import React, { useState } from "react";
import "../../styles/index.css";
import "./finances.css";
import FinancesHome from "./children/home";
import Dues from "./children/dues";
import TheDuePage from "./children/the-due";

const Finances = function ({ display }) {
  const [screen, setScreen] = useState({
    screen: "home",
    data: null,
  });

  if (!display) return;

  return (
    <div className="screen-container">
      <FinancesHome setScreen={setScreen} display={screen.screen === "home" && true} />
      <Dues setScreen={setScreen} display={screen.screen === "dues" && true} />
      <TheDuePage
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "thedue" && true}
      />
    </div>
  );
};

export default Finances;
