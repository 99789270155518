import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../finances.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import SearchInput from "../../../components/searchInput/SearchInput";
import {
  TheDueTable,
  pseudoCheckBoxSelectedData,
} from "../../../components/the-due-table/the-due-table";
import { IoMdArrowBack } from "react-icons/io";

const TheDuePage = function ({ display, screen, setScreen }) {
  const [allTransactions, setAllTransactions] = useState("");
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const [oringinalData, setOriginalData] = useState([]);
  const [theBillInQuestion, setTheBillInQuestion] = useState({});
  const [page, setPage] = useState(0);
  const [csvData, setcsvData] = useState([]);
  const [paidAndUnpaid, setPaidAndUnpaid] = useState({
    paid: 0,
    unpaid: 0,
  });

  const { backendServer, setIsLoading, patternMatching, estate } = useContext(AppContext);

  useEffect(() => {
    getData(true);

    return () => {
      setPaidAndUnpaid({
        paid: 0,
        unpaid: 0,
      });
      setOriginalData([]);
      setAllTransactions([]);
    };
  }, [screen?.data]);

  async function getData(loader) {
    if (!screen?.data) return;
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#thedue-checkbox-parent"))
      document.querySelector("#thedue-checkbox-parent").checked = false;

    try {
      if (loader) setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/dues/get?onepopulatemap=${screen?.data}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const returnedAllUsers = await (
        await fetch(
          `${backendServer}/user/get?minordetailsForResidenttype=${returned?.data?.bill_payer}`,
          {
            credentials: "include",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const allUsers = returnedAllUsers?.data;

      setTheBillInQuestion(returned?.data);

      if (returned?.data?.bill_frequency === "One Time") {
        const sorted = allUsers?.map((e, i) => {
          if (!returned?.data?.one_time_payers?.find((el) => el?.user?.email === e?.email))
            return {
              user: e,
              paymentRef: null,
              id: i,
            };
          else {
            const obj = {
              ...returned?.data?.one_time_payers?.find((el) => el?.user?.email === e?.email),
            };
            obj.id = i;
            return obj;
          }
        });

        setPaidAndUnpaid({
          paid: sorted?.filter((e) => e?.paymentRef).length,
          unpaid: sorted?.filter((e) => !e?.paymentRef).length,
        });

        setAllTransactions(sorted);
        setOriginalData(sorted);
        workCSV(sorted);
      } else {
        const sorted = allUsers?.map((e, i) => {
          if (
            !returned?.data?.payStackRef?.subscriptions?.find(
              (el) => el?.customer?.email === e?.email
            )
          )
            return {
              status: "false",
              createdAt: false,
              customer: e,
              id: i,
            };
          else {
            const obj = {
              ...returned?.data?.payStackRef?.subscriptions?.find(
                (el) => el?.customer?.email === e?.email
              ),
            };
            obj.id = i;
            return obj;
          }
        });

        setPaidAndUnpaid({
          paid: sorted?.filter((e) => e?.status === "active").length,
          unpaid: sorted?.filter((e) => e?.status !== "active").length,
        });

        setAllTransactions(sorted);
        setOriginalData(sorted);
        workCSV(sorted);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("We could not get the details of this due. Please try again later.");
    }
  }

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#thedue-checkbox-parent"))
      document.querySelector("#thedue-checkbox-parent").checked = false;
    const query = e.target.value;

    if (!query) {
      workCSV([...oringinalData]);
      return setAllTransactions([...oringinalData]);
    }

    const foundObjects = [...oringinalData]?.filter((e) => {
      return (
        patternMatching(query, e?.customer?.first_name) ||
        patternMatching(query, e?.user?.first_name) ||
        patternMatching(query, e?.customer?.last_name) ||
        patternMatching(query, e?.user?.last_name) ||
        patternMatching(query?.split(" ")[0], e?.customer?.first_name) ||
        patternMatching(query?.split(" ")[0], e?.customer?.last_name) ||
        patternMatching(query?.split(" ")[1], e?.user?.first_name) ||
        patternMatching(query?.split(" ")[1], e?.user?.last_name)
      );
    });

    setAllTransactions(foundObjects);

    workCSV(foundObjects);
  }

  function getStatusAndColor(status) {
    if (status !== "active" && status !== "success")
      return {
        text: "Unpaid",
        color: "high-severity-thedue",
      };
    else
      return {
        text: "Paid",
        color: "low-severity-thedue",
      };
  }

  function workCSV(data) {
    setcsvData([
      ["Name", "Bill Type", "Address", "Amount", "Status", "Date", "Date"],
      ...data?.map(({ customer, user, status, paymentRef, createdAt }) => {
        return [
          theBillInQuestion?.bill_frequency !== "One Time"
            ? customer?.first_name + ` ${customer?.last_name}`
            : user?.first_name + ` ${user?.last_name}`,
          theBillInQuestion?.bill_type,
          theBillInQuestion?.bill_frequency !== "One Time"
            ? customer?.address?.addressString
            : user?.address?.addressString,
          ,
          theBillInQuestion?.amount,
          theBillInQuestion?.bill_frequency !== "One Time"
            ? getStatusAndColor(status).text
            : getStatusAndColor(paymentRef?.status).text,
          createdAt || paymentRef?.initiated_on
            ? new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              }).format(
                new Date(
                  (theBillInQuestion?.bill_frequency !== "One Time"
                    ? createdAt
                    : paymentRef?.initiated_on) || Date.now()
                )
              )
            : "-",
        ];
      }),
    ]);
  }

  if (!display) return;

  return (
    <div>
      <div className="visitorspass-stat-container flex-row">
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <p className="small removemargin">
            <IoMdArrowBack
              onClick={() =>
                setScreen({
                  screen: "dues",
                  data: null,
                })
              }
              style={{ marginTop: -3 }}
              size={17}
              className="cursor"
            />{" "}
            &nbsp; Back to Dues
          </p>
        </div>

        <div
          className="td-each-d-stat-container cursor"
          onClick={() => {
            setAllTransactions([...oringinalData]);
            workCSV([...oringinalData]);
          }}
        >
          <img src="/td-1.png" alt="estility-finances" />
          <p className="small greyText removemargin">
            Billed Residents<br></br>
            <span className="big f-stat-text">{oringinalData?.length || "-"}</span>
          </p>
        </div>

        <div
          className="td-each-d-stat-container cursor"
          onClick={() => {
            if (theBillInQuestion?.bill_frequency === "One Time") {
              setAllTransactions([...oringinalData]?.filter((e) => e?.paymentRef));
              workCSV([...oringinalData]?.filter((e) => e?.paymentRef));
            } else {
              setAllTransactions([...oringinalData]?.filter((e) => e?.status === "active"));
              workCSV([...oringinalData]?.filter((e) => e?.status === "active"));
            }
          }}
        >
          <img src="/td-1.png" alt="estility-finances" />
          <p className="small greyText removemargin">
            Paid<br></br>
            <span className="big f-stat-text">{paidAndUnpaid?.paid || "-"}</span>
          </p>
        </div>

        <div
          className="td-each-d-stat-container cursor"
          onClick={() => {
            if (theBillInQuestion?.bill_frequency === "One Time") {
              {
                setAllTransactions([...oringinalData]?.filter((e) => !e?.paymentRef));
                workCSV([...oringinalData]?.filter((e) => !e?.paymentRef));
              }
            } else {
              setAllTransactions([...oringinalData]?.filter((e) => e?.status !== "active"));
              workCSV([...oringinalData]?.filter((e) => e?.status !== "active"));
            }
          }}
        >
          <img src="/td-1.png" alt="estility-finances" />
          <p className="small greyText removemargin">
            Unpaid<br></br>
            <span className="big f-stat-text">{paidAndUnpaid?.unpaid || "-"}</span>
          </p>
        </div>
      </div>
      <div className="p-table-container">
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <div style={{ width: "94%" }}>
            <SearchInput placeholder="search for a Resident" onChange={(e) => searchPlease(e)} />
          </div>
          <div className="flex-row" style={{ width: "fit-content", gap: 10 }}>
            <CSVLink
              className="cursor small csv-link"
              filename={`${estate?.estate_name} Due Data`}
              data={csvData}
            >
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>
            <FiRefreshCcw
              className="cursor"
              title="Refresh Data"
              size={20}
              onClick={() => getData(true)}
            />
          </div>
        </div>
        <TheDueTable
          checkBoxSelectedData={checkBoxSelectedData}
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          page={page}
          setPage={setPage}
          data={allTransactions}
          setcsvData={setcsvData}
          theBillInQuestion={theBillInQuestion}
        />
      </div>
    </div>
  );
};

export default TheDuePage;
