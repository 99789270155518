import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import { IoMdArrowBack } from "react-icons/io";
import EditAUserModal from "../edit-resident-details-modal/edit-resident-details-modal";
import TransactionsHistoryModal from "../transaction-history-modal/transaction-history-modal";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";

const ResdientDetails = function ({ screen, setScreen, setHideResidentTable, hardRefresh }) {
  const { backendServer, setIsLoading } = useContext(AppContext);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showThmModal, setShowThmModal] = useState(false);

  const [theResident, setTheResident] = useState({});

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        const returned = await (
          await fetch(`${backendServer}/user/get?email=${screen?.residentsDetailsData?.email}`, {
            method: "GET",
            credentials: "include",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        setTheResident(returned?.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        popup("Error fetching resident...");
      }
    })();
  }, [screen]);

  return (
    <div>
      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        <p className="small removemargin">
          <IoMdArrowBack
            onClick={() => {
              setHideResidentTable(false);
              setScreen({
                screen: "table",
                residentsDetailsData: null,
              });
            }}
            style={{ marginTop: -3 }}
            size={17}
            className="cursor"
          />{" "}
          &nbsp; Go Back
        </p>
      </div>

      <div className="flex-row align-row-left" style={{ gap: 0 }}>
        <div style={{ width: "20%" }}>
          <img
            className="resident-details-img-big"
            src={screen?.residentsDetailsData?.image.props.src}
            alt="Resident Big Image"
          />
        </div>
        <div style={{ width: "fit-content" }}>
          <h6 className="blackText boldText">Primary Resident Details</h6>
          <div className="flex-row align-row-left more-gap">
            <div className="flex-column align-column-left width-fit-content resident-d-max-width ">
              <p className="small boldText removemargin">Full Name</p>
              <p className="small  removemargin">{screen?.residentsDetailsData?.name}</p>
            </div>
            <div className="flex-column align-column-left width-fit-content resident-d-max-width ">
              <p className="small boldText removemargin">Property Code</p>
              <p className="small  removemargin">
                {screen?.residentsDetailsData?.property_code || "none"}
              </p>
            </div>
            <div className="flex-column align-column-left width-fit-content resident-d-max-width ">
              <p className="small boldText removemargin">Units Occupied</p>
              <p className="small  removemargin">
                {theResident?.units_occupied ? theResident?.units_occupied : "-"}
              </p>
            </div>
          </div>

          <div className="flex-row align-row-left more-gap">
            <div className="flex-column align-column-left width-fit-content resident-d-max-width ">
              <p className="small boldText removemargin">Email Address</p>
              <p className="small  removemargin">{screen?.residentsDetailsData?.email}</p>
            </div>
            <div className="flex-column align-column-left width-fit-content resident-d-max-width ">
              <p className="small boldText removemargin">Phone Number</p>
              <p className="small  removemargin">{screen?.residentsDetailsData?.mobile}</p>
            </div>
          </div>
        </div>
      </div>

      {theResident?.secondaryResidents?.length > 0 && (
        <div className="flex-row align-row-left" style={{ gap: 0 }}>
          <div style={{ width: "20%", opacity: 0 }}>
            <img
              className="resident-details-img-big"
              src={screen?.residentsDetailsData?.image.props.src}
              alt="Resident Big Image"
            />
          </div>
          <div style={{ width: "fit-content" }}>
            <h6 className="blackText boldText" style={{ marginTop: 20 }}>
              Secondary Residents
            </h6>
            {theResident?.secondaryResidents?.map((e, i) => (
              <div key={i} style={{ borderBottom: "1px solid rgb(214, 211, 211)" }}>
                <div className="flex-row align-row-left" style={{ gap: 60 }}>
                  <div className="flex-column align-column-left width-fit-content resident-d-max-width ">
                    <p className="small boldText removemargin">Full Name</p>
                    <p className="small  removemargin">{e?.full_name}</p>
                  </div>
                  <div className="flex-column align-column-left width-fit-content resident-d-max-width ">
                    <p className="small boldText removemargin">Phone Number</p>
                    <p className="small  removemargin">{e?.mobile}</p>
                  </div>
                </div>
                <div className="flex-row align-row-left more-gap">
                  <div className="flex-column align-column-left width-fit-content resident-d-max-width ">
                    <p className="small boldText removemargin">Email Address</p>
                    <p className="small  removemargin">{e?.email}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div
        className="flex-row"
        style={{ justifyContent: "flex-end", paddingRight: 40, marginTop: 50, gap: 20 }}
      >
        <button
          onClick={() => setShowThmModal(true)}
          className="themeBtn whiteBg themeBorder themeText"
        >
          Check Payment History
        </button>
        <button onClick={() => setShowEditUserModal(true)} className="themeBtn">
          Edit Resident Details
        </button>
      </div>

      <EditAUserModal
        data={screen?.residentsDetailsData}
        display={showEditUserModal}
        setEditAUserModal={setShowEditUserModal}
        setScreen={setScreen}
        setHideResidentTable={setHideResidentTable}
        hardRefresh={hardRefresh}
      />
      <TransactionsHistoryModal
        display={showThmModal}
        setTransactionHistoryModal={setShowThmModal}
        data={screen?.residentsDetailsData}
      />
    </div>
  );
};

export default ResdientDetails;
