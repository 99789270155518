import React, { useState, useContext, useEffect } from "react";
import "../styles/index.css";
import "./side-nav.css";
import AppContext from "../context/app-context";
import { MDBSpinner } from "mdb-react-ui-kit";
import { Tooltip } from "@mui/material";
import estateAccesses from "../context/estate-staff-access";

const SideNav = function ({ setSideNav, loggedIn }) {
  const [activeNav, setActiveNav] = useState(
    document?.location?.hash?.toUpperCase()?.replace("#", "")?.trim() || "DASHBOARD"
  );
  const [logo, setLogo] = useState("");
  const { changeScreen, setIsLoading, estate, getSignedAwsUrl, usersData } = useContext(AppContext);

  useEffect(() => {
    if (!usersData?.username) return;

    //force default route by access
    if (
      !estateAccesses[usersData?.estate_child_roles[0]]?.includes(
        capitalizeEachWord(document.location.hash?.replace("#", "")?.trim())
      )
    )
      setActiveNav(estateAccesses[usersData?.estate_child_roles[0]][0]?.toUpperCase());
  }, [loggedIn, usersData]);

  useEffect(() => {
    //mobile force route
    const isMobile = navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500;
    if (isMobile) {
      setActiveNav("VERIFICATION");
    }
  }, [loggedIn, usersData]);

  function capitalizeEachWord(word) {
    const arr = word?.split(" ");
    const mapped = arr?.map((e) => {
      return e[0]?.toUpperCase() + e?.slice(1)?.toLowerCase();
    });

    return mapped.join(" ");
  }

  useEffect(() => {
    setSideNav({ setActiveNav });
  }, [loggedIn]);

  useEffect(() => {
    (async function () {
      setLogo(estate?.logo ? await getSignedAwsUrl(estate?.logo, "others") : "/logo.png");
    })();
  }, [estate]);

  return (
    <div className="side-nav-container">
      <div className="side-nav-intro flex-row">
        {logo ? (
          <img src={logo} alt="Estility-Logo" className="estility-logo-side-nav" />
        ) : (
          <MDBSpinner size="sm" className="mx-2">
            <span className="visually-hidden"></span>
          </MDBSpinner>
        )}
        <p className="big whiteText media-nav-text">
          {estate?.estate_name &&
            (estate?.estate_name?.length < 18
              ? estate?.estate_name
              : estate?.estate_name?.slice(0, 18) + "...")}
        </p>
      </div>
      <div className="nav-items-container">
        {estateAccesses[usersData?.estate_child_roles[0]]?.includes("Dashboard") && (
          <button
            onClick={() => {
              setActiveNav("DASHBOARD");
              changeScreen("DASHBOARD");
              document.location.hash = "Dashboard";
            }}
            className={`each-nav media-nav ${activeNav === "DASHBOARD" && "each-nav-active"}`}
          >
            <img src="/n-1.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Dashboard</p>
          </button>
        )}

        {estateAccesses[usersData?.estate_child_roles[0]]?.includes("Properties") && (
          <button
            onClick={() => {
              setActiveNav("PROPERTIES");
              changeScreen("PROPERTIES");
              document.location.hash = "Properties";
            }}
            className={`each-nav media-nav ${activeNav === "PROPERTIES" && "each-nav-active"}`}
          >
            <img src="/n-2.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Properties</p>
          </button>
        )}

        {estateAccesses[usersData?.estate_child_roles[0]]?.includes("Residents") && (
          <button
            onClick={() => {
              setActiveNav("RESIDENTS");
              changeScreen("RESIDENTS");
              document.location.hash = "Residents";
            }}
            className={`each-nav media-nav ${activeNav === "RESIDENTS" && "each-nav-active"}`}
          >
            <img src="/n-3.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Residents</p>
          </button>
        )}

        {estateAccesses[usersData?.estate_child_roles[0]]?.includes("Finances") && (
          <button
            onClick={() => {
              setActiveNav("FINANCES");
              changeScreen("FINANCES");
              document.location.hash = "Finances";
            }}
            className={`each-nav media-nav ${activeNav === "FINANCES" && "each-nav-active"}`}
          >
            <img src="/n-4.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Finances</p>
          </button>
        )}

        {estateAccesses[usersData?.estate_child_roles[0]]?.includes("Visitors") && (
          <button
            onClick={() => {
              setActiveNav("VISITORS");
              changeScreen("VISITORS");
              document.location.hash = "Visitors";
            }}
            className={`each-nav media-nav ${activeNav === "VISITORS" && "each-nav-active"}`}
          >
            <img src="/n-5.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Visitors</p>
          </button>
        )}

        {estateAccesses[usersData?.estate_child_roles[0]]?.includes("Verification") && (
          <Tooltip
            disableHoverListener
            placement="right"
            arrow
            enterTouchDelay={0}
            title="Verification"
          >
            <button
              onClick={() => {
                setActiveNav("VERIFICATION");
                changeScreen("VERIFICATION");
                document.location.hash = "Verification";
              }}
              className={`each-nav ${activeNav === "VERIFICATION" && "each-nav-active"}`}
            >
              <img src="/n-10.png" alt="nav-icon" style={{ width: 20 }} />
              <p className="small whiteText removemargin media-nav-text">Verification</p>
            </button>
          </Tooltip>
        )}

        {estateAccesses[usersData?.estate_child_roles[0]]?.includes("Broadcasts") && (
          <button
            onClick={() => {
              setActiveNav("BROADCASTS");
              changeScreen("BROADCASTS");
              document.location.hash = "Broadcasts";
            }}
            className={`each-nav media-nav ${activeNav === "BROADCASTS" && "each-nav-active"}`}
          >
            <img src="/n-6.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Broadcasts</p>
          </button>
        )}

        {estateAccesses[usersData?.estate_child_roles[0]]?.includes("Compliants") && false && (
          <button
            onClick={() => {
              setActiveNav("COMPLIANTS");
              changeScreen("COMPLIANTS");
              document.location.hash = "Compliants";
            }}
            className={`each-nav media-nav ${activeNav === "COMPLIANTS" && "each-nav-active"}`}
          >
            <img src="/n-7.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Complaints</p>
          </button>
        )}

        {estateAccesses[usersData?.estate_child_roles[0]]?.includes("Settings") && (
          <button
            onClick={() => {
              setActiveNav("SETTINGS");
              changeScreen("SETTINGS");
              document.location.hash = "Settings";
            }}
            className={`each-nav media-nav ${activeNav === "SETTINGS" && "each-nav-active"}`}
          >
            <img src="/n-8.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Settings</p>
          </button>
        )}

        <Tooltip disableHoverListener placement="right" arrow enterTouchDelay={0} title="Log Out">
          <button
            onClick={() => {
              setIsLoading(true);
              localStorage.removeItem("emsusername");
              localStorage.removeItem("emsemail");
              localStorage.removeItem("emstoken");
              localStorage.removeItem("emsbaseurl");
              setTimeout(() => {
                document.location.reload();
              }, [1500]);
            }}
            className={`each-nav`}
          >
            <img src="/n-9.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Log Out</p>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default SideNav;
