import React from 'react'
import './Accordion.css'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiMinus } from 'react-icons/bi'
type Props = {
  showText: boolean;
  onClick?: () => void;
  title: string;
  desc: string;
}

const Accordion = ({showText, onClick,desc, title}: Props) => {
  return (
    <div className='drop-accordion'>
        <div className='drop-accordion-title' onClick={() => onClick && onClick()}><div>{title}</div> {showText? <BiMinus/> :<AiOutlinePlus/>}</div>
        {
          showText &&
        <div>{desc}</div>
        }
    </div>
  )
}

export default Accordion