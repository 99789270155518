type IFaqData = {
  tag: string;
  title: string;
  desc: string;
};

export const residentsFaq: IFaqData[] = [
  {
    tag: "residents",
    title: " What happens when I create a resident on the EMS? ",
    desc: ` Once created, a resident receives an automated "welcome email" containing login details for the resident app. All created residents have immediate access to the resident app. `,
  },
  {
    tag: "residents",
    title: "Can I see different resident types on the app?",
    desc: "Yes, you can. In the RESIDENT module, click on the filter icon to sort residents by different types. ",
  },
  {
    tag: "residents",
    title: `How do I search for a resident? `,
    desc: `To search for a resident, go to the RESIDENT module and use the search modal to search for residents using any of their details. `,
  },
];
export const complaintsFaq: IFaqData[] = [
  {
    tag: "complaints",
    title: "How do i mark a complaint as resolved",
    desc: "You can make payments for all your orders including electricity and gas purchase using your wallet balance. To add money to your wallet, tap on the “fund wallet” button and follow the on-screen prompts.",
  },
];

export const propertiesFaq: IFaqData[] = [
  {
    tag: "properties",
    title: `Can I create multiple properties simultaneously on the EMS app? `,
    desc: `Currently, the EMS app allows the creation of one property at a time. As a facility manager, it is recommended to review and confirm the details of each property, including property type, address, and contact number, before initiating the creation process. If you have a need to create multiple properties simultaneously, we encourage you to reach out to our support team directly through the EMS app. The Estility Support team will be happy to assist you in efficiently creating the properties you require. `,
  },
  {
    tag: "properties",
    title: `How can I view only occupied or non-occupied properties on the EMS App? `,
    desc: `To filter properties based on occupancy status on the EMS App, follow these steps: 

    Navigate to the PROPERTIES module. 
    
    Click on the filter icon. 
    
    Locate the Occupancy Status field in the filter options. 
    
    Choose "YES" to view only occupied properties or "NO" to see non-occupied properties. `,
  },
  {
    tag: "properties",
    title: `Is it mandatory to provide Secondary Contact details for each property? `,
    desc: `No, it is not mandatory to fill in the Secondary Contact details for every property. We understand that not all properties may have a secondary owner or contact. You have the flexibility to provide this information based on the specific details and requirements of each property. If you have any further questions or concerns, feel free to reach out for assistance. `,
  },
  {
    tag: "properties",
    title: `How can I add staff members to the app for their use? `,
    desc: `To add staff members to the app, follow these steps:  

    Navigate to the SETTINGS module. 
    
    Click on USERS. 
    
    Select "ADD NEW" to initiate the process of adding a new user. 
    
    Specify the necessary details for the new staff member. 
    
    Choose the appropriate staff role based on the available roles configured for your Estate. 
    
    This allows you to efficiently add staff members to the app, assigning them roles tailored to the specific needs of your estate. If you encounter any challenges or require further assistance, please don't hesitate to reach out for support. `,
  },
];

const financeFaq: IFaqData[] = [
  {
    tag: "finance",
    title: `Can I specify multiple account numbers for estate fund withdrawals?  `,
    desc: `Yes, you can add several account numbers for withdrawals, but only one account can be set as the primary account for withdrawals. To add an account number, go to the SETTINGS module, click on BILLING, and then select ADD NEW. Tick the "make this primary account for withdrawals" option on the ADD NEW screen to set it as the primary account. `,
  },
  {
    tag: "finance",
    title: `Can I add dues for residents to pay in my estate?  `,
    desc: `Certainly! To add dues for estate residents, navigate to the FINANCE module, go to BILLS and DUES, click on "view all," and then select "Create new Bill." `,
  },
  {
    tag: "finance",
    title: `How are incentives calculated? `,
    desc: ` Incentives are calculated based on the percentage of delivered orders of groceries or gas to your estate. The percentage value is not constant and may vary. `,
  },
  {
    tag: "finance",
    title: `Can I view residents who owe dues in the estate?  `,
    desc: `Yes, you can view residents who owe service charges, dues, or any created charges in the FINANCE module. Under BILLS and DUES, click on "view all," and then select any of the created service charges or dues to see residents' payment statuses. `,
  },
];

const broadCastFaq: IFaqData[] = [
  {
    tag: "broadcast",
    title: `Can I send broadcast messages to different categories of residents? `,
    desc: `Absolutely! To send broadcast messages to different resident types, navigate to the BROADCAST module, click on NEW BROADCAST, and after entering the content, choose the specific resident types you want to send the broadcast to. `,
  },
];

const visitorsFaq: IFaqData[] = [
  {
    tag: "visitors",
    title: ` Can I see a list of current visitors in my estate and their visit times? `,
    desc: `Yes, you can. Navigate to the VISITORS page, click on the filter icon, and filter by visitor status to see approved, departed, pending, and declined visitors. `,
  },
  {
    tag: "visitors",
    title: `Can security verify Estility riders entering my estate?  `,
    desc: `Yes, security personnel can use the Estate app to verify Estility riders by checking their access codes. `,
  },
  {
    tag: "visitors",
    title: `Is printing possible on the app? `,
    desc: `Yes, printing is available on all modules of the EMS app except for Dashboard, Verification, Settings, and Broadcast modules. `,
  },
];

export const faqsList: IFaqData[] = [
  ...residentsFaq,
  ...complaintsFaq,
  ...propertiesFaq,
  ...financeFaq,
  ...broadCastFaq,
  ...visitorsFaq,
];
