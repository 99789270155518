import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./edit-due-modal.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const EditABillModal = function ({ setEditABillModal, getData, showEditBillModal }) {
  const { backendServer, setIsLoading } = useContext(AppContext);
  const [disableBtn, setdisableBtn] = useState(true);
  const [billType, setbillType] = useState("");
  const [billPayer, setbillPayer] = useState("");
  const [propertyType, setpropertyType] = useState("");
  const [billFrequency, setbillFrequency] = useState("");
  const [amount, setamount] = useState("");

  useEffect(() => {
    if (!billType && !billPayer && !propertyType && !billFrequency && !amount) setdisableBtn(true);
    else setdisableBtn(false);

    if (amount && amount < 1) setdisableBtn(true);
  });

  async function editDue() {
    const obj = {};
    if (billType) obj.bill_type = billType;
    if (billPayer) obj.bill_payer = billPayer;
    if (propertyType) obj.property_type = propertyType;
    if (billFrequency) obj.bill_frequency = billFrequency;
    if (amount) obj.amount = amount;
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/dues/update/${showEditBillModal?.data?.id}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify(obj),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setIsLoading(false);
      popup("Bill Updated");
      setEditABillModal({
        display: false,
        data: null,
      });
      getData(true);
      setbillType("");
      setbillPayer("");
      setpropertyType("");
      setbillFrequency("");
      setamount("");
    } catch (err) {
      setIsLoading(false);
      popup("Error updating bill, try again later");
    }
  }

  if (!showEditBillModal?.display) return;

  return (
    <div className="modal-container">
      <div className="modal-body edit-a-bill-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setEditABillModal({
                display: false,
                data: null,
              });
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="a-bill-modal-cont flex-column align-column-left">
          <div className="flex-row align-row-left">
            <p className="big">Fill in the new bill details below</p>
          </div>

          <div className="flex-row">
            <MDBDropdown id="mdb-dropdown-custom-dropdown">
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {billType || showEditBillModal?.data?.bill_type || "Bill Type"}
              </MDBDropdownToggle>

              <MDBDropdownMenu id="edit-a-a-bill-mdb-menu-custom-dropdown">
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillType(e.target.textContent);
                  }}
                >
                  Residential
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillType(e.target.textContent);
                  }}
                >
                  Facility
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillType(e.target.textContent);
                  }}
                >
                  Security
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillType(e.target.textContent);
                  }}
                >
                  Electricity
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillType(e.target.textContent);
                  }}
                >
                  Water
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

            <MDBDropdown id="mdb-dropdown-custom-dropdown">
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {billPayer || showEditBillModal?.data?.bill_payer || "Bill Payer"}
              </MDBDropdownToggle>

              <MDBDropdownMenu id="edit-a-a-bill-mdb-menu-custom-dropdown">
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillPayer(e.target.textContent);
                  }}
                >
                  Primary
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillPayer(e.target.textContent);
                  }}
                >
                  Secondary
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillPayer(e.target.textContent);
                  }}
                >
                  Property Owner
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillPayer(e.target.textContent);
                  }}
                >
                  All Residents
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </div>

          <div className="flex-row">
            <MDBDropdown id="mdb-dropdown-custom-dropdown">
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {propertyType || showEditBillModal?.data?.property_type || "Property Type"}
              </MDBDropdownToggle>

              <MDBDropdownMenu id="edit-a-a-bill-mdb-menu-custom-dropdown">
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setpropertyType(e.target.textContent);
                  }}
                >
                  Flat
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setpropertyType(e.target.textContent);
                  }}
                >
                  Studio Apt
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setpropertyType(e.target.textContent);
                  }}
                >
                  Facility
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setpropertyType(e.target.textContent);
                  }}
                >
                  All Properties
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

            <MDBDropdown id="mdb-dropdown-custom-dropdown">
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {billFrequency || showEditBillModal?.data?.bill_frequency || "Bill Frequency"}
              </MDBDropdownToggle>

              <MDBDropdownMenu id="edit-a-a-bill-mdb-menu-custom-dropdown">
                <MDBDropdownItem
                  link
                  href="#Finances"
                  disabled={true}
                  onClick={(e) =>
                    popup("Sorry, you cannot change the frequency of an already created due.")
                  }
                >
                  Monthly
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  disabled={true}
                  onClick={(e) =>
                    popup("Sorry, you cannot change the frequency of an already created due.")
                  }
                >
                  Quarterly
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  disabled={true}
                  onClick={(e) =>
                    popup("Sorry, you cannot change the frequency of an already created due.")
                  }
                >
                  Yearly
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  disabled={true}
                  onClick={(e) =>
                    popup("Sorry, you cannot change the frequency of an already created due.")
                  }
                >
                  One Time
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </div>

          <div className="flex-row">
            <input
              className="in a-a-u-inputs"
              placeholder="Amount"
              type="number"
              defaultValue={showEditBillModal?.data?.amount || 0}
              onChange={(e) => setamount(e.target.value)}
              min="1"
            />
            <input
              className="in a-a-u-inputs"
              placeholder=""
              type="FAKE INPUT"
              maxLength="100"
              value={""}
              style={{ opacity: 0 }}
              onChange={() => {}}
            />
          </div>

          <button onClick={editDue} className="themeBtn a-a-u-btn" disabled={disableBtn}>
            Update Bill
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditABillModal;
