import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./residents.css";
import AppContext from "../../context/app-context";
import {
  ResidentsTableTable,
  pseudoCheckBoxSelectedData,
} from "../../components/residents-table/residents-table";
import { popup } from "../../vanilla-functions/model";
import { CgSortAz } from "react-icons/cg";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import SearchInput from "../../components/searchInput/SearchInput";

const Residents = function ({ display }) {
  const [page, setPage] = useState(0);
  const [allResidents, setAllResidents] = useState([]);
  const [resolvedData, setResolvedData] = useState([]);
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const [sortBy, setSortBy] = useState(true);
  const [hideResidentTable, setHideResidentTable] = useState(false);
  const { backendServer, getSignedAwsUrl, setIsLoading, patternMatching, usersData, estate } =
    useContext(AppContext);

  function workCSV(data) {
    setcsvData([
      ["Name", "Address", "Unit Code", "Phone Number", "Email", "Resident Level"],
      ...data?.map(
        ({ first_name, last_name, address, mobile, resident_type, property_code, email }) => {
          return [
            first_name + ` ${last_name}`,
            address?.addressString,
            property_code,
            mobile,
            email,
            resident_type ? resident_type : "none",
          ];
        }
      ),
    ]);
  }

  async function getData(raw) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#resident-checkbox-parent"))
      document.querySelector("#resident-checkbox-parent").checked = false;
    const comps = await Promise.all(
      raw?.map(async (e) => {
        if (e.profile_picture && e.profile_picture !== "/user_placeholder.png")
          e.profile_picture = await getSignedAwsUrl(e?.profile_picture, "profile-pictures");
        else e.profile_picture = "/user_placeholder.png";
        return e;
      })
    );
    setResolvedData(comps);
    workCSV(comps);
  }

  useEffect(() => {
    if (!display) return;
    (async () => {
      try {
        setIsLoading(true);
        const returned = await (
          await fetch(`${backendServer}/user/get?role=user`, {
            method: "GET",
            credentials: "include",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
        setAllResidents(returned?.data?.filter((e) => e.is_active));
        setIsLoading(false);
        getData(returned?.data.filter((e) => e.is_active));
      } catch (err) {
        setIsLoading(false);
        popup("Error fetching Residents...");
      }
    })();
  }, [display, usersData]);

  function sort() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#resident-checkbox-parent"))
      document.querySelector("#resident-checkbox-parent").checked = false;
    if (sortBy) {
      const sortedArray = [];
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Primary"));
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Property Owner"));
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Secondary"));
      sortedArray.push(...[...resolvedData].filter((e) => !e?.resident_type));
      setResolvedData([...sortedArray]);
      workCSV(sortedArray);
    } else {
      const sortedArray = [];
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Property Owner"));
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Secondary"));
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Primary"));
      sortedArray.push(...[...resolvedData].filter((e) => !e?.resident_type));
      setResolvedData([...sortedArray]);
      workCSV(sortedArray);
    }
    setSortBy(!sortBy);
  }

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#resident-checkbox-parent"))
      document.querySelector("#resident-checkbox-parent").checked = false;
    const query = e.target.value;

    if (!query) return getData([...allResidents]);

    const foundResidents = allResidents?.filter((e) => {
      return (
        patternMatching(query, e.first_name + ` ${e.last_name}`) ||
        patternMatching(query?.split(" ")[0], e.first_name) ||
        patternMatching(query?.split(" ")[0], e.last_name) ||
        patternMatching(query?.split(" ")[1], e.first_name) ||
        patternMatching(query?.split(" ")[1], e.last_name)
      );
    });
    getData(foundResidents);

    workCSV(foundResidents);
  }

  useEffect(
    (e) => {
      return () => {
        if (display) setHideResidentTable(false);
      };
    },
    [display]
  );

  if (!display) return;

  return (
    <div className="screen-container">
      {!hideResidentTable && (
        <div className="residents-stat-container flex-row">
          <div className="r-each-d-stat-container">
            <img src="/r-1.png" alt="estility-resident" />
            <p className="small greyText removemargin">
              Total Residents<br></br>
              <span className="big r-stat-text">{allResidents.length || "-"}</span>
            </p>
          </div>

          <div className="r-each-d-stat-container">
            <img src="/r-2.png" alt="estility-resident" />
            <p className="small greyText removemargin">
              Primary Residents<br></br>
              <span className="big r-stat-text">
                {allResidents.filter((e) => e.resident_type === "Primary").length || "-"}
              </span>
            </p>
          </div>

          <div className="r-each-d-stat-container">
            <img src="/r-3.png" alt="estility-resident" />
            <p className="small greyText removemargin">
              Secondary Residents<br></br>
              <span className="big r-stat-text">
                {allResidents.filter((e) => e.resident_type === "Secondary").length || "-"}
              </span>
            </p>
          </div>

          <div className="r-each-d-stat-container">
            <img src="/r-4.png" alt="estility-resident" />
            <p className="small greyText removemargin">
              Property Owners<br></br>
              <span className="big r-stat-text">
                {allResidents.filter((e) => e.resident_type === "Property Owner").length || "-"}
              </span>
            </p>
          </div>
        </div>
      )}
      <div className="p-table-container">
        {!hideResidentTable && (
          <div
            style={{
              marginBottom: 15,
              width: "100%",
              gap: "1rem",
              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SearchInput placeholder="search for a resident" onChange={(e) => searchPlease(e)} />

            <CgSortAz onClick={sort} className="cursor" title="Sort By Resident Level" size={30} />
            <CSVLink
              className="cursor small csv-link"
              filename={`${estate?.estate_name} Residents Data`}
              data={csvData}
            >
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>
            <FiRefreshCcw
              className="cursor"
              title="Refresh Data"
              size={20}
              onClick={() => getData(allResidents)}
            />
          </div>
        )}
        <ResidentsTableTable
          page={page}
          setPage={setPage}
          setHideResidentTable={setHideResidentTable}
          data={resolvedData}
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          checkBoxSelectedData={checkBoxSelectedData}
          setcsvData={setcsvData}
        />
      </div>
    </div>
  );
};

export default Residents;
