

export const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        const base64Data = reader.result+'';
        if (base64Data) {
          resolve(base64Data);
        } else {
          reject(new Error('Base64 data not found'));
        }
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(file);
    });
  };

  type IShortenText = {
    text: string, maxLen: number, replacer?: string | any;
    callback?: (fullText: string) => void
  }

  export const shortenText = (args: IShortenText) => {
    if (args.text.length < args.maxLen) {
      return {text: args.text, callback: null};
    } else {
      return {
        text: args.text.slice(0, args.maxLen)+(args.replacer || '...'),
        callback: args.callback && args.callback(args.text),
      };
    }
  };


  type IValidateField = {
    fields: any[];
    onError?: (filed: string) => void;
    callback?: () => void;
  };
  export const validateField = ({ fields, onError, callback }: IValidateField): boolean => {
    let status: boolean = true;
  
    fields.forEach((element) => {
      for (const key in element) {
        if (Object.prototype.hasOwnProperty.call(element, key)) {
          const val = element[key];
          if (val === '' || val === null || val === undefined) {
            status = false;
            onError && onError(key);
            return;
          }
        }
      }
    });
  
    status && callback && callback(); // if status is true and callback is supply
  
    return status;
  };
