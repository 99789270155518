import { OptionsSvg } from "../../assets/options-svg";

const columns = [
  {
    id: "c_checkbox",
    label: (
      <input
        id="c-checkbox-parent"
        type="checkbox"
        className="table-checkboxes c-table-checkboxes-head"
      />
    ),
    minWidth: 20,
  },
  { id: "user", label: "Name", minWidth: 170 },
  { id: "compliant_type", label: "Complaint Type", minWidth: 170 },
  {
    id: "address",
    label: "Address",
    minWidth: 170,
    align: "left",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "left",
  },
  {
    id: "severity",
    label: "Severity",
    minWidth: 100,
    align: "left",
  },
  {
    id: "options",
    label: "",
    minWidth: 15,
    align: "left",
  },
];

function sortData(data, showOptions, setCompliantsModal) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      c_checkbox: (
        <input
          data={e?._id}
          id="c-checkbox-children"
          type="checkbox"
          className="table-checkboxes c-table-checkboxes"
        />
      ),
      user: e?.first_name + ` ${e?.last_name}`,
      compliant_type: e?.compliant_type,
      address: e?.address.slice(0, 20) + "...",
      description: e?.description.slice(0, 20) + "..",
      status: e?.status ? "resolved" : "un-resolved",
      severity: (
        <span
          className={
            e?.severity === "low"
              ? "low-severity-c"
              : e?.severity === "medium"
              ? "medium-severity-c"
              : "high-severity-c"
          }
        >
          {e?.severity}
        </span>
      ),
      options: showOptions && <OptionsSvg data={e} setCompliantsModal={setCompliantsModal} />,
    };
  });

  return finalArray;
}

export { sortData, columns };
