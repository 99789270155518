import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import './SearchInput.css'

type Props = {
    mainData?: any[];
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onGetFilteredOnChange?: (newData: any[]) => void;
    placeholder?: string;
};

const SearchInput = ({mainData = [], onChange, onGetFilteredOnChange, placeholder}: Props) => {
  
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (searchItem: string) => {
        setSearchQuery(searchItem);
        const _filteredData = mainData.filter((item) => {
          const objectValues = Object.values(item).map((value) =>
            typeof value === "string" ? value.toLowerCase() : value
          );
    
          const searchValue = searchItem.toLowerCase();
          return objectValues.some((value: any) =>
              patternMatching(searchValue, value)
            // value.toString().toLowerCase().includes(searchValue)
          );
        });
        onGetFilteredOnChange && onGetFilteredOnChange(_filteredData);
      };

      function patternMatching(wordOne: any, wordTwo: any) {
        if (!wordOne || !wordTwo) return false;
        //wrote this for name searching
        let result = wordOne?.split("")?.some((e:any, i: number) => {
          return String(e)?.toUpperCase() !== String(wordTwo?.split("")[i])?.toUpperCase();
        });
        return !result;
      }

  return (
    <div className="search-input-box-wrapper">
      <CiSearch />
      <input
        placeholder={placeholder || ''}
        value={searchQuery}
        onChange={(e) => {
            setSearchQuery(e.target.value)
            onChange && onChange(e);
            onGetFilteredOnChange && handleSearch(e.target.value)
        }}
      />
    </div>
  );
};

export default SearchInput;
