import { useState, useContext } from "react";
import Button from "../../../components/button/Button";
import Bankpopup from "../../../components/popup/bankpopup/Bankpopup";
import Success from "../../../components/popup/second-success/second-success.js";
import { FiPlus } from "react-icons/fi";
import BankCard from "./BankCard";
import AppContext from "../../../context/app-context";

type Props = {};

const BillingSettings = (props: Props) => {
  const { estate } = useContext(AppContext);

  const [openAddAccountFormModal, setOpenAddAccountFormModal] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  return (
    <div style={{ display: "flex", gap: "2.5rem", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <strong>Withdrawal Methods</strong>
        <div>Select or add withdrawal method for your earnings</div>

        {estate?.bank_accounts.map((x: any, i: any) => (
          <BankCard
            isPrimary={x.is_primary}
            key={i}
            accountName={x.account_name}
            bankAccountNumber={String(x.account_number)}
            bankName={x.bank_name}
            bankLogo={"/logo.png"}
            id={x._id}
            fullData={x}
          />
        ))}
      </div>
      <Bankpopup
        isOpen={openAddAccountFormModal}
        setIsOpen={() => setOpenAddAccountFormModal(false)}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "end",
          width: "100%",
        }}
      >
        <Button
          leftIcon={<FiPlus size={"1rem"} />}
          label={"Add new"}
          onClick={() => setOpenAddAccountFormModal(true)}
        />
      </div>

      {/* Success modal */}
      {openSuccessModal && (
        <Success
          isModalOpen={openSuccessModal}
          buttonText={"View dashboard"}
          text={"Your request to add a new withdrawal method has been received."}
          title={"Request Recieved"}
          handleModalOpen={() => setOpenSuccessModal(false)}
          onBtnClick={() => setOpenSuccessModal(false)}
        />
      )}
      {/* Success modal */}
    </div>
  );
};

export default BillingSettings;
