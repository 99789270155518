import React, { useContext, useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import AppContext from "../../context/app-context";
import processBarChartData from "./data.js";
import { popup } from "../../vanilla-functions/model";

const AllDuesChartPlusOrderIncentives = ({}) => {
  const [dues, setAllDues] = useState([]);
  const [orders, setOrders] = useState([]);
  const { backendServer, shortenNumber, estate } = useContext(AppContext);

  useEffect(() => {
    (async function () {
      try {
        const returned = await (
          await fetch(
            `${backendServer}/dues/get?allpopulateonetimepayersPlusAllpopulateontimemap=true`,
            {
              credentials: "include",
              headers: {
                token: localStorage.getItem("emstoken"),
              },
            }
          )
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        const initialData = [...returned?.data].reverse();

        const allTransactionsRegardingTheEstate = [];

        initialData.forEach((theFullDue) => {
          if (theFullDue?.bill_frequency === "One Time") {
            theFullDue.one_time_payers.forEach((e) => {
              allTransactionsRegardingTheEstate.push({
                name: e?.user?.first_name + ` ${e?.user?.last_name}`,
                initiated_on: e?.paymentRef?.initiated_on,
                bill_type: theFullDue?.bill_type,
                _id: e?._id,
                status: "completed",
                amount: e?.paymentRef?.amount || theFullDue?.amount,
              });
            });
          } else if (theFullDue?.mappedSubscriptions) {
            theFullDue?.mappedSubscriptions?.forEach((e) => {
              allTransactionsRegardingTheEstate.push({
                name: e?.customer?.first_name + ` ${e?.customer?.last_name}`,
                initiated_on: e?.most_recent_invoice?.paid_at,
                bill_type: theFullDue?.bill_type,
                _id: e?.most_recent_invoice?.invoice_code,
                status: "completed",
                amount: theFullDue?.amount,
              });
            });
          }
        });

        setAllDues(allTransactionsRegardingTheEstate);
      } catch (err) {
        popup("Error fetching Estate Dues Statistics");
      }
    })();
  }, [estate]);

  useEffect(() => {
    (async function () {
      try {
        const returned = await (
          await fetch(`${backendServer}/order/get`, {
            method: "GET",
            credentials: "include",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        setOrders(returned?.data?.filter((e) => e.status === "completed"));
      } catch (err) {
        popup("Error fetching Estate Statistics");
      }
    })();
  }, [estate]);

  return (
    <ResponsiveBar
      data={processBarChartData(dues, orders, estate?.estateConfig?.order_incentive, estate?.estateConfig?.incentive_unit)}
      keys={["Dues", "Order Incentives"]}
      indexBy="month"
      margin={{ top: 20, right: 130, bottom: 50, left: 60 }}
      padding={0.4}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={["#6359E9", "#64CFF6"]}
      valueFormat={(value) => shortenNumber(value)}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={
        [
          // {
          //   match: {
          //     id: "Orders",
          //   },
          //   id: "lines",
          // },
        ]
      }
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: `Months \n (${new Date(Date.now()).getFullYear()})`,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Amount (NGN)",
        legendPosition: "middle",
        legendOffset: -50,
        format: (value) => shortenNumber(value),
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "black",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 110,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Estility Estate Due and Order Income"
      barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in month: " + e.indexValue}
    />
  );
};

export default AllDuesChartPlusOrderIncentives;
