import React, { useState } from "react";
import "../../styles/index.css";
import "./properties.css";
import PropertiesHome from "./children/properties-home";
import PropertyDetails from "./children/property-details";
import ResidentsTable from "./children/residents-table";

const Properties = function ({ display }) {
  const [screen, setScreen] = useState({
    screen: "home",
    detailsID: null,
    residentsTableData: null,
  });

  if (!display) return;

  return (
    <div className="screen-container">
      <PropertiesHome setScreen={setScreen} display={screen.screen === "home" && true} />
      <PropertyDetails
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "details" && true}
      />
      <ResidentsTable
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "residents-table" && true}
      />
    </div>
  );
};

export default Properties;
