import React, { useContext, useState, useEffect } from "react";
import "../../styles/index.css";
import "./engine-top-bar.css";
import AppContext from "../../context/app-context";
import { IoIosNotificationsOutline } from "react-icons/io";
import { HiChevronDown } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";
import AddAPropertyModal from "../add-a-property-modal/add-a-property-modal";
import { AddAUserModal } from "../add-a-user-modal/add-a-user-modal";
import Notifications from "../notification-popup/notification";

function EngineTopBar({}) {
  const [addAPropertyModal, setAddAPropertyModal] = useState({
    display: false,
    data: {},
  });
  const [addAuserModal, setAddAUserModal] = useState({
    display: false,
    data: {},
  });
  const [userImage, setUserImage] = useState("");
  const { usersData, getSignedAwsUrl } = useContext(AppContext);
  const [showDropDown, setShowDropDown] = useState(false);

  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    (async function () {
      setUserImage(await getSignedAwsUrl(usersData?.profile_picture, "profile-pictures"));
    })();
  }, [usersData]);

  useEffect(() => {
    document
      .querySelector(".header-dropdown")
      ?.addEventListener("mouseleave", () => setShowDropDown(false));
  }, []);

  useEffect(() => {
    document.querySelector("body")?.addEventListener("click", (e) => {
      if (
        e.target.classList.contains("drop-down-button") ||
        e.target.classList.contains("top-bar-btn")
      )
        return;
      setShowDropDown(false);
    });
  }, []);

  return (
    <div className="top-bar-container">
      <p className="big removemargin media-engine-intro">
        Welcome back, {usersData?.last_name} 👋
        <br></br>{" "}
        <span className="small removemargin">Here's what is happening in your estate today.</span>
      </p>

      <div className="flex-row" style={{ width: "fit-content", gap: 15 }}>
        {(document.location.hash.toUpperCase()?.replace("#", "").trim() === "PROPERTIES" ||
          document.location.hash.toUpperCase()?.replace("#", "").trim() === "RESIDENTS") && (
          <button onClick={() => setShowDropDown(true)} className="top-bar-btn themeBtn">
            + &nbsp; Add New &nbsp; <HiChevronDown />
          </button>
        )}
        <div className={`header-dropdown ${!showDropDown && "hide"}`}>
          <p
            className="drop-down-button"
            onClick={() => {
              setAddAPropertyModal({
                display: true,
                data: {},
              });
              setShowDropDown(false);
            }}
          >
            <AiOutlinePlus size={15} /> Property
          </p>
          <p
            className="drop-down-button"
            onClick={() => {
              setAddAUserModal({
                display: true,
                data: {},
              });
              setShowDropDown(false);
            }}
          >
            <AiOutlinePlus size={15} /> Resident
          </p>
        </div>
        <IoIosNotificationsOutline
          className="cursor media-engine-intro"
          onClick={() => setShowNotifications(true)}
          size={20}
        />
        <img
          src={userImage || "/user_placeholder.png"}
          alt="estility admin"
          className="top-bar-profile-pic"
        />
        <div className="flex-column align-column-left" style={{ width: "fit-content" }}>
          <p className="small removemargin">{usersData?.last_name}</p>
          <p className="top-bar-status small removemargin">{usersData?.estate_child_roles[0]}</p>
        </div>
      </div>
      <AddAPropertyModal
        display={addAPropertyModal?.display}
        setAddAPropertyModal={setAddAPropertyModal}
      />
      <AddAUserModal display={addAuserModal?.display} setAddAUserModal={setAddAUserModal} />

      <Notifications isOpen={showNotifications} setIsOpen={setShowNotifications} />
    </div>
  );
}

export default EngineTopBar;
