import React, { useEffect, useState } from "react";
import { fileToBase64 } from "../../../../utils";
import InputField from "../../../../components/inputField/InputField";
import SelectInput from "../../../../components/selectInput/SelectInput";
import Button from "../../../../components/button/Button";
import { useMainAppContext } from "../../../../context/appContext/MainAppContext";
import "./AccountInfoEdit.css";
import SecondSuccess from "../../../../components/popup/second-success/second-success";
import { UpdateUserInfoUrl, UpdateUserProfilePicUrl } from "../../../../config/constants/endpoints";

type Props = {
  onNavigate?: (screen: number) => void;
};

const AccountInfoEdit = ({ onNavigate }: Props) => {
  const [newLogo, setNewLogo] = useState<string | undefined>(undefined);

  const { userData, getSignedAwsUrl, setIsLoading, emsToken, backendServerUrl, updateUsersData } =
    useMainAppContext();
  const [userImage, setUserImage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  // profile

  const [uFirstName, setUFirstName] = useState("");
  const [uLastName, setULasName] = useState("");
  const [uPhoneNUmber, setUPhoneNumber] = useState("");
  const [uAddress, setUAddress] = useState("");

  useEffect(() => {
    (async function () {
      setUserImage(await getSignedAwsUrl(userData?.profile_picture, "profile-pictures"));
    })();
  }, [userData]);

  const updateUserProfile = async () => {
    const userId = userData._id;
    const data = {
      mobile: uPhoneNUmber,
      first_name: uFirstName,
      last_name: uLastName,
      address: uAddress,
    };

    const pathUrl = UpdateUserInfoUrl(userId);
    const url = `${backendServerUrl}/${pathUrl}`;
    const token = emsToken;
    setIsLoading(true);
    const result = await (
      await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(data),
      })
    ).json();

    const msg = result.message;
    const status = result.status;

    if (status + "".toLowerCase().includes("profile updated")) {
      updateUsersData();
      setOpenSuccessModal(true);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleUploadProfilePicture = async (imageUri: File) => {
    const formData = new FormData();

    formData.append("photo", imageUri);

    const pathUrl = UpdateUserProfilePicUrl(userData._id);
    const url = `${backendServerUrl}/${pathUrl}`;

    try {
      setIsLoading(true);

      await (
        await fetch(url, {
          method: "PATCH",
          body: formData,
          headers: {
            enctype: "multipart/form-data",
            token: emsToken,
          },
        })
      ).json();

      await updateUsersData();
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="accountinfo-edit-info-container">
      <div className="estate-info-first-child">
        <div className="estate-info-first-child-logo">
          <img
            src={newLogo || userImage || "/user_placeholder.png"}
            alt="estility admin"
            className=""
          />
        </div>
        <div className="accountinfo-edit-info-change-logo">
          <span>Change profile picture</span>
          <input
            type="file"
            title=""
            onChange={async ({ target: { files } }) => {
              if (files !== null) {
                const file = files[0];
                try {
                  handleUploadProfilePicture(file);
                  const imgSrc = await fileToBase64(file);
                  setNewLogo(imgSrc);
                } catch (error) {
                  // console.log(error)
                }
              }
            }}
          />
        </div>
      </div>
      <div className="accountinfo-edit-info-form">
        <div className="accountinfo-edit-info-form-group">
          <InputField
            label={userData?.first_name}
            name="firstName"
            value={uFirstName}
            onChange={(e) => setUFirstName(e.target.value)}
          />
          <InputField
            label={userData?.last_name}
            name="lastName"
            value={uLastName}
            onChange={(e) => setULasName(e.target.value)}
          />
        </div>
        <div className="accountinfo-edit-info-form-group">
          <input
            className="in ems-settings-in-edit"
            disabled={true}
            style={{ height: 55, fontSize: "medium" }}
            value={userData?.email}
          />
          <InputField
            label={userData?.mobile}
            name="mobile"
            value={uPhoneNUmber}
            onChange={(e) => setUPhoneNumber(e.target.value)}
          />
        </div>
        <InputField
          label={userData?.address?.addressString}
          name="address"
          value={uAddress}
          onChange={(e) => setUAddress(e.target.value)}
        />
        {/* <div className='accountinfo-edit-info-form-group'>
              <SelectInput options={
                      ["User", "Admin"]
                    } placeholder="Role" name={'residential'} value={state['residential']} onChange={({target: {name, value}}) => {
                      setState((p: any) => ({...p, [name]: value}));
                      // console.log(name, value)
                    }}  />
                <SelectInput options={
                    ["Permissions"]
                  } placeholder="Permissions"  name={'permissions'} value={state['permissions']} onChange={({target: {name, value}}) => {
                    setState((p: any) => ({...p, [name]: value}));
                    // console.log(name, value)
                  }}  />
            </div> */}
        <div className="accountinfo-edit-info-form-action-f-right">
          <span
            className="accountinfo-edit-info-cancel-btn"
            onClick={() => onNavigate && onNavigate(0)}
          >
            Cancel
          </span>
          <Button
            label="Save Changes"
            onClick={() => {
              updateUserProfile();
            }}
          />
        </div>
      </div>
      {/* Modal */}
      <SecondSuccess
        title={"Changes Saved"}
        text={"Changes to your user profile has been saved successfully"}
        isModalOpen={openSuccessModal}
        handleModalOpen={() => {
          setOpenSuccessModal(false);
          onNavigate && onNavigate(0);
        }}
        onBtnClick={() => {
          setOpenSuccessModal(false);
          onNavigate && onNavigate(0);
        }}
        buttonText={"Go Back to Dashboard"}
      />
      {/* Modal */}
    </div>
  );
};

export default AccountInfoEdit;
