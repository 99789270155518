import { ResidentsOptionsSvg } from "../../assets/resident-options-svg";
const columns = [
  {
    id: "resident_checkbox",
    label: (
      <input
        id="resident-checkbox-parent"
        type="checkbox"
        className="table-checkboxes resident-table-checkboxes-head"
      />
    ),
    minWidth: 20,
  },
  { id: "image", label: "", minWidth: 100 },
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "address",
    label: "Address",
    minWidth: 150,
    align: "left",
  },
  {
    id: "property_code",
    label: "Unit Code",
    minWidth: 130,
    align: "left",
  },
  {
    id: "mobile",
    label: "Phone Number",
    minWidth: 100,
    align: "left",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 150,
    align: "left",
  },
  {
    id: "level",
    label: "Resident Level",
    minWidth: 100,
    align: "left",
  },
  {
    id: "options",
    label: "",
    minWidth: 15,
    align: "left",
  },
];

function sortData(data, hardRefresh) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      resident_checkbox: (
        <input
          data={e?._id}
          id="resident-checkbox-children"
          type="checkbox"
          className="table-checkboxes resident-table-checkboxes"
        />
      ),
      image: (
        <img src={e?.profile_picture} alt="estility admin" className="resident-table-profile-pic" />
      ),
      name: e?.first_name + ` ${e.last_name}`,
      address:
        e?.address?.addressString?.length > 35
          ? e?.address?.addressString?.slice(0, 35) + "..."
          : e?.address?.addressString,
      property_code: e?.property_code || "-",
      mobile: e?.mobile,
      email: e?.email?.length > 40 ? e?.email.slice(0, 40) + "..." : e?.email,
      level: e?.resident_type ? e?.resident_type : "none",
      options: <ResidentsOptionsSvg hardRefresh={hardRefresh} data={e} />,
    };
  });

  return finalArray;
}

export { sortData, columns };
