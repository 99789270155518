import React, { useState } from "react";
import "../../styles/index.css";
import "./verification.css";
import VerificationVerify from "./screens/verify";
import VerificationHome from "./screens/home";
import SecondSuccess from "../../components/popup/second-success/second-success";

const Verification = ({ display }) => {
  const [showSucces, setShowSuccess] = useState(false);
  const [screen, setScreen] = useState({
    screen: "home",
    data: null,
  });

  if (!display) return;

  return (
    <div className="screen-container">
      <VerificationHome setScreen={setScreen} display={screen.screen === "home" && true} />
      <VerificationVerify
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "verify" && true}
        setShowSuccess={setShowSuccess}
      />
      <SecondSuccess
        title={"Visitor Verification Successful"}
        text={
          "The code has been successfully verified, you can now allow them entrace into the estate"
        }
        isModalOpen={showSucces}
        handleModalOpen={setShowSuccess}
        buttonText={"Go to Dashboard"}
        onBtnClick={() => setShowSuccess(false)}
      />
    </div>
  );
};

export default Verification;
