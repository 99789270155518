import { CheckSquare, Square } from 'phosphor-react'
import './PermissionChild.css'


type Props = {
    value: boolean;
    name: string;
    onChange?: (arg: {name: string, value: boolean}) => void;
    label: string
}

const PermissionChild = ({name, value, label, onChange}: Props) => {

    const handleChange = () => {
        onChange && onChange({name, value: !value})

    }
  return (
    <div className='permit-check-child'>
        {value? <CheckSquare weight='fill' className='permit-check-child-checked' onClick={handleChange} /> : <Square weight='bold' className='permit-check-child-unchecked' onClick={handleChange}/>}
        <div className='permit-check-child-value'>{label}</div>
    </div>
  )
}

export default PermissionChild