import React, { useState } from "react";
import "../../styles/index.css";
import "./settings.css";
import TabSwitcher from "../../components/tabSwitcher/TabSwitcher.tsx";
import EstateInfo from "./estateInfo/EstateInfo.tsx";
import { VscAccount } from "react-icons/vsc";
import { CiHome } from "react-icons/ci";
import { CgPassword } from "react-icons/cg";
import AccountInfo from "./accountInfo/AccountInfo";
import { BsCreditCard } from "react-icons/bs";
import { BiUserCheck } from "react-icons/bi";
import { MdOutlineLiveHelp } from "react-icons/md";
import Faq from "./faq/Faq";
import BillingSettings from "./billing/BillingSettings";
import SecuritySettings from "./security/SecuritySettings";
import EstateUsers from "./users/estate-users";

const Settings = ({ display }) => {
  const [active, setActive] = useState(0);

  if (!display) return;

  return (
    <div className="screen-container">
      <TabSwitcher
        title="Settings"
        panes={[
          {
            tab: (
              <div>
                <CiHome style={{ fontSize: "1rem" }} />
                <span>Estate info</span>
              </div>
            ),
            body: <EstateInfo />,
          },
          {
            tab: (
              <div>
                <VscAccount style={{ fontSize: "1rem" }} />
                <span>Account info</span>
              </div>
            ),
            body: <AccountInfo />,
          },
          {
            tab: (
              <div>
                <CgPassword style={{ fontSize: "1rem" }} />
                <span>Security</span>
              </div>
            ),
            body: <SecuritySettings />,
          },
          {
            tab: (
              <div>
                <BsCreditCard style={{ fontSize: "1rem" }} />
                <span>Billing</span>
              </div>
            ),
            body: <BillingSettings />,
          },
          {
            tab: (
              <div>
                <BiUserCheck style={{ fontSize: "1rem" }} />
                <span>Users</span>
              </div>
            ),
            body: <EstateUsers active={active} />,
          },
          {
            tab: (
              <div>
                <MdOutlineLiveHelp style={{ fontSize: "1rem" }} />
                <span>Estility support</span>
              </div>
            ),
            body: <Faq />,
          },
        ]}
        onClick={(index) => {
          setActive(index);
        }}
      />
    </div>
  );
};

export default Settings;
