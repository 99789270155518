import React, { useState } from 'react';
import './contact.css';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Editor from '../../editor/Editor';
import InputField from '../../inputField/InputField';
import SelectInput from '../../selectInput/SelectInput';

type IContact = {
  isOpen?: boolean;
  onClose?: () => void;
  onSendMsg?: (arg: any) => void;
}
const Contact = ({onClose, onSendMsg}: IContact) => {

  const [state, setState]  = useState<any>({})
  const [htmlStr, setHtmlStr]  = useState<string>('')

  return (
    <div className='modal-container'>
      <div className='contact-role-modal-body'>
      <div className="create-role-modal-body-close-btn">
          <AiOutlineCloseCircle
            onClick={() => {
              onClose && onClose()
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>
    <div className='contact-container'>
      <div className='contact-form-title'>
        <div className='contact-form-header'>Reach Out to Us</div>
      </div>
     <div className='contact-subtitle'>Send us a message and our team will get back to you as soon as possible</div>
     <form className='contact-form'>
        <InputField placeholder='Sarah Johnson' label='name' name='name' value={state['name']} onChange={({target: {name, value}}) => setState((p:any) => ({...p, [name]: value}))} />
        <InputField placeholder='Admin@storefront.com' label='email' name='email' value={state['email']} onChange={({target: {name, value}}) => setState((p:any) => ({...p, [name]: value}))} />
        <InputField placeholder='Admin@storefront.com' label='email' name='email2' value={state['email2']} onChange={({target: {name, value}}) => setState((p:any) => ({...p, [name]: value}))} />
        <SelectInput name='type' options={['Wallet', 'Product', 'Orders', 'Others']} onChange={({target: {name, value}}) => setState((p:any) => ({...p, [name]: value}))} />
        <div className='contact-subtitle'>Description</div>
        <div>
          <Editor
          value={htmlStr}
          placeholder='Enter your message here'
          maxWidth={'6rem'} name='message'  onChange={({text, htmlString, rawHtmString}) => {
            rawHtmString && setHtmlStr(rawHtmString);
            setState((p: any) => ({...p, message: text}))}} />
        </div>

        <button type='button' onClick={() => onSendMsg && onSendMsg(state)} className='contact-button'>Send</button>
      </form>
    </div>
    </div>
    </div>
  )
}

export default Contact