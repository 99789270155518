import React, { useState } from "react";
import "../../styles/index.css";
import "./visitors.css";
import VisitorsHome from "./children/visitors-home";
import VisitorsDetails from "./children/visitors-details";

const Visitors = function ({ display }) {
  const [screen, setScreen] = useState({
    screen: "home",
    data: null,
  });

  if (!display) return;

  return (
    <div className="screen-container">
      <VisitorsHome
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "home" && true}
      />
      <VisitorsDetails
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "details" && true}
      />
    </div>
  );
};

export default Visitors;
