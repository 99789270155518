const columns = [
  { id: "image", label: "", minWidth: 30 },
  { id: "name", label: "Name", minWidth: 150 },
  { id: "role", label: "Role", minWidth: 100 },
  { id: "mobile", label: "Phone", minWidth: 150 },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    align: "left",
  },
  {
    id: "actions",
    label: "",
    minWidth: 100,
    align: "left",
  },
];

function sortData(data, setShowEditEstateUser, deleteEstateUser) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      image: (
        <img src={e?.profile_picture} alt="estility users" className="resident-table-profile-pic" />
      ),
      name:
        (e?.first_name + " " + e?.last_name).length > 50
          ? (e?.first_name + " " + e?.last_name).slice(0, 50) + "..."
          : e?.first_name + " " + e?.last_name,
      role: e?.estate_child_roles?.join(", "),
      mobile: e?.mobile,
      email: e?.email?.length > 50 ? e?.email.slice(0, 50) + "..." : e?.email,
      actions: (
        <>
          <span>
            {" "}
            <img
              title="Edit this staff detail"
              onClick={() =>
                setShowEditEstateUser({
                  display: true,
                  data: {
                    first_name: e?.first_name,
                    last_name: e?.last_name,
                    role: e?.role,
                    mobile: e?.mobile,
                    email: e?.email,
                    _id: e?._id,
                    estate_child_roles: e?.estate_child_roles,
                  },
                })
              }
              className="cursor"
              src="/edit.png"
              alt="edit-icon"
            />
          </span>
          &nbsp; &nbsp;
          <span>
            {" "}
            <img
              title="Delete this staff"
              onClick={() => deleteEstateUser(e?._id)}
              className="cursor"
              src="/delete.png"
              alt="delete-icon"
            />
          </span>
        </>
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
