import React, { useState, useEffect, useContext } from "react";
import "../styles/index.css";
import "./login.css";
import AppContext from "../context/app-context";
import { popup } from "../vanilla-functions/model";
import ResetPassword from "./reset-password";

const Login = function ({}) {
  const [disableBtn, setDisableBtn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);

  const {
    setLoggedIn,
    backendServer,
    setIsLoading,
    setUsersData,
    loggedIn,
    getUserFromGlobalBookAndUpdateState,
  } = useContext(AppContext);

  useEffect(() => {
    if (email && password) setDisableBtn(false);
    else setDisableBtn(true);
  });

  async function login() {
    setIsLoading(true);
    try {
      const BaseURL = await getUserFromGlobalBookAndUpdateState(email);

      if (!BaseURL) throw new Error("Failed to retrieve user information. Please try again later.");

      const returned = await (
        await fetch(`${BaseURL}/admin/login`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email, loginPass: password }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      localStorage.setItem("emsusername", returned.data.username);
      localStorage.setItem("emsemail", returned.data.email);
      localStorage.setItem("emstoken", returned.data.token);

      const data = await (
        await fetch(`${BaseURL}/user/get?email=${returned.data.email}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (!data?.data?.is_active) throw new Error("Authentication error");

      setUsersData(data.data);

      setIsLoading(false);

      setLoggedIn(true);

      popup(`Welcome back, ${data.data.first_name}`);

      document.location.hash = "Dashboard";
    } catch (err) {
      popup(err.message || "Something went wrong");
      setIsLoading(false);
    }
  }

  async function runAutoLogin() {
    if (!backendServer) return;
    setIsLoading(true);
    try {
      const data = await (
        await fetch(`${backendServer}/user/get?email=${localStorage.getItem("emsemail")}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (!data?.data?.is_active) throw new Error("Authentication error");

      setUsersData(data.data);

      setLoggedIn(true);
      setIsLoading(false);
    } catch (err) {
      popup("Login Failed. Please login again...");
      setIsLoading(false);
      setLoggedIn(false);
      localStorage.removeItem("emsusername");
      localStorage.removeItem("emsemail");
      localStorage.removeItem("emstoken");
      localStorage.removeItem("emsbaseurl");
    }
  }

  useEffect(() => {
    if (localStorage.getItem("emsusername") && localStorage.getItem("emsbaseurl")) runAutoLogin();
    else {
      setLoggedIn(false);
      document.location.hash = "auth";
    }
  }, [backendServer]);

  if (loggedIn === "none") return;

  return (
    <>
      {!showResetPassword && (
        <div className="login-container">
          <>
            <div className="login-container-one">
              <div className="wavy-container">
                <img className="wavy-img" src="/wavy.png" alt="Estility" />
                <div className="wavy-content">
                  <div className="flex-row align-row-left" style={{ gap: 20, width: "50%" }}>
                    <img className="wavy-logo" src="/logo.png" alt="Estility" />
                    <p className="big whiteText removemargin" style={{ fontSize: "25px" }}>
                      Estility
                    </p>
                  </div>
                  {/* <p className="small blackText cursor">
                    <FiSettings style={{ marginTop: -5 }} /> &nbsp; Help
                  </p> */}
                </div>
              </div>

              <div className="login-cont">
                <p className="big boldText removemargin">Welcome Back to your estate!</p>
                <span className="small removemargin">
                  Get back to managing and overseeing your estate
                </span>

                <input
                  className="in login-in"
                  type="text"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value?.toLowerCase())}
                />
                <input
                  className="in login-in"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />

                <div className="flex-row space-between no-padding forgot-remember-login">
                  <p></p>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setShowResetPassword(true);
                    }}
                    href="#"
                    className="small themeText"
                  >
                    Reset Password
                  </a>
                </div>

                <button disabled={disableBtn} onClick={login} className="themeBtn login-btn">
                  Sign In
                </button>
              </div>
            </div>
            <div className="login-container-two">
              <img className="login-banner-img" src="/login-banner.png" alt="Estility" />
            </div>
          </>
        </div>
      )}
      {showResetPassword && <ResetPassword setShowResetPassword={setShowResetPassword} />}
    </>
  );
};

export default Login;
