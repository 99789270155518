import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "../login.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";

const BeginReset = function ({ display, setStage, setEmail, email, setCodeSentFromServer }) {
  const [disableBtn, setDisableBtn] = useState(false);

  const { setIsLoading, validateEmail, getUserFromGlobalBookAndUpdateState } =
    useContext(AppContext);

  useEffect(() => {
    if (email && validateEmail(email)) setDisableBtn(false);
    else setDisableBtn(true);
  });

  async function beginRest() {
    setIsLoading(true);
    try {
      const BaseURL = await getUserFromGlobalBookAndUpdateState(email);

      if (!BaseURL) throw new Error("Failed to retrieve user information. Please try again later.");

      const returned = await (
        await fetch(`${BaseURL}/password/init`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: email }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setCodeSentFromServer(String(returned?.data));
      setStage("code-screen");
      setIsLoading(false);
    } catch (err) {
      popup(err.message || "Something went wrong");
      setIsLoading(false);
    }
  }

  if (!display) return;

  return (
    <div className="login-container-one">
      <div className="wavy-container">
        <div className="wavy-content">
          <div className="flex-row align-row-left" style={{ gap: 20, width: "50%" }}>
            <img className="wavy-logo" src="/logo.png" alt="Estility" />
          </div>
        </div>
      </div>

      <div className="login-cont">
        <div className="flex-row align-row-left code-box-edit-cont">
          <p className="small boldText removemargin">
            Please type in your email address to reset your password
          </p>
        </div>
        <input
          className="in login-in"
          type="text"
          placeholder="Email Address"
          onChange={(e) => setEmail(e.target.value?.toLowerCase())}
        />

        <button disabled={disableBtn} onClick={beginRest} className="themeBtn login-btn">
          Reset Password
        </button>
      </div>
    </div>
  );
};

export default BeginReset;
