import React, { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import { IoMdArrowBack } from "react-icons/io";
import {
  ResidentsTableTable,
  pseudoCheckBoxSelectedData,
} from "../../../components/residents-table/residents-table";
import AppContext from "../../../context/app-context";
import { CgSortAz } from "react-icons/cg";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { popup } from "../../../vanilla-functions/model";
import SearchInput from "../../../components/searchInput/SearchInput";

const ResidentsTable = function ({ display, screen, setScreen }) {
  const [page, setPage] = useState(0);
  const [resolvedData, setResolvedData] = useState([]);
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const [sortBy, setSortBy] = useState(true);
  const [hideResidentTable, setHideResidentTable] = useState(false);
  const { getSignedAwsUrl, patternMatching, setIsLoading, backendServer, estate } =
    useContext(AppContext);

  useEffect(() => {
    if (!screen?.residentsTableData) return;
    getData([...screen?.residentsTableData]);
  }, [screen, display]);

  async function getData(raw) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#resident-checkbox-parent"))
      document.querySelector("#resident-checkbox-parent").checked = false;
    const comps = await Promise.all(
      raw?.map(async (e) => {
        if (e.profile_picture && e.profile_picture !== "/user_placeholder.png")
          e.profile_picture = await getSignedAwsUrl(e?.profile_picture, "profile-pictures");
        else e.profile_picture = "/user_placeholder.png";
        return e;
      })
    );
    setResolvedData(comps);
    setcsvData([
      ["Name", "Address", "Phone Number", "Email Address", "Resident Level"],
      ...comps?.map(({ first_name, last_name, address, mobile, email, resident_type }) => {
        return [
          first_name + ` ${last_name}`,
          address?.addressString,
          mobile,
          email,
          resident_type ? resident_type : "none",
        ];
      }),
    ]);
  }

  function sort() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#resident-checkbox-parent"))
      document.querySelector("#resident-checkbox-parent").checked = false;
    if (sortBy) {
      const sortedArray = [];
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Primary"));
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Property Owner"));
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Secondary"));
      sortedArray.push(...[...resolvedData].filter((e) => !e?.resident_type));
      setResolvedData([...sortedArray]);
      setcsvData([
        ["Name", "Address", "Phone Number", "Email Address", "Resident Level"],
        ...sortedArray?.map(({ first_name, last_name, address, mobile, email, resident_type }) => {
          return [
            first_name + ` ${last_name}`,
            address?.addressString,
            mobile,
            email,
            resident_type ? resident_type : "none",
          ];
        }),
      ]);
    } else {
      const sortedArray = [];
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Property Owner"));
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Secondary"));
      sortedArray.push(...[...resolvedData].filter((e) => e.resident_type === "Primary"));
      sortedArray.push(...[...resolvedData].filter((e) => !e?.resident_type));
      setResolvedData([...sortedArray]);
      setcsvData([
        ["Full Name", "Address", "Phone Number", "Email Address", "Resident Level"],
        ...sortedArray?.map(({ first_name, last_name, address, mobile, email, resident_type }) => {
          return [
            first_name + ` ${last_name}`,
            address?.addressString,
            mobile,
            email,
            resident_type ? resident_type : "none",
          ];
        }),
      ]);
    }
    setSortBy(!sortBy);
  }

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#resident-checkbox-parent"))
      document.querySelector("#resident-checkbox-parent").checked = false;
    const query = e.target.value;

    if (!query) return getData([...screen?.residentsTableData]);

    const foundResidents = screen?.residentsTableData?.filter((e) => {
      return (
        patternMatching(query, e.first_name + ` ${e.last_name}`) ||
        patternMatching(query?.split(" ")[0], e.first_name) ||
        patternMatching(query?.split(" ")[0], e.last_name) ||
        patternMatching(query?.split(" ")[1], e.first_name) ||
        patternMatching(query?.split(" ")[1], e.last_name)
      );
    });
    getData(foundResidents);

    setcsvData([
      ["Name", "Address", "Phone Number", "Email Address", "Resident Level"],
      ...foundResidents?.map(({ first_name, last_name, address, mobile, email, resident_type }) => {
        return [
          first_name + ` ${last_name}`,
          address?.addressString,
          mobile,
          email,
          resident_type ? resident_type : "none",
        ];
      }),
    ]);
  }

  async function hardRefresh() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/property/get?property_code=${screen?.detailsID}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      getData([...returned?.data?.residents.filter((e) => e.is_active)]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching Property Data");
    }
  }

  if (!display) return;

  return (
    <div>
      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        {!hideResidentTable && (
          <p className="small removemargin">
            <IoMdArrowBack
              onClick={() =>
                setScreen({
                  screen: "details",
                  residentsTableData: null,
                  detailsID: screen?.detailsID,
                })
              }
              style={{ marginTop: -3 }}
              size={17}
              className="cursor"
            />{" "}
            &nbsp; Property Residents
          </p>
        )}
      </div>
      <div className="p-table-container">
        {!hideResidentTable && (
          <div
            style={{
              marginBottom: 15,
              width: "100%",
              gap: "1rem",
              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SearchInput placeholder="search for a resident" onChange={(e) => searchPlease(e)} />
            <CgSortAz onClick={sort} className="cursor" title="Sort By Resident Level" size={30} />
            <CSVLink
              className="cursor small csv-link"
              filename={`${estate?.estate_name} Residents Data`}
              data={csvData}
            >
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>
            <FiRefreshCcw className="cursor" title="Refresh Data" size={20} onClick={hardRefresh} />
            {/* <div className="flex-row" style={{ width: "fit-content", gap: 10 }}>
            </div> */}
          </div>
        )}
        <ResidentsTableTable
          setHideResidentTable={setHideResidentTable}
          data={resolvedData}
          screen={screen}
          page={page}
          setPage={setPage}
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          checkBoxSelectedData={checkBoxSelectedData}
          setcsvData={setcsvData}
          hardRefresh={hardRefresh}
        />
      </div>
    </div>
  );
};

export default ResidentsTable;
