const columns = [
  { id: "_id", label: "", minWidth: 0 },
  { id: "biller", label: "Biller", minWidth: 170 },
  { id: "bill_type", label: "Due/Fee Type", minWidth: 100 },
  { id: "bill_payer", label: "Bill Payer", minWidth: 170 },
  {
    id: "property_type",
    label: "Property Type",
    minWidth: 170,
    align: "left",
  },
  {
    id: "bill_frequency",
    label: "Frequency",
    minWidth: 100,
    align: "left",
  },
  {
    id: "amount",
    label: "Amount (NGN)",
    minWidth: 100,
    align: "center",
  },
  {
    id: "actions",
    label: "",
    minWidth: 100,
    align: "left",
  },
];

function sortData(data, setShowEditBillModal, deleteDue, estate) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      _id: <span data-id={e?._id}></span>,
      biller: estate?.estate_name,
      bill_type: e?.bill_type,
      bill_payer: e?.bill_payer,
      property_type: e?.property_type,
      bill_frequency: e?.bill_frequency,
      amount: e?.amount,
      actions: (
        <>
          <span>
            {" "}
            <img
              onClick={(e) => {
                e.stopPropagation();
                setShowEditBillModal({
                  display: true,
                  data: {
                    bill_type: e?.bill_type,
                    bill_payer: e?.bill_payer,
                    property_type: e?.property_type,
                    bill_frequency: e?.bill_frequency,
                    amount: e?.amount,
                    id: e?._id,
                  },
                });
              }}
              className="cursor"
              src="/edit.png"
              alt="edit-icon"
            />
          </span>
          &nbsp; &nbsp;
          <span>
            {" "}
            <img
              onClick={(el) => {
                el.stopPropagation();
                deleteDue(e?._id);
              }}
              className="cursor"
              src="/delete.png"
              alt="delete-icon"
            />
          </span>
        </>
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
