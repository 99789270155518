import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../properties.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import {
  PropertiesTable,
  pseudoCheckBoxSelectedData,
} from "../../../components/properties-table/properties-table";
import { CgSortAz } from "react-icons/cg";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import SearchInput from "../../../components/searchInput/SearchInput";
import PropertyStatusFilter from "./property-status-filter";

const PropertiesHome = function ({ display, setScreen }) {
  const [tableFilterStatus, setTableFIlterStatus] = useState({
    occupancyStatus: "",
    propertyType: "",
  });

  const [showTableFilter, setShowTableFilter] = useState(false);

  const [page, setPage] = useState(0);
  const [csvData, setcsvData] = useState([]);
  const [properties, setProperties] = useState(null);
  const [oringinalData, setOriginalData] = useState([]);
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const { backendServer, usersData, setIsLoading, estate } = useContext(AppContext);

  useEffect(() => {
    getData();
  }, [usersData]);

  async function getData(noLoader) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#property-checkbox-parent"))
      document.querySelector("#property-checkbox-parent").checked = false;
    try {
      if (!noLoader) setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/property/get`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const theReversed = [...returned?.data];
      theReversed.reverse();
      setProperties(theReversed);
      setOriginalData(theReversed);
      setIsLoading(false);

      workCSV(theReversed);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching Estate Properties");
    }
  }

  function workCSV(data) {
    setcsvData([
      [
        "Property Code",
        "Property Type",
        "Address",
        "No. of Units",
        "No. of Residents",
        "Ocuppied Status",
        "Date Created",
      ],
      ...data?.map(
        ({
          property_code,
          property_type,
          full_address,
          number_of_units,
          residents,
          occupied,
          createdAt,
        }) => {
          return [
            property_code,
            property_type,
            full_address?.house_number +
              " " +
              full_address?.street_name +
              " " +
              full_address?.city +
              " " +
              full_address?.state +
              " ",
            number_of_units,
            residents?.length,
            occupied,
            new Date(createdAt),
          ];
        }
      ),
    ]);
  }

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#property-checkbox-parent"))
      document.querySelector("#property-checkbox-parent").checked = false;
    const query = e.target.value;

    if (!query) return getData(true);

    let filteredData;

    if (
      oringinalData?.find((e) => {
        return e.property_code === String(query).toUpperCase();
      })
    ) {
      filteredData = [
        oringinalData?.find((e) => {
          return e.property_code === String(query).toUpperCase();
        }),
      ];
    } else {
      filteredData = oringinalData?.filter((e) => {
        return String(query)
          .toUpperCase()
          .split("")
          .some((el) => e.property_code.split("").includes(el));
      });
    }

    setProperties(filteredData);
    workCSV(filteredData);
  }

  function runTableFilter() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#property-checkbox-parent"))
      document.querySelector("#property-checkbox-parent").checked = false;

    let sortedArray = [...oringinalData];

    if (tableFilterStatus.occupancyStatus) {
      sortedArray = sortedArray.filter(
        (e) => e.occupied === (tableFilterStatus.occupancyStatus === "yes" ? true : false)
      );
    }

    if (tableFilterStatus.propertyType) {
      sortedArray = sortedArray.filter((e) => e?.property_type === tableFilterStatus?.propertyType);
    }
    setProperties([...sortedArray]);

    workCSV(sortedArray);

    setShowTableFilter(false);
  }

  if (!display) return;

  return (
    <div>
      <div className="properties-stat-container flex-row">
        <div className="each-p-stat-container">
          <img src="/d-2.png" alt="estility-properties" />
          <p className="small greyText removemargin">
            Total Properties<br></br>
            <span className="big p-stat-text">{oringinalData?.length || "-"}</span>
          </p>
        </div>

        <div className="each-p-stat-container">
          <img src="/p-2.png" alt="estility-properties" />
          <p className="small greyText removemargin">
            Total Units<br></br>
            <span className="big p-stat-text">
              {(() => {
                let total = 0;
                oringinalData?.forEach((e) => {
                  total = total + e.number_of_units;
                });
                return total;
              })() || "-"}
            </span>
          </p>
        </div>

        <div
          className="each-p-stat-container cursor"
          onClick={() => {
            setPage(0);
            pseudoCheckBoxSelectedData.length = 0;
            if (document.querySelector("#property-checkbox-parent"))
              document.querySelector("#property-checkbox-parent").checked = false;
            let sortedArray = [...oringinalData];
            sortedArray = sortedArray.filter((e) => e.occupied === true);
            setProperties([...sortedArray]);
            workCSV(sortedArray);
          }}
        >
          <img src="/p-3.png" alt="estility-properties" />
          <p className="small greyText removemargin">
            Occupied Properties<br></br>
            <span className="big p-stat-text">
              {oringinalData?.filter((e) => e.occupied === true)?.length || "-"}
            </span>
          </p>
        </div>

        <div
          className="each-p-stat-container cursor"
          onClick={() => {
            setPage(0);
            pseudoCheckBoxSelectedData.length = 0;
            if (document.querySelector("#property-checkbox-parent"))
              document.querySelector("#property-checkbox-parent").checked = false;
            let sortedArray = [...oringinalData];
            sortedArray = sortedArray.filter((e) => e.occupied === false);
            setProperties([...sortedArray]);
            workCSV(sortedArray);
          }}
        >
          <img src="/p-4.png" alt="estility-properties" />
          <p className="small greyText removemargin">
            Unoccupied Properties<br></br>
            <span className="big p-stat-text">
              {oringinalData?.filter((e) => e.occupied === false)?.length || "-"}
            </span>
          </p>
        </div>
      </div>
      <div className="p-table-container">
        <div
          style={{
            marginBottom: 15,
            width: "100%",
            gap: "1rem",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SearchInput
            placeholder="search for a property (e.g. ES44B5)"
            onChange={(e) => searchPlease(e)}
          />
          <CgSortAz
            onClick={setShowTableFilter}
            className="cursor"
            title="Sort By Occupied Status"
            size={30}
          />
          <CSVLink
            className="cursor small csv-link"
            filename={`${estate?.estate_name} Properties Data`}
            data={csvData}
          >
            <BsPrinter title="Print CSV" size={20} />
          </CSVLink>

          <FiRefreshCcw
            className="cursor"
            title="Refresh Data"
            size={20}
            onClick={() => getData(false)}
          />
        </div>
        <PropertiesTable
          checkBoxSelectedData={checkBoxSelectedData}
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          page={page}
          setPage={setPage}
          data={properties}
          setScreen={setScreen}
          setcsvData={setcsvData}
        />
      </div>
      <PropertyStatusFilter
        isOpen={showTableFilter}
        setIsOpen={setShowTableFilter}
        getData={getData}
        runTableFilter={runTableFilter}
        setTableFIlterStatus={setTableFIlterStatus}
        tableFilterStatus={tableFilterStatus}
      />
    </div>
  );
};

export default PropertiesHome;
