import React, { useState, useEffect, useContext, useRef } from "react";
import "../../styles/index.css";
import "./add-a-user-modal.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";
import SecondaryResidentsCard from "./secondary-residents-cards";

const inputAlert = {
  residentType: "",
  firstName: "",
  lastName: "",
  fullAddress: "",
  mobile: "",
  email: "",
  unitsOccupied: "",
  username: "",
  password: "",
  secondaryResidents: "",
};
const secondaryResidentsData = [];

const AddAUserModal = function ({ display, setAddAUserModal }) {
  const { backendServer, setIsLoading, validateEmail, updateUsersData, validateMobile, estate } =
    useContext(AppContext);
  const [allPropertiesComp, setallPropertiesComp] = useState("");
  const [residentType, setResidentType] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [fullAddress, setfullAddress] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [unitsOccupied, setunitsOccupied] = useState("");
  const [falseState, setFalseState] = useState("");
  const [_falseState2, setFalseState2] = useState("");
  const [pCode, setPCode] = useState("");

  const [onView, setOnView] = useState("home");
  const [secondaryResidentsComps, setSecondaryResidentsComps] = useState([]);

  const inputRefs = useRef({
    residentTypeRef: "",
    firstNameRef: "",
    lastNameRef: "",
    mobileRef: "",
    emailRef: "",
    unitsOccupiedRef: "",
  });

  useEffect(() => {
    (async function () {
      const returned = await (
        await fetch(`${backendServer}/property/get`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setallPropertiesComp(
        returned?.data?.map((e, i) => (
          <MDBDropdownItem
            key={i}
            pcode={e.property_code}
            id={e._id}
            link
            onClick={(e) => {
              setfullAddress(e.target.textContent);
              setPCode(e.target.parentElement.getAttribute("pcode"));
            }}
          >
            {e?.full_address?.house_number + " " + e?.full_address?.street_name}
          </MDBDropdownItem>
        ))
      );
    })();
  }, [display]);

  async function addUser() {
    if (!validateFields()) return;

    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/signup/user`, {
          method: "POST",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            email: email,
            mobile: mobile,
            address: {
              addressString: fullAddress,
              geoLocation: {
                coordinates: ["", ""],
              },
              postCode: "000000",
            },
            estateID: estate?._id,
            login_token: {
              token_id: "1",
              token: "1",
            },
            resident_type: residentType,
            units_occupied: unitsOccupied,
            secondaryResidents: secondaryResidentsData,
            property_code: pCode,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setIsLoading(false);
      popup("Resident Added");
      setResidentType("");
      setfirstName("");
      setlastName("");
      setfullAddress("");
      setmobile("");
      setemail("");
      setunitsOccupied("");
      secondaryResidentsData.length = 0;
      setOnView("home");
      updateUsersData();
      setAddAUserModal({
        display: false,
        data: {},
      });
    } catch (err) {
      setIsLoading(false);
      popup("Error creating resident, try again later");
    }
  }

  useEffect(() => {
    if (fullAddress) {
      inputAlert.fullAddress = "";
      setFalseState2({});
    }
    if (residentType) {
      inputAlert.residentType = "";
      setFalseState2({});
    }
    if (firstName) {
      inputAlert.firstName = "";
      setFalseState2({});
    }
    if (lastName) {
      inputAlert.lastName = "";
      setFalseState2({});
    }
    if (mobile && validateMobile(mobile)) {
      inputAlert.mobile = "";
      setFalseState2({});
    }
    if (email && validateEmail(email)) {
      inputAlert.email = "";
      setFalseState2({});
    }
    if (unitsOccupied && unitsOccupied > 0) {
      inputAlert.unitsOccupied = "";
      setFalseState2({});
    }
    if (
      !secondaryResidentsData.find((e) => {
        if (
          !e.full_name ||
          !e?.email ||
          !e?.mobile ||
          !validateEmail(e?.email) ||
          !validateMobile(e?.mobile)
        )
          return e;
      })
    ) {
      inputAlert.secondaryResidents = "";
      setFalseState2({});
    }
  }, [
    residentType,
    firstName,
    lastName,
    fullAddress,
    mobile,
    email,
    unitsOccupied,
    secondaryResidentsData,
    falseState,
  ]);

  function validateFields() {
    if (!fullAddress) {
      inputAlert.fullAddress = "property address is required";
      setFalseState({});
      return false;
    } else if (!residentType) {
      inputAlert.residentType = "we need the resident type";
      setFalseState({});
      return false;
    } else if (!firstName) {
      inputAlert.firstName = "first name is required";
      setFalseState({});
      inputRefs.current.firstNameRef.focus();
      return false;
    } else if (!lastName) {
      inputAlert.lastName = "last name is required";
      setFalseState({});
      inputRefs.current.lastNameRef.focus();
      return false;
    } else if (!mobile || !validateMobile(mobile)) {
      inputAlert.mobile = "mobile is missing or invalid";
      setFalseState({});
      inputRefs.current.mobileRef.focus();
      return false;
    } else if (!email || !validateEmail(email)) {
      inputAlert.email = "email is missing or invalid";
      setFalseState({});
      inputRefs.current.emailRef.focus();
      return false;
    } else if (!unitsOccupied || Number(unitsOccupied) < 1) {
      inputAlert.unitsOccupied = "units occupied is required";
      setFalseState({});
      inputRefs.current.unitsOccupiedRef.focus();
      return false;
    } else if (
      secondaryResidentsData.find((e) => {
        if (
          !e.full_name ||
          !e?.email ||
          !validateMobile(e?.mobile) ||
          !e?.mobile ||
          !validateEmail(e?.email)
        )
          return e;
      })
    ) {
      inputAlert.secondaryResidents = "incomplete secondary resident(s) data";
      setFalseState({});
      return false;
    } else return true;
  }

  return (
    <div className="modal-container" style={{ display: `${display ? "flex" : "none"}` }}>
      <div className="modal-body edit-a-user-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setAddAUserModal({
                display: false,
                data: {},
              });
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div
          className="a-user-modal-cont flex-column align-column-left"
          style={{ display: `${onView === "home" ? "flex" : "none"}` }}
        >
          <div className="flex-row align-row-left">
            <p className="big">Create new Resident by adding their details below</p>
          </div>

          <div className="flex-row">
            <MDBDropdown id="mdb-dropdown-custom-dropdown">
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {fullAddress || "Property Address"}
              </MDBDropdownToggle>
              <MDBDropdownMenu id="eedit-a-a-user-mdb-menu-custom-dropdown">
                {allPropertiesComp}
              </MDBDropdownMenu>
            </MDBDropdown>

            <MDBDropdown id="mdb-dropdown-custom-dropdown">
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {residentType || "Resident Type"}
              </MDBDropdownToggle>

              <MDBDropdownMenu id="edit-a-a-user-mdb-menu-custom-dropdown">
                <MDBDropdownItem
                  link
                  href="#Residents"
                  onClick={(e) => {
                    setResidentType(e.target.textContent);
                  }}
                >
                  Primary
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Residents"
                  onClick={(e) => {
                    setResidentType(e.target.textContent);
                  }}
                >
                  Secondary
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Residents"
                  onClick={(e) => {
                    setResidentType(e.target.textContent);
                  }}
                >
                  Property Owner
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

            <div className="flex-row alert-container">
              <p className="redText xsmall removemargin alignTextLeft">{inputAlert.fullAddress}</p>
              <p className="redText xsmall removemargin alignTextLeft">{inputAlert.residentType}</p>
            </div>
          </div>

          <div className="flex-row">
            <input
              ref={(ref) => (inputRefs.current.firstNameRef = ref)}
              className="in a-a-u-inputs"
              placeholder="First Name"
              type="text"
              value={firstName}
              onChange={(e) => setfirstName(e.target.value)}
            />
            <input
              ref={(ref) => (inputRefs.current.lastNameRef = ref)}
              className="in a-a-u-inputs"
              placeholder="Last Name"
              type="text"
              maxLength="100"
              value={lastName}
              onChange={(e) => setlastName(e.target.value)}
            />
            <div className="flex-row alert-container">
              <p className="redText xsmall removemargin alignTextLeft">{inputAlert.firstName}</p>
              <p className="redText xsmall removemargin alignTextLeft">{inputAlert.lastName}</p>
            </div>
          </div>

          <div className="flex-row">
            <input
              ref={(ref) => (inputRefs.current.emailRef = ref)}
              className="in a-a-u-inputs"
              placeholder="Email"
              type="email"
              maxLength="100"
              value={email}
              onChange={(e) => {
                if (!email || !validateEmail(email)) {
                  inputAlert.email = "email is missing or invalid";
                }
                setemail(e.target.value);
              }}
            />
            <input
              ref={(ref) => (inputRefs.current.mobileRef = ref)}
              className="in a-a-u-inputs"
              placeholder="Mobile"
              type="text"
              value={mobile}
              onChange={(e) => {
                if (!mobile || !validateMobile(mobile)) {
                  inputAlert.mobile = "mobile is missing or invalid";
                }
                setmobile(e.target.value);
              }}
            />
            <div className="flex-row alert-container">
              <p className="redText xsmall removemargin alignTextLeft">{inputAlert.email}</p>
              <p className="redText xsmall removemargin alignTextLeft">{inputAlert.mobile}</p>
            </div>
          </div>

          <div className="flex-row">
            <input
              ref={(ref) => (inputRefs.current.unitsOccupiedRef = ref)}
              className="in a-a-u-inputs"
              placeholder="Units Occupied"
              type="number"
              value={unitsOccupied}
              onChange={(e) => setunitsOccupied(e.target.value)}
            />
            <input
              className="in a-a-u-inputs"
              placeholder=""
              type="FAKE INPUT"
              maxLength="100"
              value={""}
              style={{ opacity: 0 }}
              onChange={() => {}}
            />
            <div className="flex-row alert-container">
              <p className="redText xsmall removemargin alignTextLeft">
                {inputAlert.unitsOccupied}
              </p>
            </div>
          </div>
        </div>

        <div
          className="a-user-modal-cont flex-column align-column-left"
          style={{
            display: `${onView === "secondary" ? "flex" : "none"}`,
            alignItems: "flex-start",
          }}
        >
          <div className="flex-row align-row-left removemargin">
            <p className="big removemargin">
              Create new Seondary Resident by adding their details below
            </p>
          </div>

          <p className="small removemargin boldText" style={{ fontSize: 14, textAlign: "left" }}>
            Primary Resident Details
          </p>

          <div className="flex-row align-row-left more-gap" style={{ marginTop: -15 }}>
            <div
              className="flex-column align-column-left width-fit-content"
              style={{ alignItems: "flex-start", padding: 0 }}
            >
              <p className="small boldText removemargin">Full Name</p>
              <p className="small  removemargin">
                {firstName} {lastName}
              </p>
            </div>
            <div className="flex-column align-column-left width-fit-content">
              <p className="small boldText removemargin">Email Address</p>
              <p className="small  removemargin">{email}</p>
            </div>
            <div className="flex-column align-column-left width-fit-content">
              <p className="small boldText removemargin">Phone Number</p>
              <p className="small  removemargin">{mobile}</p>
            </div>
          </div>

          <p className="small removemargin boldText" style={{ fontSize: 14 }}>
            Secondary Resident{" "}
            <span style={{ color: "#0D6A99" }}>(Maximum of 4 secondary residents)</span>
          </p>

          {secondaryResidentsComps}
        </div>

        <div className="flex-row" style={{ marginLeft: 40 }}>
          <p className="redText xsmall removemargin alignTextLeft">
            {inputAlert.secondaryResidents}
          </p>
        </div>

        <div className="flex-row align-row-left" style={{ paddingLeft: 22 }}>
          {secondaryResidentsData?.length < 4 && (
            <button
              onClick={() => {
                if (
                  !residentType ||
                  !firstName ||
                  !lastName ||
                  !fullAddress ||
                  !mobile ||
                  !email ||
                  !unitsOccupied ||
                  unitsOccupied < 0 ||
                  !validateEmail(email) ||
                  !validateMobile(mobile)
                )
                  return popup("Please complete resident details above");
                setOnView("secondary");
                secondaryResidentsData.push({
                  full_name: "",
                  email: "",
                  mobile: "",
                });
                setSecondaryResidentsComps([
                  ...secondaryResidentsComps,
                  <SecondaryResidentsCard
                    key={secondaryResidentsData.length - 1}
                    index={secondaryResidentsData.length - 1}
                    setFalseState={setFalseState}
                  />,
                ]);
              }}
              className="themeBtn whiteBg blackText no-border"
            >
              + Add Secondary Resident
            </button>
          )}
        </div>
        <div className="flex-row" style={{ paddingBottom: 20 }}>
          <button onClick={addUser} className="themeBtn a-a-u-btn">
            Create Resident
          </button>
        </div>
      </div>
    </div>
  );
};

export { AddAUserModal, secondaryResidentsData };
