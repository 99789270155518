import React, { useEffect, useRef, useState } from "react";
import "./SelectInput.css";
import { AiOutlineCaretDown } from "react-icons/ai";

interface CustomDropdownProps {
  options: any[];
  placeholder?: string;
  label?: string;
  name: string;
  value?: string;
  onChange?: (e: ITarget) => void;
  keyToRender?: string;
  keyValue?: string;
}

type ITarget = {
  target: {
    name: string;
    value: string;
  };
};

const SelectInput = ({
  options,
  name,
  onChange,
  value,
  keyToRender,
  keyValue,
  label,
  placeholder,
}: CustomDropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<any | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleSelect = (option: any) => {
    onChange && onChange({ target: { name, value: option } });
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="slp-custom-select" ref={dropdownRef}>
      <div className="slp-select-box" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        {selectedOption ? value : label || placeholder || "Select an option"}
        <AiOutlineCaretDown />
      </div>
      {isDropdownOpen && (
        <div className="slp-option-list">
          {options.map((option, index) =>
            typeof option === "string" ? (
              <div key={index} className="slp-option" onClick={() => handleSelect(option)}>
                {option}
              </div>
            ) : (
              <div
                key={index}
                className="slp-option"
                onClick={() => {
                  const firstKey = Object.keys(option)[0];
                  const val = keyValue
                    ? option[keyValue]
                    : keyToRender
                    ? option[keyToRender]
                    : option[firstKey];
                  handleSelect(val);
                }}
              >
                {keyToRender
                  ? option[keyToRender]
                  : keyValue
                  ? option[keyValue]
                  : option[Object.keys(option)[0]]}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SelectInput;
