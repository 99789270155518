import BroadcastTablePopup from "./popup/popup";

const columns = [
  {
    id: "popup",
    label: "",
    minWidth: 30,
    align: "left",
  },
  { id: "title", label: "Title", minWidth: 170 },
  { id: "_id", label: "Message ID", minWidth: 100 },
  { id: "recipients", label: "Recipient", minWidth: 130 },
  {
    id: "text",
    label: "Message Body",
    minWidth: 170,
    align: "left",
  },
  {
    id: "createdAt",
    label: "Date",
    minWidth: 120,
    align: "left",
  },
];

function sortData(data, openPopup) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      popup: <BroadcastTablePopup text={e?.text} open={openPopup} />,
      title: e?.title,
      _id: e?._id,
      recipients: e?.recipients,
      text: (
        <span data={e.text}>
          {e?.text.length > 50
            ? e?.text?.split("<br/>").join("\n")?.slice(0, 50) + "..."
            : e?.text.split("<br/>").join("\n")}
        </span>
      ),
      createdAt: (
        <span>
          {new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e?.createdAt))}
          <br></br>
          <span className="greyText">
            {new Intl.DateTimeFormat("en-us", {
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(e?.createdAt))}
          </span>
        </span>
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
