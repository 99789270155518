import React, { useContext, useState, useEffect } from "react";
import "../../styles/index.css";
import "./transaction-history-modal.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { CgSortAz } from "react-icons/cg";
import {
  TransactionsTable,
  pseudoCheckBoxSelectedData,
} from "../transactions-table/transactions-table";

const TransactionsHistoryModal = function ({ display, setTransactionHistoryModal, data }) {
  const [filteredWalletData, setFilteredWalletData] = useState([]);
  const [oringinalData, setOriginalData] = useState([]);
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const { backendServer, setIsLoading } = useContext(AppContext);
  const [csvData, setcsvData] = useState([]);
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [theUser, setTheUser] = useState({});
  const [filename, setFilename] = useState("");

  const getTransactionData = async (loader) => {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#f-checkbox-parent"))
      document.querySelector("#f-checkbox-parent").checked = false;
    try {
      if (loader) setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/user/get?email=${data?.email}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setIsLoading(false);
      setTheUser(returned?.data);

      const returnedDues = await (
        await fetch(
          `${backendServer}/dues/get?allpopulateonetimepayersPlusAllpopulateontimemap=true`,
          {
            credentials: "include",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          }
        )
      ).json();

      const initialOneTimeDuePaymentData = [...returnedDues?.data].reverse();

      const allTransactionsRegardingTheEstate = [];

      initialOneTimeDuePaymentData.forEach((theFullDue) => {
        if (theFullDue?.bill_frequency === "One Time") {
          theFullDue.one_time_payers.forEach((e) => {
            allTransactionsRegardingTheEstate.push({
              name: e?.user?.first_name + ` ${e?.user?.last_name}`,
              initiated_on: e?.paymentRef?.initiated_on,
              bill_type: theFullDue?.bill_type,
              _id: e?._id,
              status: "completed",
              amount: e?.paymentRef?.amount,
              email: e?.user?.email,
            });
          });
        } else if (theFullDue?.mappedSubscriptions) {
          theFullDue?.mappedSubscriptions?.forEach((e) => {
            allTransactionsRegardingTheEstate.push({
              name: e?.customer?.first_name + ` ${e?.customer?.last_name}`,
              initiated_on: e?.most_recent_invoice?.created_at,
              bill_type: theFullDue?.bill_type,
              _id: e?.most_recent_invoice?.invoice_code,
              status: "completed",
              amount: theFullDue?.amount,
              email: e?.customer?.email,
            });
          });
        }
      });

      const allTransactionsForTheUser = allTransactionsRegardingTheEstate.filter(
        (e) => e?.email === returned?.data?.email
      );

      setFilteredWalletData(allTransactionsForTheUser);
      setOriginalData(allTransactionsForTheUser);

      setcsvData([
        ["Bill Type", "Transaction ID", "Status", "Date", "Amount"],
        ...allTransactionsForTheUser?.map(({ bill_type, _id, status, initiated_on, amount }) => {
          return [
            bill_type,
            _id,
            status,
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(initiated_on)),
            new Intl.NumberFormat("en-us", {
              currency: "NGN",
            }).format(amount),
          ];
        }),
      ]);
    } catch (err) {
      setIsLoading(false);
      setTransactionHistoryModal(false);
      popup("Error finding transaction data");
    }
  };

  useEffect(() => {
    if (!display) return;
    getTransactionData(true);
  }, [display]);

  async function runDateFilter() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#f-checkbox-parent"))
      document.querySelector("#f-checkbox-parent").checked = false;
    try {
      if (!fromDate || !toDate) return popup("Please set a date range");

      const startDate = new Date(fromDate);

      const endDate = new Date(toDate);

      const datesArray = [];

      // loop from start date to end date
      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        datesArray.push(new Date(date));
      }

      const dateRanges = datesArray.map((e) =>
        new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(e))
      );

      const newDateRangeData = oringinalData.filter((e) => {
        if (
          dateRanges.includes(
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(e.initiated_on))
          )
        )
          return e;
      });

      setFilteredWalletData(newDateRangeData);
      setShowFilter(false);
      setFilename(
        `${fromDate} to ${toDate} - ${theUser?.first_name} ${theUser?.last_name} Transactions`
      );
      setcsvData([
        ["Bill Type", "Transaction ID", "Status", "Date", "Amount"],
        ...newDateRangeData?.map(({ bill_type, _id, status, initiated_on, amount }) => {
          return [
            bill_type,
            _id,
            status,
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(initiated_on)),
            new Intl.NumberFormat("en-us", {
              currency: "NGN",
            }).format(amount),
          ];
        }),
      ]);
    } catch {
      setIsLoading(false);
      popup("Something went wrong");
    }
  }

  if (!display) return;

  return (
    <div className="modal-container">
      <div className="modal-body thm-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setTransactionHistoryModal(false);
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="thm-modal-cont flex-column align-column-left">
          <div className="flex-row align-row-left">
            <div style={{ width: "fit-content" }}>
              <img className="thm-img-big" src={data?.image.props.src} alt="Resident Big Image" />
            </div>
            <div>
              <div className="flex-row align-row-left">
                <div className="flex-column align-column-left width-fit-content">
                  <p className="small boldText removemargin">Full Name</p>
                  <p className="small  removemargin">{data?.name}</p>
                </div>
                <div className="flex-column align-column-left width-fit-content">
                  <p className="small boldText removemargin">Property Code</p>
                  <p className="small  removemargin">{theUser?.property_code || "-"}</p>
                </div>
                <div className="flex-column align-column-left width-fit-content">
                  <p className="small boldText removemargin">Unit Occupied</p>
                  <p className="small  removemargin">{theUser?.units_occupied || "-"}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="thm-table-container">
            <div
              className="flex-row"
              style={{
                marginBottom: 15,
              }}
            >
              <div className="flex-row thm-table-controls">
                <CgSortAz
                  onClick={() => setShowFilter(true)}
                  className="cursor"
                  title="Filter By Date"
                  size={30}
                />
                <div className={`filter-dropdown ${!showFilter && "hide"}`}>
                  <div className="flex-row align-row-left">
                    <p style={{ fontSize: "15px" }} className="blackText removemargin">
                      From:
                    </p>
                    <input type="date" onChange={(e) => setFromDate(e.target.value)} />
                  </div>
                  <div className="flex-row align-row-left">
                    <p style={{ fontSize: "15px" }} className="blackText removemargin">
                      To:
                    </p>
                    <input
                      type="date"
                      style={{ marginLeft: 18 }}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                  <div className="flex-row">
                    <button onClick={runDateFilter} className="themeBtn">
                      GO
                    </button>
                    <button
                      onClick={() => setShowFilter(false)}
                      className="themeBtn whiteBg themeBorder blackText"
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
                <CSVLink
                  className="cursor small csv-link"
                  filename={
                    filename || `${theUser?.first_name} ${theUser?.last_name}'s Transactions`
                  }
                  data={csvData}
                >
                  <BsPrinter title="Print CSV" size={20} />
                </CSVLink>
                <FiRefreshCcw
                  className="cursor"
                  title="Refresh Data"
                  size={20}
                  onClick={() => getTransactionData(true)}
                />
              </div>
            </div>
            <TransactionsTable
              setCheckBoxSelectedData={setCheckBoxSelectedData}
              checkBoxSelectedData={checkBoxSelectedData}
              setcsvData={setcsvData}
              data={filteredWalletData}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsHistoryModal;
