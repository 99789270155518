import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { sortData, columns } from "./data";
import "./compliants-table.css";
let pseudoCheckBoxSelectedData = [];

function CompliantsTable({
  data,
  limit,
  showPageNumber,
  showOptions,
  setCompliantsModal,
  page,
  setPage,
  setCheckBoxSelectedData,
  setcsvData,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(limit);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function makeChecboxComeAlive(event) {
    if (pseudoCheckBoxSelectedData.find((e) => e._id === event?.target?.getAttribute("data"))) {
      pseudoCheckBoxSelectedData = pseudoCheckBoxSelectedData.filter(
        (e) => e._id !== event?.target?.getAttribute("data")
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      setcsvData([
        [
          "Name",
          "Compliant Type",
          "Address",
          "Description",
          "Status",
          "Severity",
          "User ID",
          "Date Created",
        ],
        ...pseudoCheckBoxSelectedData?.map(
          ({
            first_name,
            last_name,
            compliant_type,
            address,
            description,
            status,
            severity,
            userID,
            createdAt,
          }) => {
            return [
              first_name + ` ${last_name}`,
              compliant_type,
              address,
              description,
              status ? "Resolved" : "Un-resolved",
              severity,
              userID?._id,
              new Date(createdAt),
            ];
          }
        ),
      ]);
    } else {
      pseudoCheckBoxSelectedData.push(
        data?.find((e) => e?._id === event?.target?.getAttribute("data"))
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      setcsvData([
        [
          "Name",
          "Compliant Type",
          "Address",
          "Description",
          "Status",
          "Severity",
          "User ID",
          "Date Created",
        ],
        ...pseudoCheckBoxSelectedData?.map(
          ({
            first_name,
            last_name,
            compliant_type,
            address,
            description,
            status,
            severity,
            userID,
            createdAt,
          }) => {
            return [
              first_name + ` ${last_name}`,
              compliant_type,
              address,
              description,
              status ? "Resolved" : "Un-resolved",
              severity,
              userID?._id,
              new Date(createdAt),
            ];
          }
        ),
      ]);
    }
  }

  useEffect(() => {
    document.querySelectorAll("#c-checkbox-children").forEach((element) => {
      if (pseudoCheckBoxSelectedData.find((e) => e._id === element?.getAttribute("data"))) {
        element.checked = true;
      } else element.checked = false;
    });
  });

  function allActionCTable(add) {
    if (add) {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#c-checkbox-children").forEach((element) => {
        pseudoCheckBoxSelectedData.push(
          data?.find((e) => e?._id === element?.getAttribute("data"))
        );
        element.checked = true;
      });

      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);

      setcsvData([
        [
          "Name",
          "Compliant Type",
          "Address",
          "Description",
          "Status",
          "Severity",
          "User ID",
          "Date Created",
        ],
        ...pseudoCheckBoxSelectedData?.map(
          ({
            first_name,
            last_name,
            compliant_type,
            address,
            description,
            status,
            severity,
            userID,
            createdAt,
          }) => {
            return [
              first_name + ` ${last_name}`,
              compliant_type,
              address,
              description,
              status ? "Resolved" : "Un-resolved",
              severity,
              userID?._id,
              new Date(createdAt),
            ];
          }
        ),
      ]);
    } else {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#c-checkbox-children").forEach((element) => {
        element.checked = false;
      });
      setcsvData([
        [
          "Name",
          "Compliant Type",
          "Address",
          "Description",
          "Status",
          "Severity",
          "User ID",
          "Date Created",
        ],
        ...data?.map(
          ({
            first_name,
            last_name,
            compliant_type,
            address,
            description,
            status,
            severity,
            userID,
            createdAt,
          }) => {
            return [
              first_name + ` ${last_name}`,
              compliant_type,
              address,
              description,
              status ? "Resolved" : "Un-resolved",
              severity,
              userID?._id,
              new Date(createdAt),
            ];
          }
        ),
      ]);
    }
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "fit-content" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  onClick={(e) => {
                    if (!setCheckBoxSelectedData) return;
                    if (e.target.className.includes("c-table-checkboxes-head")) {
                      if (!e.target.checked) allActionCTable(false);
                      else allActionCTable(true);
                    }
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortData(data, showOptions, setCompliantsModal)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          onClick={(event) => {
                            if (!setCheckBoxSelectedData) return;
                            if (event?.target?.className?.includes("c-table-checkboxes")) {
                              event.stopPropagation();
                              return makeChecboxComeAlive(event);
                            }
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {showPageNumber && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={sortData(data).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}

export { CompliantsTable, pseudoCheckBoxSelectedData };
