import { useState, useEffect, useContext } from "react";
import "./EditEstateInfo.css";
import SecondSuccess from "../../../components/popup/second-success/second-success";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { MDBSpinner } from "mdb-react-ui-kit";

type Props = {
  onNavigate?: (screen: number) => void;
};

const EditEstateInfo = ({ onNavigate }: Props) => {
  const [disableBtn, setDisbleBtn] = useState<boolean>(true);
  const [newLogo, setNewLogo] = useState<string | undefined>(undefined);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [state, setState] = useState<any | {}>({
    email: "",
    mobile: "",
  });

  const {
    estate,
    getSignedAwsUrl,
    updateUsersData,
    backendServer,
    setIsLoading,
    validateEmail,
    validateMobile,
  } = useContext(AppContext);

  useEffect(() => {
    (async function () {
      setNewLogo(estate?.logo ? await getSignedAwsUrl(estate?.logo, "others") : "/logo.png");
    })();
  }, [estate]);

  useEffect(() => {
    if (
      (state?.email && validateEmail(state?.email)) ||
      (state?.mobile && validateMobile(state?.mobile))
    )
      setDisbleBtn(false);
    else setDisbleBtn(true);
  });

  const sendUpdates: () => void = async () => {
    try {
      setIsLoading(true);

      const obj: any | {} = {};

      if (state?.email && validateEmail(state?.email)) obj.email = state?.email;
      if (state?.mobile && validateMobile(state?.mobile)) obj.mobile = state?.mobile;

      const returned = await (
        await fetch(`${backendServer}/estate/update/details`, {
          method: "PATCH",
          headers: {
            token: localStorage.getItem("emstoken") || "",
            "content-type": "application/json",
          },
          body: JSON.stringify(obj),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned?.message);

      setIsLoading(false);
      setOpenSuccessModal(true);
      updateUsersData(true);
      setState({
        email: "",
        mobile: "",
      });
    } catch (err) {
      setIsLoading(false);
      popup("Something went wrong");
    }
  };

  return (
    <div className="edit-estate-info-container">
      <div className="estate-info-first-child">
        <div className="estate-info-first-child-logo">
          {newLogo ? (
            <img alt="Estate Logo" src={newLogo || "/user_placeholder.png"} />
          ) : (
            <MDBSpinner size="sm" className="mx-2">
              <span className="visually-hidden"></span>
            </MDBSpinner>
          )}
        </div>
        <div className="edit-estate-info-change-logo">
          <span>Change Estate logo</span>
          <input
            type="file"
            title=""
            onChange={async ({ target: { files } }) => {
              if (files !== null) {
                setIsLoading(true);
                const file = files[0];
                const form = new FormData();
                form.append("logo", file);
                try {
                  await fetch(`${backendServer}/estate/update/logo`, {
                    method: "PATCH",
                    headers: {
                      token: localStorage.getItem("emstoken") || "",
                      enctype: "multipart/form-data",
                    },
                    body: form,
                  });

                  updateUsersData(true);
                  setIsLoading(false);
                } catch {
                  popup("Error Updating Estate Logo :(");
                  setIsLoading(false);
                }
              }
            }}
          />
        </div>
      </div>
      <div className="edit-estate-info-form">
        <div className="edit-estate-info-form-group">
          <input
            placeholder={estate?.estate_name || "Estate Name"}
            className="in ems-settings-in-edit"
            disabled={true}
            style={{ height: 55, fontSize: "medium" }}
          />

          <input
            placeholder={estate?.estate_type || "Estate Type"}
            className="in ems-settings-in-edit"
            disabled={true}
            style={{ height: 55, fontSize: "medium" }}
          />
        </div>
        <div className="edit-estate-info-form-group">
          <input
            placeholder={estate?.email || "Estate Email"}
            className="in ems-settings-in-edit"
            style={{ height: 55, fontSize: "medium" }}
            value={state?.email}
            type="text"
            onBlur={(e) => {
              if (!validateEmail(e.target.value)) popup("That email is invalid");
            }}
            onChange={(e) =>
              setState((p: any) => {
                return { ...p, email: e.target.value };
              })
            }
          />

          <input
            placeholder={estate?.mobile || "Estate Mobile"}
            className="in ems-settings-in-edit"
            style={{ height: 55, fontSize: "medium" }}
            type="text"
            value={state?.mobile}
            onBlur={(e) => {
              if (!validateMobile(e.target.value)) popup("That mobile number is invalid");
            }}
            onChange={(e) =>
              setState((p: any) => {
                return { ...p, mobile: e.target.value };
              })
            }
          />
        </div>
        <input
          placeholder={estate?.address?.addressString || "Estate Full Address"}
          className="in ems-settings-in-edit"
          style={{ height: 55, fontSize: "medium", width: "100%" }}
          disabled={true}
        />

        <div className="edit-estate-info-form-group">
          <input
            placeholder={estate?.address?.state || "Estate State"}
            className="in ems-settings-in-edit"
            style={{ height: 55, fontSize: "medium" }}
            disabled={true}
          />
          <input
            placeholder={estate?.address?.country || "Estate Country"}
            className="in ems-settings-in-edit"
            style={{ height: 55, fontSize: "medium" }}
            value={state?.address?.country}
            disabled={true}
          />
        </div>
        <div className="edit-estate-info-form-action-f-right">
          <span className="edit-estate-info-cancel-btn" onClick={() => onNavigate && onNavigate(0)}>
            Cancel
          </span>
          <button className="themeBtn" disabled={disableBtn} onClick={sendUpdates}>
            Save Changes
          </button>
        </div>
      </div>
      {openSuccessModal && (
        <SecondSuccess
          title={"Changes Saved"}
          text={"Changes to your estate profile has been saved successfully"}
          isModalOpen={openSuccessModal}
          handleModalOpen={() => setOpenSuccessModal(false)}
          onBtnClick={() => setOpenSuccessModal(false)}
          buttonText={"Close"}
        />
      )}
    </div>
  );
};

export default EditEstateInfo;
