import React, { useState, useContext } from "react";
import "../../styles/index.css";
import "./broadcasts.css";
import AppContext from "../../context/app-context";
import BroadCastHome from "./broadcastHome/BroadCastHome";
import BroadcastForm from "./broadcastForm/BroadcastForm";

const Broadcasts = function ({ display }) {
  const { backendServer } = useContext(AppContext);

  const [active, setActive] = useState(0);

  const screen = [
    <BroadCastHome display={true} onNavigate={(s) => setActive(s)} />,
    <BroadcastForm onNavigate={(s) => setActive(s)} />,
  ];

  if (!display) return;

  return <div className="screen-container">{screen[active]}</div>;
};

export default Broadcasts;
