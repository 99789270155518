import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./add-a-property-modal.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const AddAPropertyModal = function ({ display, setAddAPropertyModal }) {
  const [whatsWrong, setWhatsWrong] = useState("");

  const { backendServer, setIsLoading, validateEmail, updateUsersData, validateMobile } =
    useContext(AppContext);
  const [disableBtn, setdisableBtn] = useState(true);
  const [addSecondaryOption, setAddSecondaryOption] = useState(false);
  const [propertyType, setPropertyType] = useState("");
  const [occupied, setOccupied] = useState(false);
  const [numberOfUnits, setNumberOfUnits] = useState("");
  const [houseNumber, sethouseNumber] = useState("");
  const [streetName, setstreetName] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [primaryContact, setPrimaryContact] = useState({
    name: "",
    mobile: "",
    email: "",
  });
  const [secondaryContact, setSecondaryContact] = useState({
    name: "",
    mobile: "",
    email: "",
  });

  function runValidation() {
    if (!propertyType) return setWhatsWrong("Property Type is requried");
    if (!numberOfUnits || numberOfUnits < 0)
      return setWhatsWrong("Number of units missing/invalid");
    if (!houseNumber) return setWhatsWrong("House number is required");
    if (!streetName) return setWhatsWrong("Street name is required");
    if (!city) return setWhatsWrong("City is required");
    if (!state) return setWhatsWrong("Property State name is required");

    if (!primaryContact.name || !primaryContact.email || !primaryContact.mobile)
      return setWhatsWrong("Please complete Primary Contact details");

    if (!validateEmail(primaryContact.email))
      return setWhatsWrong("Primary Contact Email is invalid");
    if (!validateMobile(primaryContact.mobile))
      return setWhatsWrong("Primary Contact Mobile Number is invalid");

    if (
      addSecondaryOption &&
      (!secondaryContact.name || !secondaryContact.email || !secondaryContact.mobile)
    )
      return setWhatsWrong("Please complete Secondary Contact details to proceed");

    if (addSecondaryOption && !validateEmail(secondaryContact.email))
      return setWhatsWrong("You have an invalid Secondary Contact Email");

    if (addSecondaryOption && !validateMobile(secondaryContact.mobile))
      return setWhatsWrong("You have an invalid Secondary Contact Mobile Number");

    setWhatsWrong("");
  }

  useEffect(() => {
    runValidation();

    if (
      !propertyType ||
      !numberOfUnits ||
      numberOfUnits < 0 ||
      !houseNumber ||
      !streetName ||
      !city ||
      !state ||
      !primaryContact.name ||
      !primaryContact.email ||
      !primaryContact.mobile ||
      !validateEmail(primaryContact.email) ||
      !validateMobile(primaryContact.mobile)
    )
      setdisableBtn(true);
    else setdisableBtn(false);

    if (
      addSecondaryOption &&
      (!secondaryContact.name ||
        !secondaryContact.email ||
        !secondaryContact.mobile ||
        !validateMobile(secondaryContact.mobile) ||
        !validateEmail(secondaryContact.email))
    )
      setdisableBtn(true);
  });

  async function addProperty() {
    try {
      setIsLoading(true);
      const contactArray = [];

      contactArray.push({
        full_name: primaryContact.name,
        phone: primaryContact.mobile,
        email: primaryContact.email,
        contact_type: "Primary",
      });

      if (addSecondaryOption)
        contactArray.push({
          full_name: secondaryContact.name,
          phone: secondaryContact.mobile,
          email: secondaryContact.email,
          contact_type: "Secondary",
        });

      const returned = await (
        await fetch(`${backendServer}/property/create`, {
          method: "POST",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify({
            property_type: propertyType,
            number_of_units: numberOfUnits,
            full_address: {
              house_number: houseNumber,
              street_name: streetName,
              city: city,
              state: state,
            },
            occupied: occupied,
            contacts: contactArray,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Property Added");
      setAddAPropertyModal({
        display: false,
        data: {},
      });
      setIsLoading(false);
      setPropertyType("");
      setOccupied(false);
      setNumberOfUnits(0);
      sethouseNumber("");
      setstreetName("");
      setcity("");
      setstate("");
      setPrimaryContact({
        name: "",
        mobile: "",
        email: "",
      });
      setSecondaryContact({
        name: "",
        mobile: "",
        email: "",
      });
      updateUsersData();
    } catch (err) {
      setIsLoading(false);
      popup("Error creating property, try again later");
    }
  }

  if (!display) return;

  return (
    <div className="modal-container">
      <div className="modal-body edit-a-property-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setAddAPropertyModal({
                display: false,
                data: {},
              });
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="a-property-modal-cont flex-column align-column-left">
          <div className="flex-row align-row-left">
            <p className="big">Input the new Property Details Below</p>
          </div>

          <div className="flex-row">
            <MDBDropdown id="mdb-dropdown-custom-dropdown">
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {propertyType || "Property Type"}
              </MDBDropdownToggle>

              <MDBDropdownMenu id="mdb-menu-custom-dropdown">
                <MDBDropdownItem
                  link
                  href="#Properties"
                  onClick={(e) => {
                    setPropertyType(e.target.textContent);
                  }}
                >
                  Bungalow
                </MDBDropdownItem>

                <MDBDropdownItem
                  link
                  href="#Properties"
                  onClick={(e) => {
                    setPropertyType(e.target.textContent);
                  }}
                >
                  Studio Apartment
                </MDBDropdownItem>

                <MDBDropdownItem
                  link
                  href="#Properties"
                  onClick={(e) => {
                    setPropertyType(e.target.textContent);
                  }}
                >
                  Service Apartment
                </MDBDropdownItem>

                <MDBDropdownItem
                  link
                  href="#Properties"
                  onClick={(e) => {
                    setPropertyType(e.target.textContent);
                  }}
                >
                  Block of Flats
                </MDBDropdownItem>

                <MDBDropdownItem
                  link
                  href="#Properties"
                  onClick={(e) => {
                    setPropertyType(e.target.textContent);
                  }}
                >
                  Mansion
                </MDBDropdownItem>

                <MDBDropdownItem
                  link
                  href="#Properties"
                  onClick={(e) => {
                    setPropertyType(e.target.textContent);
                  }}
                >
                  Other
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
            <input
              className="in a-p-m-inputs"
              placeholder="Number of Units"
              type="number"
              onChange={(e) => setNumberOfUnits(e.target.value)}
              min="0"
              value={numberOfUnits}
            />
          </div>

          <div className="flex-row">
            <input
              className="in a-p-m-inputs"
              placeholder="House Number"
              type="text"
              value={houseNumber}
              onChange={(e) => sethouseNumber(e.target.value)}
            />
            <input
              className="in a-p-m-inputs"
              placeholder="Street Name"
              type="text"
              maxLength="100"
              value={streetName}
              onChange={(e) => setstreetName(e.target.value)}
            />
          </div>

          <div className="flex-row">
            <input
              className="in a-p-m-inputs"
              placeholder="City"
              type="text"
              maxLength="100"
              value={city}
              onChange={(e) => setcity(e.target.value)}
            />
            <input
              className="in a-p-m-inputs"
              placeholder="State"
              type="text"
              maxLength="100"
              value={state}
              onChange={(e) => setstate(e.target.value)}
            />
          </div>

          <div className="flex-column" style={{ gap: 5, alignItems: "flex-start" }}>
            <p className="removemargin small" style={{ paddingLeft: 22 }}>
              Property Status
            </p>
            <div className="flex-row">
              <div className="radio-input-box" onClick={() => setOccupied(true)}>
                <input title="State" type="radio" checked={occupied} onChange={() => {}} />
                <p className="small">Occupied</p>
              </div>
              <div className="radio-input-box" onClick={() => setOccupied(false)}>
                <input
                  title="State"
                  type="radio"
                  checked={!occupied ? true : false}
                  onChange={() => {}}
                />
                <p className="small">Vacant</p>
              </div>
            </div>
          </div>

          <div className="flex-column" style={{ gap: 5, alignItems: "flex-start" }}>
            <p className="removemargin small" style={{ paddingLeft: 22 }}>
              Primary Contact
            </p>
            <div className="flex-row">
              <input
                className="in a-p-m-inputs"
                placeholder="Name"
                type="text"
                maxLength="100"
                value={primaryContact.name}
                onChange={(e) => setPrimaryContact({ ...primaryContact, name: e.target.value })}
              />
              <input
                className="in a-p-m-inputs"
                placeholder="Mobile Number"
                type="text"
                maxLength="100"
                value={primaryContact.mobile}
                onChange={(e) => setPrimaryContact({ ...primaryContact, mobile: e.target.value })}
              />
            </div>
            <div className="flex-row">
              <input
                className="in a-p-m-inputs"
                placeholder="Email"
                type="text"
                maxLength="100"
                value={primaryContact.email}
                onChange={(e) => setPrimaryContact({ ...primaryContact, email: e.target.value })}
              />
              <input
                className="in a-p-m-inputs"
                placeholder=""
                type="Fake INPUT DO NOT EDIT"
                maxLength="100"
                style={{ opacity: 0 }}
              />
            </div>
          </div>

          {addSecondaryOption && (
            <div className="flex-column" style={{ gap: 5, alignItems: "flex-start" }}>
              <p className="removemargin small" style={{ paddingLeft: 22 }}>
                Secondary Contact
              </p>
              <div className="flex-row">
                <input
                  className="in a-p-m-inputs"
                  placeholder="Name"
                  type="text"
                  maxLength="100"
                  value={secondaryContact.name}
                  onChange={(e) =>
                    setSecondaryContact({ ...secondaryContact, name: e.target.value })
                  }
                />
                <input
                  className="in a-p-m-inputs"
                  placeholder="Mobile Number"
                  type="text"
                  maxLength="100"
                  value={secondaryContact.mobile}
                  onChange={(e) =>
                    setSecondaryContact({ ...secondaryContact, mobile: e.target.value })
                  }
                />
              </div>
              <div className="flex-row">
                <input
                  className="in a-p-m-inputs"
                  placeholder="Email"
                  type="text"
                  maxLength="100"
                  value={secondaryContact.email}
                  onChange={(e) =>
                    setSecondaryContact({ ...secondaryContact, email: e.target.value })
                  }
                />
                <input
                  className="in a-p-m-inputs"
                  placeholder=""
                  type="Fake INPUT DO NOT EDIT"
                  maxLength="100"
                  style={{ opacity: 0 }}
                />
              </div>
            </div>
          )}

          <div className="flex-row align-row-left" style={{ paddingLeft: 22 }}>
            <button
              onClick={() => setAddSecondaryOption(!addSecondaryOption)}
              className="themeBtn whiteBg themeBorder blackText"
            >
              {!addSecondaryOption ? "+ Add Secondary Contact" : "- Remove Secondary Contact"}
            </button>
          </div>

          <button onClick={addProperty} className="themeBtn a-p-m-btn" disabled={disableBtn}>
            Create Property
          </button>
          <p style={{ marginTop: -15 }} className="small redText removemargin">
            {whatsWrong}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddAPropertyModal;
