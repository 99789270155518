import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./compliants.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import {
  CompliantsTable,
  pseudoCheckBoxSelectedData,
} from "../../components/compliants-table/compliants-table";
import { CgSortAz } from "react-icons/cg";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import CompliantsModal from "../../components/compliants-modal/compliants-modal";

const Compliants = function ({ display }) {
  const [page, setPage] = useState(0);
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const [compliants, setCompliants] = useState(null);
  const [sortBy, setSortBy] = useState(true);
  const [compliantsModal, setCompliantsModal] = useState({
    display: false,
    data: {},
  });
  const { backendServer, usersData, setIsLoading, estate } = useContext(AppContext);

  useEffect(() => {
    getData();
  }, [usersData]);

  async function getData() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#c-checkbox-parent"))
      document.querySelector("#c-checkbox-parent").checked = false;
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/compliants/get`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const theReversed = [...returned?.data];
      theReversed.reverse();
      setCompliants(theReversed);
      setIsLoading(false);

      setcsvData([
        [
          "Name",
          "Compliant Type",
          "Address",
          "Description",
          "Status",
          "Severity",
          "User ID",
          "Date Created",
        ],
        ...theReversed?.map(
          ({
            first_name,
            last_name,
            compliant_type,
            address,
            description,
            status,
            severity,
            userID,
            createdAt,
          }) => {
            return [
              first_name + ` ${last_name}`,
              compliant_type,
              address,
              description,
              status ? "Resolved" : "Un-resolved",
              severity,
              userID?._id,
              new Date(createdAt),
            ];
          }
        ),
      ]);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      popup("Error fetching Estate Complaints");
    }
  }

  function sort() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#c-checkbox-parent"))
      document.querySelector("#c-checkbox-parent").checked = false;
    if (sortBy) {
      const sortedArray = [];
      sortedArray.push(...[...compliants].filter((e) => e.severity === "high"));
      sortedArray.push(...[...compliants].filter((e) => e.severity === "medium"));
      sortedArray.push(...[...compliants].filter((e) => e.severity === "low"));
      setCompliants([...sortedArray]);
      setcsvData([
        [
          "Name",
          "Compliant Type",
          "Address",
          "Description",
          "Status",
          "Severity",
          "User ID",
          "Date Created",
        ],
        ...sortedArray?.map(
          ({
            first_name,
            last_name,
            compliant_type,
            address,
            description,
            status,
            severity,
            userID,
            createdAt,
          }) => {
            return [
              first_name + ` ${last_name}`,
              compliant_type,
              address,
              description,
              status ? "Resolved" : "Un-resolved",
              severity,
              userID?._id,
              new Date(createdAt),
            ];
          }
        ),
      ]);
    } else {
      const sortedArray = [];
      sortedArray.push(...[...compliants].filter((e) => e.severity === "low"));
      sortedArray.push(...[...compliants].filter((e) => e.severity === "medium"));
      sortedArray.push(...[...compliants].filter((e) => e.severity === "high"));
      setCompliants([...sortedArray]);
      setcsvData([
        [
          "Name",
          "Compliant Type",
          "Address",
          "Description",
          "Status",
          "Severity",
          "User ID",
          "Date Created",
        ],
        ...sortedArray?.map(
          ({
            first_name,
            last_name,
            compliant_type,
            address,
            description,
            status,
            severity,
            userID,
            createdAt,
          }) => {
            return [
              first_name + ` ${last_name}`,
              compliant_type,
              address,
              description,
              status ? "Resolved" : "Un-resolved",
              severity,
              userID?._id,
              new Date(createdAt),
            ];
          }
        ),
      ]);
    }
    setSortBy(!sortBy);
  }

  if (!display) return;

  return (
    <div className="screen-container">
      <div className="compliants-stat-container flex-row">
        <div className="each-c-stat-container">
          <img src="/d-2.png" alt="estility-complaints" />
          <p className="small greyText removemargin">
            Total Complaints<br></br>
            <span className="big c-stat-text">{compliants?.length || "-"}</span>
          </p>
        </div>

        <div className="each-c-stat-container">
          <img src="/d-2.png" alt="estility-complaints" />
          <p className="small greyText removemargin">
            Resolved Complaints<br></br>
            <span className="big c-stat-text">
              {compliants?.filter((e) => e.status === true)?.length || "-"}
            </span>
          </p>
        </div>

        <div className="each-c-stat-container">
          <img src="/d-2.png" alt="estility-complaints" />
          <p className="small greyText removemargin">
            Unresolved Complaints<br></br>
            <span className="big c-stat-text">
              {compliants?.filter((e) => e.status === false)?.length || "-"}
            </span>
          </p>
        </div>
      </div>
      <div className="c-table-container">
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <p className="big removemargin">All Complaints</p>
          <div className="flex-row" style={{ width: "fit-content", gap: 10 }}>
            <CgSortAz onClick={sort} className="cursor" title="Sort By Severity" size={30} />
            <CSVLink
              className="cursor small csv-link"
              filename={`${estate?.estate_name} Compliants Data`}
              data={csvData}
            >
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>

            <FiRefreshCcw className="cursor" title="Refresh Data" size={20} onClick={getData} />
          </div>
        </div>
        <CompliantsTable
          setCompliantsModal={setCompliantsModal}
          data={compliants}
          limit={10}
          showPageNumber={true}
          showOptions={true}
          page={page}
          setPage={setPage}
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          checkBoxSelectedData={checkBoxSelectedData}
          setcsvData={setcsvData}
        />
      </div>

      <CompliantsModal
        display={compliantsModal?.display}
        compliant={compliantsModal?.data}
        setCompliantsModal={setCompliantsModal}
        getData={getData}
      />
    </div>
  );
};

export default Compliants;
