import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../visitors.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { CgSortAz } from "react-icons/cg";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import {
  VPassTable,
  pseudoCheckBoxSelectedData,
} from "../../../components/v-pass-table/v-pass-table";
import SearchInput from "../../../components/searchInput/SearchInput";
import VisitorsStatusFilter from "./visitors-status-filter";

const VisitorsHome = function ({ display, setScreen }) {
  const [tableFilterStatus, setTableFIlterStatus] = useState({
    from: "",
    to: "",
    requestStatus: "",
  });

  const [showTableFilter, setShowTableFilter] = useState(false);

  const [page, setPage] = useState(0);
  const [originalData, setOriginalData] = useState([]);
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const [allPasses, setAllPasses] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const [sortBy, setSortBy] = useState(true);
  const { backendServer, setIsLoading, patternMatching, estate } = useContext(AppContext);

  function setCSVFunction(data) {
    setcsvData([
      [
        "Vistor Name",
        "Resident Name",
        "Property Code",
        "Type",
        "Status",
        "Date",
        "Number of Guests",
        "Time In",
        "Time Out",
      ],
      ...data?.map(
        ({
          visitor_details,
          residentID,
          createdAt,
          active,
          number_of_persons,
          property_code,
          pass_type,
          arrival_date,
          departure_date,
        }) => {
          return [
            visitor_details?.first_name + ` ${visitor_details?.last_name}`,
            residentID?.first_name + ` ${residentID?.last_name}`,
            property_code || "none",
            pass_type || "Visitor",
            active ? "Pending" : "Expired",
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(createdAt)),
            number_of_persons || "-",
            arrival_date
              ? new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(arrival_date))
              : "-",
            departure_date
              ? new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(departure_date))
              : "-",
          ];
        }
      ),
    ]);
  }

  const getData = async (loader) => {
    try {
      if (loader) setIsLoading(true);
      setPage(0);
      pseudoCheckBoxSelectedData.length = 0;
      if (document.querySelector("#visitors-checkbox-parent"))
        document.querySelector("#visitors-checkbox-parent").checked = false;
      const returned = await (
        await fetch(`${backendServer}/visitors-pass/get`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setIsLoading(false);
      const reversed = [...returned?.data].reverse();
      setAllPasses(reversed);
      setOriginalData(reversed);

      setCSVFunction(reversed);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching Residents...");
    }
  };

  useEffect(() => {
    if (!display) return;
    getData(true);
  }, [display]);

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#visitors-checkbox-parent"))
      document.querySelector("#visitors-checkbox-parent").checked = false;
    const query = e.target.value;

    if (!query) return getData();

    const foundVisitors = [...originalData]?.filter((e) => {
      return (
        patternMatching(query, e.visitor_details.first_name + ` ${e.visitor_details.last_name}`) ||
        patternMatching(query?.split(" ")[0], e.visitor_details.first_name) ||
        patternMatching(query?.split(" ")[0], e.visitor_details.last_name) ||
        patternMatching(query?.split(" ")[1], e.visitor_details.first_name) ||
        patternMatching(query?.split(" ")[1], e.visitor_details.last_name)
      );
    });

    setAllPasses(foundVisitors);

    setCSVFunction(foundVisitors);
  }

  function runTableFilter() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#visitors-checkbox-parent"))
      document.querySelector("#visitors-checkbox-parent").checked = false;

    let sortedArray = [...originalData];

    if (tableFilterStatus.from && tableFilterStatus.to) {
      const startDate = new Date(tableFilterStatus.from);
      const endDate = new Date(tableFilterStatus.to);

      const datesArray = [];

      // loop from start date to end date
      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        datesArray.push(new Date(date));
      }

      const dateRanges = datesArray.map((e) =>
        new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(e))
      );

      sortedArray = sortedArray.filter((e) => {
        if (
          dateRanges.includes(
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(e.createdAt))
          )
        )
          return e;
      });
    }

    if (tableFilterStatus?.requestStatus === "Pending") {
      sortedArray = sortedArray.filter((e) => e?.active && !e?.arrival_date && !e.departure_date);
    }
    if (tableFilterStatus?.requestStatus === "Approved") {
      sortedArray = sortedArray.filter((e) => e?.arrival_date && !e.departure_date);
    }
    if (tableFilterStatus?.requestStatus === "Departed") {
      sortedArray = sortedArray.filter((e) => e?.arrival_date && e?.departure_date);
    }
    if (tableFilterStatus?.requestStatus === "Declined") {
      sortedArray = sortedArray.filter((e) => e?.total_declined_status);
    }

    setAllPasses([...sortedArray]);

    setCSVFunction(sortedArray);

    setShowTableFilter(false);
  }

  if (!display) return;

  return (
    <div className="">
      <div className="visitorspass-stat-container flex-row">
        <div className="v-each-d-stat-container">
          <img src="/d-2.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Total Visitors<br></br>
            <span className="big v-stat-text">{originalData.length || "-"}</span>
          </p>
        </div>

        <div className="v-each-d-stat-container">
          <img src="/d-2.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Expected Visitors<br></br>
            <span className="big v-stat-text">
              {originalData.filter((e) => e?.active && !e?.arrival_date && !e.departure_date)
                .length || "-"}
            </span>
          </p>
        </div>

        <div className="v-each-d-stat-container">
          <img src="/d-2.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Current Visitors<br></br>
            <span className="big v-stat-text">
              {originalData.filter((e) => e?.arrival_date && !e.departure_date).length || "-"}
            </span>
          </p>
        </div>

        <div className="v-each-d-stat-container">
          <img src="/d-2.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Delivery Riders<br></br>
            <span className="big v-stat-text">
              {originalData.filter((e) => e?.pass_type === "Rider").length || "-"}
            </span>
          </p>
        </div>
      </div>
      <div className="p-table-container">
        <div
          style={{
            marginBottom: 15,
            width: "100%",
            gap: "1rem",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SearchInput placeholder="search for a visitor" onChange={(e) => searchPlease(e)} />
          <CgSortAz
            onClick={setShowTableFilter}
            className="cursor"
            title="Sort By Status"
            size={30}
          />
          <CSVLink
            className="cursor small csv-link"
            filename={`${estate?.estate_name} Visitors Pass Data`}
            data={csvData}
          >
            <BsPrinter title="Print CSV" size={20} />
          </CSVLink>
          <FiRefreshCcw
            className="cursor"
            title="Refresh Data"
            size={20}
            onClick={() => getData(true)}
          />
        </div>
        <VPassTable
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          checkBoxSelectedData={checkBoxSelectedData}
          setcsvData={setcsvData}
          data={allPasses}
          page={page}
          setPage={setPage}
          setScreen={setScreen}
        />
      </div>

      <VisitorsStatusFilter
        isOpen={showTableFilter}
        setIsOpen={setShowTableFilter}
        getData={getData}
        runTableFilter={runTableFilter}
        setTableFIlterStatus={setTableFIlterStatus}
        tableFilterStatus={tableFilterStatus}
      />
    </div>
  );
};

export default VisitorsHome;
