import React, { useState, useImperativeHandle, forwardRef, useEffect, useContext } from "react";
import "../styles/index.css";
import "./engine.css";
import Dashboard from "../screens/dashboard/dashboard";
import Properties from "../screens/properties/properties";
import Broadcasts from "../screens/broadcasts/broadcasts";
import Residents from "../screens/residents/residents";
import Finances from "../screens/finances/finances";
import Visitors from "../screens/visitors/visitors";
import Compliants from "../screens/compliants/compliants";
import Settings from "../screens/settings/settings";
import EngineTopBar from "../components/engine-top-bar/engine-top-bar";
import EngineBottomBar from "../components/engine-bottom-bar/engine-bottom-bar";
import Verification from "../screens/verification/verification";
import AppContext from "../context/app-context";
import estateAccesses from "../context/estate-staff-access";

const Engine = forwardRef(function ({ sideNav }, ref) {
  const { loggedIn, usersData } = useContext(AppContext);

  const [onView, setOnView] = useState(
    document?.location?.hash?.toUpperCase()?.replace("#", "")?.trim() || "DASHBOARD"
  );

  useEffect(() => {
    if (!usersData?.username) return;

    //force default route by access
    if (
      !estateAccesses[usersData?.estate_child_roles[0]]?.includes(
        capitalizeEachWord(document.location.hash?.replace("#", "").trim())
      )
    ) {
      setOnView(estateAccesses[usersData?.estate_child_roles[0]][0]?.toUpperCase());
      document.location.hash = capitalizeEachWord(
        estateAccesses[usersData?.estate_child_roles[0]][0]
      );
    }
  }, [loggedIn, usersData]);

  useEffect(() => {
    //mobile force route
    const isMobile = navigator?.userAgentData?.mobile || window?.screen?.availWidth < 500;
    if (isMobile) {
      setOnView("VERIFICATION");
      document.location.hash = "Verification";
    }
  }, [loggedIn, usersData]);

  useImperativeHandle(ref, () => {
    return {
      changeScreen(screen) {
        setOnView(screen);
        sideNav?.setActiveNav(screen);
        document.location.hash = capitalizeEachWord(screen);
      },
    };
  });

  function capitalizeEachWord(word) {
    const arr = word?.split(" ");
    const mapped = arr?.map((e) => {
      return e[0]?.toUpperCase() + e?.slice(1)?.toLowerCase();
    });

    return mapped.join(" ");
  }

  return (
    <div className="engine-container">
      <EngineTopBar />
      <Dashboard sideNav={sideNav} display={onView === "DASHBOARD" && true} />
      <Properties display={onView === "PROPERTIES" && true} />
      <Residents display={onView === "RESIDENTS" && true} />
      <Finances display={onView === "FINANCES" && true} />
      <Visitors display={onView === "VISITORS" && true} />
      <Verification display={onView === "VERIFICATION" && true} />
      <Broadcasts display={onView === "BROADCASTS" && true} />
      <Compliants display={onView === "COMPLIANTS" && true} />
      <Settings display={onView === "SETTINGS" && true} />
      <EngineBottomBar />
    </div>
  );
});

export default Engine;
