import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../finances.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdArrowBack } from "react-icons/io";
import { DuesTable } from "../../../components/dues-table/dues-table";
import AddABillModal from "../../../components/create-a-bill-modal/create-a-bill-modal";
import EditABillModal from "../../../components/edit-due-modal/edit-due-modal";
import SecondSuccess from "../../../components/popup/second-success/second-success";
import SearchInput from "../../../components/searchInput/SearchInput";

const Dues = function ({ display, setScreen }) {
  const [showSucces, setShowSuccess] = useState(false);

  const [allDues, setAllDues] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [page, setPage] = useState(0);
  const [showAddABillModal, setshowAddABillModal] = useState(false);
  const [showEditBillModal, setShowEditBillModal] = useState({
    display: false,
    data: null,
  });
  const { backendServer, setIsLoading, patternMatching } = useContext(AppContext);

  const getData = async (loader) => {
    setPage(0);
    try {
      if (loader) setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/dues/get`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      setAllDues(returned?.data);
      setOriginalData(returned?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching finances...");
    }
  };

  useEffect(() => {
    if (!display) return;
    getData(true);
  }, [display]);

  function searchPlease(e) {
    setPage(0);
    const query = e.target.value;
    if (!query) return getData();

    const foundBills = [...originalData]?.filter((e) => {
      return (
        patternMatching(query, e.bill_type) ||
        patternMatching(query?.split(" ")[0], e.bill_type) ||
        patternMatching(query?.split(" ")[1], e.bill_type)
      );
    });

    setAllDues(foundBills);
  }

  async function deleteDue(id) {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/dues/delete/${id}`, {
          method: "DELETE",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
            "content-type": "application/json",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setIsLoading(false);
      getData(true);
      popup("Bill Deleted!");
    } catch (err) {
      setIsLoading(false);
      popup("Error deleting bill, try again later");
    }
  }

  if (!display) return;

  return (
    <div>
      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        <p className="small removemargin">
          <IoMdArrowBack
            onClick={() =>
              setScreen({
                screen: "home",
                data: null,
              })
            }
            style={{ marginTop: -3 }}
            size={17}
            className="cursor"
          />{" "}
          &nbsp; Back to Finances
        </p>
      </div>

      <div className="p-table-container">
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <div style={{ width: "80%" }}>
            <SearchInput placeholder="search for a bill type" onChange={(e) => searchPlease(e)} />
          </div>
          <div className="flex-row" style={{ width: "fit-content", gap: 10 }}>
            <button
              onClick={() => {
                if (originalData?.length >= 50)
                  return popup("An estate cannot have more than 50 Dues");
                setshowAddABillModal(true);
              }}
              className="themeBtn due-search-btn"
            >
              <AiOutlinePlus /> &nbsp; Create New Bill
            </button>
          </div>
        </div>
        <DuesTable
          setShowEditBillModal={setShowEditBillModal}
          page={page}
          setPage={setPage}
          data={allDues}
          deleteDue={deleteDue}
          setScreen={setScreen}
        />
      </div>

      <AddABillModal
        getData={getData}
        display={showAddABillModal}
        setAddABillModal={setshowAddABillModal}
        setShowSuccess={setShowSuccess}
      />
      <EditABillModal
        getData={getData}
        setEditABillModal={setShowEditBillModal}
        showEditBillModal={showEditBillModal}
      />

      <SecondSuccess
        title={"Due Created"}
        text={"Your new due has been added to your estate and residents have been notified."}
        isModalOpen={showSucces}
        handleModalOpen={setShowSuccess}
        buttonText={"Go to Dashboard"}
        onBtnClick={() => setShowSuccess(false)}
      />
    </div>
  );
};

export default Dues;
