import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./Editor.css";

import { Bold, Italic, UnderlineEditing } from "@ckeditor/ckeditor5-basic-styles";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";

interface IEditor {
  name?: string;
  maxWidth?: string;
  value?: any;
  onChange?: (arg: { text: string; htmlString: string; rawHtmString?: string }) => void;
  placeholder?: string;
}

const Editor = ({ name, value, placeholder, onChange, maxWidth }: IEditor) => {
  const makeHtml = (htmlString: string) => {
    return `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title></title>
        </head>
        <body>
            ${htmlString}
        </body>
        </html>
        `;
  };

  function removeHtmlTags(input: string) {
    if (typeof input === "string") {
      return input.replace(/<[^>]+>/g, " ");
    }
    return input;
  }

  return (
    <div className="in-app-rich-text-editor-wrapper" style={{ minHeight: maxWidth || "30rem" }}>
      <CKEditor
        editor={ClassicEditor}
        data={value || placeholder || "<p>Start typing</p>"}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          const convertion = editor.conversion;
          console.log(editor);
          // console.log('Change.', convertion );
          // console.log('Change.', {  data } );
          const text = removeHtmlTags(data);
          onChange && onChange({ htmlString: makeHtml(data), text, rawHtmString: data });
        }}
        onBlur={(event, editor) => {
          // console.log( 'Blur.', editor );
        }}
        onFocus={(event, editor) => {
          // console.log( 'Focus.', editor );
        }}
      />
    </div>
  );
};

export default Editor;
