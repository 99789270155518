import React from "react";
import "./delete.css";
import deleteIcon from "../../../assets/delete-icon.png";
import "../../../styles/index.css";

const Delete = ({ title, isOpen, setIsOpen, caution, confirm, onConfirm }) => {
  if (!isOpen) return;
  return (
    <div
      className="modal-overlay"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="modal-content-body">
        <div className="delete-container">
          <img src={deleteIcon} alt="question" className="question" />
          <div className="delete-text">{title}</div>
          <div>{caution}</div>
          <div className="delete-buttons">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onConfirm();
              }}
            >
              {confirm}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delete;
