import { useState, useContext } from "react";
import { useMainAppContext } from "../../../context/appContext/MainAppContext";
import "./SecuritySettings.css";
import ToggleButton from "../../../components/toggleButton/ToggleButton";
import InputField from "../../../components/inputField/InputField";
import Button from "../../../components/button/Button";
import { AiFillCloseCircle } from "react-icons/ai";
import { popup } from "../../../vanilla-functions/model";
import AppContext from "../../../context/app-context";

type Props = {};

const SecuritySettings = (props: Props) => {
  const { userData, backendServerUrl, setIsLoading, emsToken } = useMainAppContext();
  const { backendServer } = useContext(AppContext);

  const [state, setState] = useState<any>({ enable2FA: false });

  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");

  const [otp, setOtp] = useState("");

  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openSucessModal, setOpenSuccessModal] = useState(false);

  const handleChangePassword = async () => {
    try {
      setIsLoading(true);
      // alert(userData.email)
      const body = JSON.stringify({
        email: userData.email,
      });
      const res = await (
        await fetch(`${backendServerUrl}/password/init`, {
          headers: {
            token: emsToken,
            "content-type": "application/json",
          },
          method: "PATCH",
          body: body,
        })
      ).json();

      const { status, message, data } = res;
      if (status.toLowerCase().includes("error")) {
        message && popup(message);
        setOpenOtpModal(true);
        return;
      }

      setOpenOtpModal(true);
    } catch (error: any) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      const body = JSON.stringify({
        email: userData.email.toLowerCase(),
        token: otp,
        password: password,
      });
      setIsLoading(true);
      const res = await (
        await fetch(`${backendServerUrl}/password/change`, {
          headers: {
            "content-type": "application/json",
          },
          method: "PATCH",
          body: body,
        })
      ).json();

      const { status, message } = res;

      if (status.toLowerCase().includes("error")) {
        message && popup(message);
        setIsLoading(false);
        return;
      } else {
        setIsLoading(false);
        popup("Password reset was successful!");
        setOpenChangePasswordModal(false);
        return;
      }
    } catch (error: any) {
      popup(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2.5rem" }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="security-tile-title">Email Address</div>
          <div className="security-tile-text">Email associated with your account</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <div>{userData.email}</div>
          <span style={{ color: "#347900" }}>Verified</span>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="security-tile-title">Password</div>
          <div className="security-tile-text">
            Set a unique password to keep your account secured
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <a
            className="cursor"
            style={{ textDecoration: "underline" }}
            onClick={() => handleChangePassword()}
          >
            Change Password
          </a>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="security-tile-title">2-Step Verification</div>
          <div className="security-tile-text">
            Activating this adds an extra layer of security for your account
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <ToggleButton
            value={state["2FA"]}
            name={"2FA"}
            onChange={({ name, value }) => setState((p: any) => ({ ...p, [name]: value }))}
          />
        </div>
      </div>
      {openOtpModal && (
        <div className="change-password-overlay">
          <div className="change-password-overlay-contents">
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Confirm your identity
            </div>
            <div
              style={{
                fontSize: "0.7rem",
                marginTop: "1rem",
              }}
            >
              you're about to change your password confirm your identity by imputing the code sent
              to your email below
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1.5rem",
              }}
            >
              <InputField
                label={""}
                name={""}
                containerStyle={{
                  zIndex: 2,
                  opacity: 0,
                }}
                maxLength={4}
                inputStyle={{
                  letterSpacing: "3.8rem",
                  zIndex: 3,
                  backgroundColor: "transparent",
                  border: "none",
                }}
                value={otp}
                onChange={(e: any) => setOtp(e.target.value)}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "absolute",
                  zIndex: 1,
                  gap: "0.2rem",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {["1", "2", "3", "4"].map((x, i) => (
                  <div
                    style={{
                      border: "1px solid rgba(58, 53, 65, 0.23)",
                      borderRadius: "px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "3rem",
                      width: "3rem",
                      fontSize: "0.9rem",
                      // color: 'rgba(58, 53, 65, 0.23)'
                    }}
                    key={i}
                  >
                    {otp.split("")[i] || "*"}
                  </div>
                ))}
              </div>
            </div>
            <Button
              style={{
                marginTop: "1.5rem",
                height: "3rem",
                fontSize: "0.8rem",
              }}
              label="Verify"
              onClick={async () => {
                try {
                  setIsLoading(true);
                  const codeVerify = await (
                    await fetch(`${backendServer}/any/token/${userData?.email}`, {
                      method: "POST",
                      headers: {
                        "content-type": "application/json",
                      },
                      body: JSON.stringify({ token: otp }),
                      credentials: "include",
                    })
                  ).json();

                  if (codeVerify.status === "Internal server error")
                    throw new Error(codeVerify.message);
                  setOpenOtpModal(false);
                  setOpenChangePasswordModal(true);
                  setIsLoading(false);
                } catch (err: any) {
                  popup(err?.message || "Something went wrong");
                  setIsLoading(false);
                }
              }}
            />
            <AiFillCloseCircle
              style={{
                position: "absolute",
                right: "1rem",
                top: "1rem",
                fontSize: "1.5rem",
                color: "rgba(58, 53, 65, 0.23)",
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenOtpModal(false);
              }}
            />
          </div>
        </div>
      )}
      {openChangePasswordModal && (
        <div className="change-password-overlay">
          <div className="change-password-overlay-contents">
            <div
              style={{
                fontSize: "0.7rem",
                marginTop: "1rem",
              }}
            >
              Please enter a new secure password
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1.5rem",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <InputField
                placeholder="New Password"
                name={"password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                label={""}
              />
              <InputField
                placeholder="Re-Enter New Password"
                name={"rePassword"}
                value={rePassword}
                onChange={(e) => {
                  setRePassword(e.target.value);
                }}
                label={""}
              />
            </div>
            {password !== rePassword && (
              <span style={{ letterSpacing: "1px", fontSize: "0.6rem", color: "var(--error-30)" }}>
                passwords do not match
              </span>
            )}
            <Button
              style={{
                marginTop: "1.5rem",
                height: "3rem",
                fontSize: "0.8rem",
              }}
              onClick={() => {
                if (password === rePassword && password.length > 3) {
                  handleResetPassword();
                }
              }}
              label="Verify"
            />
            <AiFillCloseCircle
              style={{
                position: "absolute",
                right: "1rem",
                top: "1rem",
                fontSize: "1.5rem",
                color: "rgba(58, 53, 65, 0.23)",
              }}
              onClick={() => setOpenChangePasswordModal(false)}
            />
          </div>
        </div>
      )}

      {openSucessModal && (
        <div
          onClick={() => {
            setOpenSuccessModal(false);
          }}
        >
          success here
        </div>
      )}
    </div>
  );
};

export default SecuritySettings;
