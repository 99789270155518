import React, { useEffect, useState } from 'react'
import { HiPencil } from 'react-icons/hi'
import { useMainAppContext } from '../../../../context/appContext/MainAppContext';
import UserNotificationSettings from '../../notification/UserNotificationSettings';
import './AccountInfoView.css'
import { fileToBase64 } from '../../../../utils';
import { UpdateUserProfilePicUrl } from '../../../../config/constants/endpoints';
type Props = {
    onNavigate?: (arg:any) => void;
}

const AccountInfoView = ({onNavigate}: Props) => {
    const [newLogo, setNewLogo] = useState<string | undefined>(undefined)
    const {userData, getSignedAwsUrl, backendServerUrl, emsToken, setIsLoading, updateUsersData} = useMainAppContext();
    const [userImage, setUserImage] = useState("");


    useEffect(() => {
        (async function () {
          setUserImage(await getSignedAwsUrl(userData?.profile_picture, "profile-pictures"));
        })();
      }, [userData]);

      const handleUploadProfilePicture = async (imageUri: File) => {

        const formData = new FormData();
      
      
          formData.append('photo', imageUri)
      
          const pathUrl  = UpdateUserProfilePicUrl(userData._id)
          const url = `${backendServerUrl}/${pathUrl}`;

          try {
                setIsLoading(true);
                
                await (await fetch(url, {
                    method: 'PATCH',
                    body: formData,
                    headers: {
                        "enctype": "multipart/form-data",
                        "token": emsToken
                    },      
                })).json();

                await updateUsersData()

                
            } catch (error) {
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            } 
      }

  return (
    <div className='estate-info-container'>
        <div className='estate-info-first-child'>
            <div className='estate-info-first-child-logo'>
                <img
                    src={newLogo || userImage || "/user_placeholder.png"}
                    alt="estility admin"
                    className=""
                />
            </div>
            <div className='accountinfo-edit-info-change-logo'>
                <span>Change profile picture</span>
                <input type='file' 
                title=''
                onChange={
                    async ({ target: { files } }) => {
                        if (files !== null) {
                          const file = files[0];
                          try {
                            handleUploadProfilePicture(file)
                            const imgSrc = await fileToBase64(file)
                            setNewLogo(imgSrc)                    
                          } catch (error) {
                            console.log(error)
                          }
                        }
                      }
                }/>
            </div>
        </div>
        <div className='estate-info-child'>
            <div>
                <strong>First Name</strong>
                <span>{userData.first_name}</span>
            </div>
            <div>
                <strong>Last Name</strong>
                <span>{userData.last_name}</span>
            </div>
        </div>
        <div className='estate-info-child'>
            <div>
                <strong>Email</strong>
                <span>{userData.email}</span>
            </div>
            <div>
                <strong>Phone Number</strong>
                <span>{userData.mobile  }</span>
            </div>
        </div>

        <div className='estate-info-child'>
            <div>
                <strong>Address</strong>
                <span>{userData.address.addressString}</span>
            </div>
        </div>

        <div className='estate-info-child'>
            <div>
                <strong>Role</strong>
                <span>{userData.role}</span>
            </div>
            <div>
                <strong>Permission</strong>
                <span>{userData.role}</span>
            </div>
        </div>

        <div className='estate-info-action-wrapper'>
            <div onClick={() => onNavigate && onNavigate(1)}>
                <HiPencil />
                <span>Edit Details</span>
            </div>
        </div>
        <div></div>
        <UserNotificationSettings />
    </div>
  )
}

export default AccountInfoView