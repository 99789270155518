import React, { useState, useContext, useEffect } from "react";
import "../../../styles/index.css";
import "../visitors.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { IoMdArrowBack } from "react-icons/io";

const VisitorsDetails = function ({ display, setScreen, screen }) {
  const [vPassData, setVPassData] = useState(null);
  const { backendServer, setIsLoading } = useContext(AppContext);

  useEffect(() => {
    if (!screen?.data) return;
    (async function () {
      try {
        setIsLoading(true);

        const returned = await (
          await fetch(`${backendServer}/visitors-pass/get/${screen?.data}`, {
            method: "GET",
            credentials: "include",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
        setVPassData(returned?.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        popup("Error fetching visitor pass");
      }
    })();

    return () => setVPassData(null);
  }, [screen]);

  if (!display) return;

  return (
    <div>
      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        <p className="big">
          <IoMdArrowBack
            onClick={() =>
              setScreen({
                screen: "home",
                data: null,
              })
            }
            size={20}
            className="cursor"
          />{" "}
          &nbsp;Go back
        </p>
      </div>

      <p className="big boldText" style={{ marginLeft: 5 }}>
        Visitor Information
      </p>
      <div className="flex-row align-row-left more-gap">
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">First Name</p>
          <p className="small  removemargin">{vPassData?.visitor_details?.first_name}</p>
        </div>
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Last Name</p>
          <p className="small  removemargin">{vPassData?.visitor_details?.last_name}</p>
        </div>
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Mobile</p>
          <p className="small  removemargin">{vPassData?.visitor_details?.mobile}</p>
        </div>
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Number of Visitors</p>
          <p className="small  removemargin">{vPassData?.number_of_persons}</p>
        </div>
      </div>

      <div className="flex-row align-row-left more-gap">
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Time In</p>
          <p className="small  removemargin">
            {vPassData?.arrival_date
              ? new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(vPassData?.arrival_date))
              : "-"}
          </p>
        </div>
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Time Out</p>
          <p className="small  removemargin">
            {vPassData?.departure_date
              ? new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(vPassData?.departure_date))
              : "-"}
          </p>
        </div>
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Status</p>
          <p className="small  removemargin">
            {vPassData?.departure_date
              ? "Departed"
              : vPassData?.arrival_date
              ? "Approved"
              : vPassData?.total_declined_status
              ? "Declined"
              : vPassData?.active
              ? "Pending"
              : ""}
          </p>
        </div>
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Type</p>
          <p className="small  removemargin">{vPassData?.pass_type || "-"}</p>
        </div>
      </div>

      <p className="big boldText" style={{ marginLeft: 5 }}>
        Host Information
      </p>
      <div className="flex-row align-row-left more-gap">
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Full Name</p>
          <p className="small  removemargin">
            {vPassData?.residentID?.first_name} {vPassData?.residentID?.last_name}
          </p>
        </div>
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Property</p>
          <p className="small  removemargin">{vPassData?.residentID?.address?.addressString}</p>
        </div>
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Property Code</p>
          <p className="small  removemargin">{vPassData?.property_code}</p>
        </div>
      </div>
      <div className="flex-row align-row-left more-gap">
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Mobile</p>
          <p className="small  removemargin">{vPassData?.residentID?.mobile}</p>
        </div>
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Request Date</p>
          <p className="small  removemargin">
            {vPassData?.createdAt
              ? new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(vPassData?.createdAt))
              : "-"}
          </p>
        </div>
        <div className="flex-column align-column-left width-fit-content vpass-d-max-width">
          <p className="small boldText removemargin">Request Time</p>
          <p className="small  removemargin">
            {vPassData?.createdAt
              ? new Intl.DateTimeFormat("en-us", {
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(vPassData?.createdAt))
              : "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default VisitorsDetails;
