import { useState, useContext, useEffect } from "react";
import "../../../styles/index.css";
import "../verification.css";
import { popup } from "../../../vanilla-functions/model";
import AppContext from "../../../context/app-context";
import { MDBSpinner } from "mdb-react-ui-kit";

const theCode = [];

const VerificationHome = ({ display, setScreen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const { backendServer } = useContext(AppContext);
  const [borderStateColor, setBorderStateColor] = useState("grey");
  const [code, setCode] = useState("");

  useEffect(() => {
    if (code.length === 4) setDisabledBtn(false);
    else setDisabledBtn(true);

    if (isLoading) setDisabledBtn(true);
  });

  function handleCodeChange(e) {
    setBorderStateColor("grey");
    if (!e.target.value)
      document
        .querySelector("#codeboxcontainer")
        ?.children[Number(e.target.dataset.key) - 1]?.children[0]?.focus();
    else
      document
        .querySelector("#codeboxcontainer")
        ?.children[Number(e.target.dataset.key) + 1]?.children[0]?.focus();

    theCode[e.target.dataset.key] = e.target.value;

    setCode(theCode.join(""));
  }

  async function verifyPassCode() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/visitors-pass/verify/${code}`, {
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error();
      if (returned?.data?.active === "false" || !returned?.data?.passDetails) throw new Error();

      setScreen({
        screen: "verify",
        data: returned?.data?.passDetails,
      });
      setBorderStateColor("grey");
      setIsLoading(false);
      setDisabledBtn(false);
    } catch {
      popup("Invalid or Expired Visitors Pass");
      setBorderStateColor("red");
      setIsLoading(false);
      setDisabledBtn(false);
    }
  }

  if (!display) return;

  return (
    <div>
      <div className="verification-card-cont">
        <img
          src="/v-1.png"
          alt="Estility Verification"
          title="Verify Visitor Code"
          id="verify-screen-banner"
        />
        <div className="verification-card">
          <p className="vbig blackText removemargin">New Visitor</p>
          <p className="small blackText removemargin">
            Verify their identity by inputting their passcode below
          </p>

          <div id="codeboxcontainer" className="flex-row verification-code-box-cont">
            <div className="code-boxes" style={{ borderColor: borderStateColor }}>
              <input
                maxLength="1"
                data-key={0}
                placeholder="*"
                type="number"
                onChange={handleCodeChange}
                className="ver-code-input"
              />
            </div>
            <div className="code-boxes" style={{ borderColor: borderStateColor }}>
              <input
                maxLength="1"
                data-key={1}
                placeholder="*"
                type="number"
                className="ver-code-input"
                onChange={handleCodeChange}
              />
            </div>
            <div className="code-boxes" style={{ borderColor: borderStateColor }}>
              <input
                maxLength="1"
                data-key={2}
                placeholder="*"
                type="number"
                className="ver-code-input"
                onChange={handleCodeChange}
              />
            </div>
            <div className="code-boxes" style={{ borderColor: borderStateColor }}>
              <input
                maxLength="1"
                data-key={3}
                placeholder="*"
                type="number"
                className="ver-code-input"
                onChange={handleCodeChange}
              />
            </div>
          </div>

          <button
            disabled={disabledBtn}
            onClick={verifyPassCode}
            title="Verify Visitors Code"
            className="themeBtn verification-btn-one"
          >
            {isLoading && (
              <MDBSpinner size="sm" className="mx-2" color="normal">
                <span className="visually-hidden"></span>
              </MDBSpinner>
            )}{" "}
            Verify Code
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerificationHome;
