import React, { useState, useEffect, useContext } from "react";
import "../components/residents-table/residents-table.css";
import { FiEdit3 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import AppContext from "../context/app-context";
import { popup } from "../vanilla-functions/model";
import Delete from "../components/popup/delete/delete";
import EditAUserModal from "../components/edit-resident-details-modal/edit-resident-details-modal";

const ResidentsOptionsSvg = ({ data, hardRefresh }) => {
  const [showAction, setshowAction] = useState(false);
  const { backendServer, setIsLoading, updateUsersData } = useContext(AppContext);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);

  useEffect(() => {
    document.querySelector("body")?.addEventListener("click", (e) => {
      if (
        e.target.classList.contains("r-table-option-svg") ||
        e.target.classList.contains("r-filter-dropdown")
      )
        return;
      setshowAction(false);
    });
  }, []);

  async function deleteResident() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/user/delete/${data?._id}`, {
          method: "DELETE",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setshowAction(false);
      setIsLoading(false);
      setShowDeletePopup(false);
      popup("Residents Deleted!");
      updateUsersData();
      if (hardRefresh) hardRefresh();
    } catch (err) {
      setIsLoading(false);
      popup("Error deleting Residents...");
    }
  }
  return (
    <div>
      <svg
        className="cursor r-table-option-svg"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        onClick={(e) => {
          e.stopPropagation();
          setshowAction(true);
        }}
      >
        <path
          d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
          stroke="#101828"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
          stroke="#101828"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
          stroke="#101828"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className={`r-filter-dropdown ${!showAction && "hide"}`}>
        <div
          className="each-r-filter-dropown"
          onClick={(e) => {
            e.stopPropagation();
            setShowEditUserModal(true);
            setshowAction(false);
          }}
        >
          <FiEdit3 size={17} />
          <p className="small removemargin">Edit Resident</p>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDeletePopup(true);
            setshowAction(false);
          }}
          className="each-r-filter-dropown r-no-border"
        >
          <AiOutlineDelete size={17} color="red" />
          <p className="small removemargin">Delete Resident</p>
        </div>
      </div>

      <Delete
        title={"Are you sure you want to delete this resident from your estate"}
        caution={"This action cannot be undone"}
        setIsOpen={setShowDeletePopup}
        isOpen={showDeletePopup}
        confirm={"Confirm Delete"}
        onConfirm={deleteResident}
      />

      <EditAUserModal
        data={data}
        display={showEditUserModal}
        setEditAUserModal={setShowEditUserModal}
        hardRefresh={hardRefresh}
      />
    </div>
  );
};

export { ResidentsOptionsSvg };
