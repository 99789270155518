const columns = [
  {
    id: "f_checkbox",
    label: (
      <input
        id="f-checkbox-parent"
        type="checkbox"
        className="table-checkboxes f-table-checkboxes-head"
      />
    ),
    minWidth: 20,
  },
  { id: "bill_type", label: "Bill Type", minWidth: 170 },
  { id: "_id", label: "Transaction ID", minWidth: 170 },
  { id: "status", label: "Status", minWidth: 100 },
  {
    id: "date",
    label: "Date",
    minWidth: 170,
    align: "left",
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 100,
    align: "left",
  },
];

function sortData(data) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      f_checkbox: (
        <input
          data={e?._id}
          id="f-checkbox-children"
          type="checkbox"
          className="table-checkboxes f-table-checkboxes"
        />
      ),
      bill_type: e?.bill_type,
      _id: e?._id,
      status: (
        <span
          className={
            e?.status === "pending"
              ? "high-severity-t"
              : e?.status === "cancelled"
              ? "high-severity-t"
              : "low-severity-t"
          }
        >
          {e?.status}
        </span>
      ),
      date: new Intl.DateTimeFormat("en-us", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(e?.initiated_on)),
      amount: new Intl.NumberFormat("en-us", {
        currency: "NGN",
      }).format(e.amount),
    };
  });

  return finalArray;
}

export { sortData, columns };
