import React, { useRef, useState, useEffect } from "react";
import "./styles/index.css";
import Engine from "./engine/enigine";
import SideNav from "./navigation/side-nav";
import AppContext from "./context/app-context";
import Loading from "./components/loading/loading";
import Login from "./auth/login";
import MainAppContextProvider from "./context/appContext/MainAppContext";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState("none");
  const [usersData, setUsersData] = useState({});
  const [sideNav, setSideNav] = useState({});
  const [estate, setEstate] = useState({});
  const engineRef = useRef(null);

  const [backendServer, setBackendSever] = useState("");

  const getSignedAwsUrl = async function (filename, bucketname) {
    if (!filename) return;

    if (filename.split(":")[0] === "https" || filename.split(":")[0] === "http") return filename;

    const { data } = await (
      await fetch(`${backendServer}/file/get/${bucketname}/${filename}`, {
        method: "GET",
        credentials: "include",
        headers: {
          token: localStorage.getItem("emstoken"),
        },
      })
    ).json();

    return data;
  };

  function formatNumber(amount) {
    return new Intl.NumberFormat("en-us").format(amount);
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateMobile = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^(?:(?:(?:\+?234(?:\h1)?|01)\h*)?(?:\(\d{3}\)|\d{3})|\d{4})(?:\W*\d{3})?\W*\d{4}$/);
  };

  const updateUsersData = async function (andEstate) {
    const data = await (
      await fetch(`${backendServer}/user/get?email=${localStorage.getItem("emsemail")}`, {
        credentials: "include",
        headers: {
          token: localStorage.getItem("emstoken"),
        },
      })
    ).json();

    setUsersData(data.data);

    if (andEstate) getEstate();
  };

  function patternMatching(wordone, wordtwo) {
    if (!wordone || !wordtwo) return false;
    //wrote this for name searching
    let result = wordone?.split("")?.some((e, i) => {
      return String(e)?.toUpperCase() !== String(wordtwo?.split("")[i])?.toUpperCase();
    });
    return !result;
  }

  async function getEstate() {
    if (!loggedIn || loggedIn === "none") return;
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/estate/get/details`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setEstate(returned?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getEstate();
  }, [loggedIn]);

  const getUserFromGlobalBookAndUpdateState = async (email) => {
    const globalBookKey = "LTSQ1714rKvaqhLX";

    const globalBookUrl = `https://estility-global-book-ba163757a34b.herokuapp.com/estility-global-book/api/v1/${globalBookKey}/users/get?email=${email}`;

    const response = await (
      await fetch(globalBookUrl, {
        method: "GET",
      })
    ).json();

    if (response?.status === "Internal server error" || !response?.data?.estate?.backend_base_url)
      return false;
    else {
      localStorage.setItem("emsbaseurl", response?.data?.estate?.backend_base_url);
      setBackendSever(response?.data?.estate?.backend_base_url);
      return response?.data?.estate?.backend_base_url;
    }
  };

  function shortenNumber(num, decimalPlaces) {
    if (!num) return null;
    var str,
      suffix = "";

    decimalPlaces = decimalPlaces || 0;
    num = +num;

    const factor = Math.pow(10, decimalPlaces);

    if (num < 9999) {
      str = num;
    } else if (num < 1000000) {
      str = Math.floor(num / (1000 / factor)) / factor;
      suffix = "K";
    } else if (num < 1000000000) {
      str = Math.floor(num / (1000000 / factor)) / factor;
      suffix = "M";
    } else if (num < 1000000000000) {
      str = Math.floor(num / (1000000000 / factor)) / factor;
      suffix = "B";
    } else if (num < 1000000000000000) {
      str = Math.floor(num / (1000000000000 / factor)) / factor;
      suffix = "T";
    }
    return new Intl.NumberFormat("en-us").format(Number(str)) + suffix;
  }

  useEffect(() => {
    (async () => {
      const checkUrl = localStorage.getItem("emsbaseurl");
      if (checkUrl) setBackendSever(checkUrl);
      else {
        localStorage.removeItem("emsusername");
        localStorage.removeItem("emsemail");
        localStorage.removeItem("emstoken");
      }
    })();
  }, []);

  return (
    <AppContext.Provider
      value={{
        changeScreen: engineRef?.current?.changeScreen,
        setIsLoading,
        loggedIn,
        usersData,
        setUsersData,
        setLoggedIn,
        backendServer,
        getSignedAwsUrl,
        formatNumber,
        validateEmail,
        updateUsersData,
        patternMatching,
        validateMobile,
        estate,
        getUserFromGlobalBookAndUpdateState,
        shortenNumber,
      }}
    >
      <MainAppContextProvider>
        <>
          {(!loggedIn || loggedIn === "none") && <Login />}

          {loggedIn === true && (
            <div>
              <div className="App">
                <SideNav loggedIn={loggedIn} setSideNav={setSideNav} />
                <Engine sideNav={sideNav} ref={engineRef} loggedIn={loggedIn} />
              </div>
            </div>
          )}

          <Loading show={isLoading} />
        </>
      </MainAppContextProvider>
    </AppContext.Provider>
  );
}

export default App;
