import { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { EstateUsersTable } from "./estate-users-table/estate-users-table";
import AddAnEstateUserModal from "../../../components/add-an-estate-user/add-an-estate-user";
import EditAnEstateStaffModal from "../../../components/edit-an-estate-user/edit-an-estate-user";

type Props = { active: Boolean | undefined };

const EstateUsers = ({ active }: Props) => {
  const { backendServer, setIsLoading, getSignedAwsUrl, usersData } = useContext(AppContext);
  const [page, setPage] = useState(0);
  const [allEstateUsers, setAllEstateUsers] = useState([]);

  const [editStaffModal, setEditStaffModal] = useState<{ display: boolean; data: {} }>({
    display: false,
    data: {},
  });

  const [showAddAnEstateModal, setShowAddAnEstateModal] = useState(false);

  const getData = async () => {
    setPage(0);
    setIsLoading(true);
    try {
      const returned = await (
        await fetch(`${backendServer}/user/get?estatechildren=true`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken") || "",
          },
        })
      ).json();

      const resolvedImagesData: [] = returned?.data?.map(async (e: any) => {
        if (e.profile_picture)
          e.profile_picture = await getSignedAwsUrl(e?.profile_picture, "profile-pictures");
        else e.profile_picture = "/user_placeholder.png";

        return e;
      });

      setAllEstateUsers(await Promise.all(resolvedImagesData));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching finances...");
    }
  };

  useEffect(() => {
    getData();
  }, [active]);

  const deleteEstateUser = async (id: string) => {
    if (id === usersData?._id) return popup("You cannot delete yourself as an admin!");
    setIsLoading(true);
    try {
      const returned = await (
        await fetch(`${backendServer}/user/delete/${id}`, {
          method: "DELETE",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      getData();
      popup("Staff deleted");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Oooops, error deleting that staff");
    }
  };

  return (
    <div>
      <div className="flex-row space-between" style={{ height: 70 }}>
        <p className="big removemargin">Estate Staff Accounts</p>
        <button
          title="Add an Estate Staff"
          className="themeBtn"
          onClick={() => setShowAddAnEstateModal(true)}
        >
          + Add New
        </button>
      </div>
      <EstateUsersTable
        setShowEditEstateUser={setEditStaffModal}
        page={page}
        setPage={setPage}
        data={allEstateUsers}
        deleteEstateUser={deleteEstateUser}
      />

      <AddAnEstateUserModal
        display={showAddAnEstateModal}
        setAddAnEstateUserModal={setShowAddAnEstateModal}
        getData={getData}
      />

      <EditAnEstateStaffModal
        setEditAnEstateStaffModal={setEditStaffModal}
        data={editStaffModal?.data}
        display={editStaffModal?.display}
        getData={getData}
      />
    </div>
  );
};

export default EstateUsers;
