import { ToggleLeft, ToggleRight } from 'phosphor-react';
import React from 'react'

type Props = {
    value: boolean;
    name: string;
    onChange?: (arg: {name: string, value: boolean}) => void;
}

const ToggleButton = ({name, value, onChange}: Props) => {
  return (
    value?
    <ToggleRight onClick={() => onChange && onChange({name, value: !value})} weight='fill' size={32} color='#7975B6' cursor={'pointer'}/>
    :
    <ToggleLeft onClick={() => onChange && onChange({name, value: !value})} weight='fill' size={32}  color='gray' cursor={'pointer'}  />
  )
}

export default ToggleButton