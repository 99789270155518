import React from "react";
import "./Button.css";

type Props = {
  label: string;
  onClick?: () => void;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  btnType?: "outline" | "fill";
  style?: React.CSSProperties;
};

const Button = ({ label, style, onClick, leftIcon, rightIcon, btnType = "fill" }: Props) => {
  return (
    <div
      style={style}
      onClick={() => onClick && onClick()}
      className={`main-button ${
        btnType === "outline" ? "main-button-outline" : "main-button-fill"
      }`}
    >
      {leftIcon}
      {label}
      {rightIcon}
    </div>
  );
};

export default Button;
