import { Popconfirm, Button } from "antd";
import "./popup.css";
import { AiOutlineEye } from "react-icons/ai";

const BroadcastTablePopup = ({ text }) => {
  return (
    <>
      <div>
        <Popconfirm
          placement="leftTop"
          title={"Broadcast Message"}
          description={<p dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, "<br />") }}></p>}
          okText=""
          cancelText="Cancel"
          okType="ghost"
          cancelButtonProps={{ type: "text" }}
          onConfirm={() => {}}
        >
          <Button type="text" title="">
            <AiOutlineEye color="grey" size={17} />
          </Button>
        </Popconfirm>
      </div>
    </>
  );
};

export default BroadcastTablePopup;
