import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./dashboard.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import AllDuesChartPlusOrderIncentives from "../../components/all-dues-plus-order-chart/all-dues-plus-order-chart";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FinancesTable } from "../../components/finances-table/finances-table";

const Dashboard = function ({ display }) {
  const [page, setPage] = useState(0);
  const [allTransactions, setAllTransactions] = useState("");
  const [fullEstateStat, setFullEsateStat] = useState({});
  // const [activeFilter, setActiveFilter] = useState("All Orders");
  const { backendServer, setIsLoading, changeScreen } = useContext(AppContext);

  useEffect(() => {
    getData();
  }, [backendServer]);

  const getData = async () => {
    setPage(0);
    try {
      setIsLoading(true);

      const returnedDues = await (
        await fetch(
          `${backendServer}/dues/get?allpopulateonetimepayersPlusAllpopulateontimemap=true`,
          {
            credentials: "include",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          }
        )
      ).json();

      const initialOneTimeDuePaymentData = [...returnedDues?.data].reverse();

      const allTransactionsRegardingTheEstate = [];

      initialOneTimeDuePaymentData.forEach((theFullDue) => {
        if (theFullDue?.bill_frequency === "One Time") {
          theFullDue.one_time_payers.forEach((e) => {
            allTransactionsRegardingTheEstate.push({
              name: e?.user?.first_name + ` ${e?.user?.last_name}`,
              initiated_on: e?.paymentRef?.initiated_on,
              bill_type: theFullDue?.bill_type,
              _id: e?._id,
              status: "completed",
              amount: e?.paymentRef?.amount || theFullDue?.amount,
            });
          });
        } else if (theFullDue?.mappedSubscriptions) {
          theFullDue?.mappedSubscriptions?.forEach((e) => {
            allTransactionsRegardingTheEstate.push({
              name: e?.customer?.first_name + ` ${e?.customer?.last_name}`,
              initiated_on: e?.most_recent_invoice?.created_at,
              bill_type: theFullDue?.bill_type,
              _id: e?.most_recent_invoice?.invoice_code,
              status: "completed",
              amount: theFullDue?.amount,
            });
          });
        }
      });

      setAllTransactions(allTransactionsRegardingTheEstate);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching finances...");
    }
  };

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        const returned = await (
          await fetch(`${backendServer}/estate/get/fullstat`, {
            method: "GET",
            credentials: "include",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
        setFullEsateStat(returned?.data);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        popup("Error fetching Estate Compliants");
      }
    })();
  }, [backendServer]);

  if (!display) return;

  return (
    <div className="screen-container">
      <div className="dashboad-stat-container flex-row">
        <div className="each-d-stat-container">
          <img src="/d-1.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Residents<br></br>
            <span className="big d-stat-text">
              {fullEstateStat?.total_number_of_residents || "-"}
            </span>
          </p>
        </div>

        <div className="each-d-stat-container">
          <img src="/d-2.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Total Properties<br></br>
            <span className="big d-stat-text">
              {fullEstateStat?.properties?.total_number_of_properties || "-"}
            </span>
          </p>
        </div>

        <div className="each-d-stat-container">
          <img src="/d-3.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Current Issues<br></br>
            <span className="big d-stat-text">{"-"}</span>
          </p>
        </div>

        <div className="each-d-stat-container">
          <img src="/d-4.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Visitors Pass<br></br>
            <span className="big d-stat-text">
              {fullEstateStat?.visitorsPas?.total_number_of_visitors_pass || "-"}
            </span>
          </p>
        </div>
      </div>

      <div className="dashboard-graph-pie-container">
        <div className="d-graph-container">
          <div className="flex-row chart-intro">
            <p className="big greyText">Finance</p>
            {/* <BarChartDropDown activeFilter={activeFilter} setActiveFilter={setActiveFilter} /> */}
          </div>
          <AllDuesChartPlusOrderIncentives />
        </div>

        {/* <div className="d-pie-container">
          <div className="pie-label">
            <p className="small" style={{ marginTop: 10, textAlign: "center" }}>
              {fullEstateStat?.compliants?.total_number_of_compliants}
              <br></br>Compliants
            </p>
          </div>
          <div className="flex-row chart-intro">
            <p className="small greyText">Compliants</p>
          </div>
          <CompliantsPieChart />
        </div> */}
      </div>

      <div className="d-table-container">
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <p className="big removemargin">Latest Finance</p>
          <p className="small removemargin cursor" onClick={() => changeScreen("FINANCES")}>
            View All &nbsp; <AiOutlineArrowRight style={{ marginTop: -3 }} />
          </p>
        </div>
        <FinancesTable
          data={allTransactions}
          page={page}
          setPage={setPage}
          showPageNumber={false}
        />
      </div>
    </div>
  );
};

export default Dashboard;
