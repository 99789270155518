import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./create-a-bill-modal.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const AddABillModal = function ({ display, setAddABillModal, getData, setShowSuccess }) {
  const { backendServer, setIsLoading } = useContext(AppContext);
  const [disableBtn, setdisableBtn] = useState(true);
  const [billType, setbillType] = useState("");
  const [billPayer, setbillPayer] = useState("");
  const [propertyType, setpropertyType] = useState("");
  const [billFrequency, setbillFrequency] = useState("");
  const [amount, setamount] = useState("");

  useEffect(() => {
    if (!billType || !billPayer || !propertyType || !billFrequency || !amount || amount < 1)
      setdisableBtn(true);
    else setdisableBtn(false);
  });

  async function addDue() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/dues/create`, {
          method: "POST",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify({
            bill_type: billType,
            bill_payer: billPayer,
            property_type: propertyType,
            bill_frequency: billFrequency,
            amount: amount,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setIsLoading(false);
      setShowSuccess(true);
      setAddABillModal(false);
      getData(true);
      setbillType("");
      setbillPayer("");
      setpropertyType("");
      setbillFrequency("");
      setamount("");
    } catch (err) {
      setIsLoading(false);
      popup("Error creating bill, try again later");
    }
  }

  if (!display) return;

  return (
    <div className="modal-container">
      <div className="modal-body edit-a-bill-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setAddABillModal(false);
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="a-bill-modal-cont flex-column align-column-left">
          <div className="flex-row align-row-left">
            <p className="big">Fill in the new bill details below</p>
          </div>

          <div className="flex-row">
            <input
              className="in a-a-u-inputs"
              placeholder="Bill Name"
              type="text"
              value={billType}
              onChange={(e) => setbillType(e.target.value)}
              maxLength="30"
            />

            <MDBDropdown id="mdb-dropdown-custom-dropdown">
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {billPayer || "Bill Payer"}
              </MDBDropdownToggle>

              <MDBDropdownMenu id="edit-a-a-bill-mdb-menu-custom-dropdown">
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillPayer(e.target.textContent);
                  }}
                >
                  Primary
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillPayer(e.target.textContent);
                  }}
                >
                  Secondary
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillPayer(e.target.textContent);
                  }}
                >
                  Property Owner
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillPayer(e.target.textContent);
                  }}
                >
                  All Residents
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </div>

          <div className="flex-row">
            <MDBDropdown id="mdb-dropdown-custom-dropdown">
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {propertyType || "Property Type"}
              </MDBDropdownToggle>

              <MDBDropdownMenu id="edit-a-a-bill-mdb-menu-custom-dropdown">
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setpropertyType(e.target.textContent);
                  }}
                >
                  Flat
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setpropertyType(e.target.textContent);
                  }}
                >
                  Studio Apt
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setpropertyType(e.target.textContent);
                  }}
                >
                  Facility
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setpropertyType(e.target.textContent);
                  }}
                >
                  All Properties
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

            <MDBDropdown id="mdb-dropdown-custom-dropdown">
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {billFrequency || "Bill Frequency"}
              </MDBDropdownToggle>

              <MDBDropdownMenu id="edit-a-a-bill-mdb-menu-custom-dropdown">
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillFrequency(e.target.textContent);
                  }}
                >
                  Monthly
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillFrequency(e.target.textContent);
                  }}
                >
                  Quarterly
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillFrequency(e.target.textContent);
                  }}
                >
                  Yearly
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#Finances"
                  onClick={(e) => {
                    setbillFrequency(e.target.textContent);
                  }}
                >
                  One Time
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </div>

          <div className="flex-row">
            <input
              className="in a-a-u-inputs"
              placeholder="Amount"
              type="number"
              value={amount}
              onChange={(e) => setamount(e.target.value)}
              min="1"
            />
            <input
              className="in a-a-u-inputs"
              placeholder=""
              type="FAKE INPUT"
              maxLength="100"
              value={""}
              style={{ opacity: 0 }}
              onChange={() => {}}
            />
          </div>

          <button onClick={addDue} className="themeBtn a-a-u-btn" disabled={disableBtn}>
            Create New Bill
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddABillModal;
