import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../finances.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { CgSortAz } from "react-icons/cg";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {
  FinancesTable,
  pseudoCheckBoxSelectedData,
} from "../../../components/finances-table/finances-table";
import SearchInput from "../../../components/searchInput/SearchInput";
import FinanceFilter from "./finance-filter";
import { AiOutlineDown } from "react-icons/ai";
import AllIncentiveFilter from "../../../components/filter-modals/finance/incentives";

const FinancesHome = function ({ display, setScreen }) {
  const [tableFilterStatus, setTableFIlterStatus] = useState({
    from: "",
    to: "",
    status: "",
    billType: "",
  });

  const [showTableFilter, setShowTableFilter] = useState(false);

  const [allTransactions, setAllTransactions] = useState("");
  const [dues, setAllDues] = useState("");
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const [oringinalData, setOriginalData] = useState([]);
  const [page, setPage] = useState(0);
  const [csvData, setcsvData] = useState([]);
  const [_walletBalance, setWalletBalance] = useState("");
  const [filename, setFilename] = useState("");
  const [showBalance, setShowBalance] = useState(false);
  const [billTypeDropDownData, setBillTypeDropDownData] = useState("");

  const [allOrders, setAllOrders] = useState([]);

  const [incentives, setIncentives] = useState({
    incentiveAmount: 0,
    incentiveCount: 0,
  });

  const [showIncentiveModal, setShowIncentiveModal] = useState(false);
  const [statFiltersRaw, setStatFilters] = useState({
    incentives: {
      from: "",
      to: "",
    },
  });

  const { backendServer, setIsLoading, patternMatching, estate, shortenNumber } =
    useContext(AppContext);

  const getData = async (loader) => {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#finance-checkbox-parent"))
      document.querySelector("#finance-checkbox-parent").checked = false;
    try {
      if (loader) setIsLoading(true);

      const returnedDues = await (
        await fetch(
          `${backendServer}/dues/get?allpopulateonetimepayersPlusAllpopulateontimemap=true`,
          {
            credentials: "include",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          }
        )
      ).json();

      setAllDues(returnedDues?.data);

      const initialOneTimeDuePaymentData = [...returnedDues?.data].reverse();

      const allTransactionsRegardingTheEstate = [];

      initialOneTimeDuePaymentData.forEach((theFullDue) => {
        if (theFullDue?.bill_frequency === "One Time") {
          theFullDue.one_time_payers.forEach((e) => {
            allTransactionsRegardingTheEstate.push({
              name: e?.user?.first_name + ` ${e?.user?.last_name}`,
              initiated_on: e?.paymentRef?.initiated_on,
              bill_type: theFullDue?.bill_type,
              _id: e?._id,
              status: "completed",
              amount: e?.paymentRef?.amount || theFullDue?.amount,
            });
          });
        } else if (theFullDue?.mappedSubscriptions) {
          theFullDue?.mappedSubscriptions?.forEach((e) => {
            allTransactionsRegardingTheEstate.push({
              name: e?.customer?.first_name + ` ${e?.customer?.last_name}`,
              initiated_on: e?.most_recent_invoice?.created_at,
              bill_type: theFullDue?.bill_type,
              _id: e?.most_recent_invoice?.invoice_code,
              status: "completed",
              amount: theFullDue?.amount,
            });
          });
        }
      });

      setAllTransactions(allTransactionsRegardingTheEstate);
      setOriginalData(allTransactionsRegardingTheEstate);

      let count = 0;
      allTransactionsRegardingTheEstate.forEach((e) => (count = count + e.amount));
      setWalletBalance(count);

      workCSV(allTransactionsRegardingTheEstate);

      setBillTypeDropDownData(initialOneTimeDuePaymentData.map((e) => e?.bill_type));

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching finances...");
    }
  };

  useEffect(() => {
    if (!display) return;
    getData(true);
  }, [display]);

  function calcIncentives(orders) {
    if (!orders) return { totalValue: 0, length: 0 };

    let totalValue;
    let totalOrderForTheMonth = 0;

    orders.forEach((e) => {
      totalOrderForTheMonth = totalOrderForTheMonth + e.totalAmount;
    });

    if (estate?.estateConfig?.order_incentive) {
      if (estate?.estateConfig?.incentive_unit === "value") {
        totalValue = estate?.estateConfig?.order_incentive * orders.length;
      } else {
        totalValue = Math.trunc(
          (estate?.estateConfig?.order_incentive / 100) * totalOrderForTheMonth
        );
      }
    } else totalValue = 0;

    return { totalValue: totalValue, length: orders?.length };
  }

  const fetchIncentives = async () => {
    if (!backendServer || !estate?.estateConfig) return;
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/order/get?status=completed`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setAllOrders(returned?.data);

      setIncentives({
        incentiveAmount: calcIncentives(returned?.data).totalValue,
        incentiveCount: calcIncentives(returned?.data).length,
      });
      setIsLoading(false);
    } catch (err) {
      popup("Error Fetching Estate Incentives");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchIncentives();
  }, [backendServer, estate]);

  async function runIncentivesData(from, to, yesterdayy, todayy) {
    if (yesterdayy) {
      const yesterday = new Date(new Date().getTime());
      yesterday.setDate(new Date().getDate() - 1);

      setStatFilters((p) => ({
        incentives: {
          ...p.incentives,
          from: new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(yesterday),
        },
      }));

      const filteredData = allOrders.filter((e) => {
        if (
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e.createdAt)) ===
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(yesterday)
        )
          return e;
      });

      setIncentives({
        incentiveAmount: calcIncentives(filteredData).totalValue,
        incentiveCount: calcIncentives(filteredData).length,
      });
    } else if (todayy) {
      const today = new Date(Date.now());

      setStatFilters((p) => ({
        incentives: {
          ...p.incentives,
          from: new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(today),
        },
      }));

      const filteredData = allOrders.filter((e) => {
        if (
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(e.createdAt)) ===
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(today)
        )
          return e;
      });

      setIncentives({
        incentiveAmount: calcIncentives(filteredData).totalValue,
        incentiveCount: calcIncentives(filteredData).length,
      });
    } else if (from && to) {
      const startDate = new Date(from);

      const endDate = new Date(to);

      const datesArray = [];

      // loop from start date to end date
      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        datesArray.push(new Date(date));
      }

      const dateRanges = datesArray.map((e) =>
        new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(e))
      );

      const filteredData = allOrders.filter((e) => {
        if (
          dateRanges.includes(
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(e.createdAt))
          )
        )
          return e;
      });

      setIncentives({
        incentiveAmount: calcIncentives(filteredData).totalValue,
        incentiveCount: calcIncentives(filteredData).length,
      });
    } else popup("Incomplete date range");

    setShowIncentiveModal(false);
  }

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#finance-checkbox-parent"))
      document.querySelector("#finance-checkbox-parent").checked = false;
    const query = e.target.value;

    if (!query) return getData();

    const foundObjects = [...oringinalData]?.filter((e) => {
      return (
        patternMatching(query, e.name) ||
        patternMatching(query?.split(" ")[0], e.name.split(" ")[0]) ||
        patternMatching(query?.split(" ")[0], e.name.split(" ")[1]) ||
        patternMatching(query?.split(" ")[1], e.name.split(" ")[0]) ||
        patternMatching(query?.split(" ")[1], e.name.split(" ")[1])
      );
    });

    setAllTransactions(foundObjects);

    workCSV(foundObjects);
  }

  function workCSV(data) {
    setcsvData([
      ["Name", "Bill Type", "Transaction ID", "Status", "Amount (NGN)", "Date"],
      ...data?.map(({ name, bill_type, _id, status, amount, initiated_on }) => {
        return [
          name,
          bill_type,
          _id,
          status,
          amount,
          new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(initiated_on)),
        ];
      }),
    ]);
  }

  function runTableFilter() {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#finance-checkbox-parent"))
      document.querySelector("#finance-checkbox-parent").checked = false;

    let sortedArray = [...oringinalData];

    if (tableFilterStatus.from && tableFilterStatus.to) {
      const startDate = new Date(tableFilterStatus.from);

      const endDate = new Date(tableFilterStatus.to);

      const datesArray = [];

      // loop from start date to end date
      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        datesArray.push(new Date(date));
      }

      const dateRanges = datesArray.map((e) =>
        new Intl.DateTimeFormat("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(e))
      );

      sortedArray = sortedArray.filter((e) => {
        if (
          dateRanges.includes(
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(e.initiated_on))
          )
        )
          return e;
      });

      setFilename(`${tableFilterStatus.from} to ${tableFilterStatus.to} - Estate Transactions`);
    }

    if (tableFilterStatus?.status) {
      sortedArray = sortedArray.filter((e) => e?.status === tableFilterStatus?.status);
    }

    if (tableFilterStatus?.billType) {
      sortedArray = sortedArray.filter((e) => e?.bill_type === tableFilterStatus?.billType);
    }

    setAllTransactions([...sortedArray]);
    workCSV(sortedArray);
    setShowTableFilter(false);
  }

  if (!display) return;

  return (
    <div>
      <div className="visitorspass-stat-container flex-row">
        <div className="f-each-d-stat-container">
          <img src="/f-1.png" alt="estility-finances" />
          <p className="small greyText removemargin" style={{ marginBottom: -10 }}>
            Wallet Balance (NGN)<br></br>
            {showBalance ? (
              <span className="big f-stat-text">{Math.trunc(0) || "-"}</span>
            ) : (
              <span className="big f-stat-text">******</span>
            )}
            <span className="flex-row" style={{ justifyContent: "flex-end", marginTop: -5 }}>
              {!showBalance ? (
                <AiOutlineEye className="cursor" onClick={() => setShowBalance(true)} />
              ) : (
                <AiOutlineEyeInvisible className="cursor" onClick={() => setShowBalance(false)} />
              )}
            </span>
          </p>
        </div>

        <div className="o-each-d-stat-container o-each-d-stat-container-one">
          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <div
              className="flex-row"
              style={{ width: "fit-content", justifyContent: "flex-start", gap: 9 }}
            >
              <img src="/f-2.png" alt="estility-finances" />
              <p className="small greyText removemargin">
                Incentives &nbsp;&nbsp;
                <span className="xsmall greyText removemargin">
                  ({incentives.incentiveCount} Orders)
                </span>
              </p>
            </div>
          </div>

          <div className="flex-row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
            <p className="small removemargin boldText blackText" style={{ paddingLeft: 54 }}>
              ₦{shortenNumber(incentives.incentiveAmount) || "-"}
            </p>

            <div
              onClick={() => {
                setShowIncentiveModal(true);
              }}
              className="xsmall removemargin cursor no-hover"
            >
              {statFiltersRaw?.incentives?.from || statFiltersRaw?.incentives?.to
                ? "Custom"
                : "All Time"}{" "}
              <span>
                <AiOutlineDown size={10} />
              </span>
              <AllIncentiveFilter
                display={showIncentiveModal}
                setDisplay={setShowIncentiveModal}
                setStatFilters={setStatFilters}
                statFiltersRaw={statFiltersRaw}
                runIncentivesData={runIncentivesData}
                fetchIncentives={fetchIncentives}
              />
            </div>
          </div>
        </div>

        <div className="f-each-d-stat-container">
          <img src="/f-2.png" alt="estility-finances" />
          <p className="small greyText removemargin">
            Bill and Dues<br></br>
            <span className="big f-stat-text">{dues?.length || "-"}</span>
            <span
              onClick={() => {
                setScreen({
                  screen: "dues",
                  data: null,
                });
              }}
              className="flex-row cursor"
              style={{ justifyContent: "flex-end", marginTop: -5, color: "#7152F3" }}
            >
              View All
            </span>
          </p>
        </div>
      </div>
      <div className="p-table-container">
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <div style={{ width: "90%" }}>
            <SearchInput
              placeholder="search for a transaction by resident"
              onChange={(e) => searchPlease(e)}
            />
          </div>
          <div className="flex-row" style={{ width: "fit-content", gap: 10 }}>
            <CgSortAz
              onClick={() => setShowTableFilter(true)}
              className="cursor"
              title="Sort By Date"
              size={30}
            />

            <CSVLink
              className="cursor small csv-link"
              filename={filename || `${estate?.estate_name} Finances Data`}
              data={csvData}
            >
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>
            <FiRefreshCcw
              className="cursor"
              title="Refresh Data"
              size={20}
              onClick={() => getData(true)}
            />
          </div>
        </div>
        <FinancesTable
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          checkBoxSelectedData={checkBoxSelectedData}
          setcsvData={setcsvData}
          data={allTransactions}
          page={page}
          setPage={setPage}
          showPageNumber={true}
        />
      </div>

      <FinanceFilter
        isOpen={showTableFilter}
        setIsOpen={setShowTableFilter}
        getData={getData}
        runTableFilter={runTableFilter}
        setTableFIlterStatus={setTableFIlterStatus}
        tableFilterStatus={tableFilterStatus}
        billTypeDropDownData={billTypeDropDownData}
      />
    </div>
  );
};

export default FinancesHome;
