import React, { useState } from 'react'
import './EstateInfo.css'
import EditEstateInfo from '../editEstateInfo/EditEstateInfo'
import EstateInfoView from '../estateInfoView/EstateInfoView';


type IEstateInfo = {};

const EstateInfo = ({}: IEstateInfo) => {

const [activeScreen, setActiveScreen] = useState(0);

const screens = [<EstateInfoView onNavigate={setActiveScreen} />, <EditEstateInfo onNavigate={setActiveScreen}/>]

  return (
    screens[activeScreen]
  )
}

export default EstateInfo