import { useContext } from "react";
import "../../../styles/index.css";
import "../verification.css";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import AppContext from "../../../context/app-context";
import { IoMdArrowBack } from "react-icons/io";
import { popup } from "../../../vanilla-functions/model";

const VerificationVerify = ({ display, setScreen, screen, setShowSuccess }) => {
  const { backendServer, setIsLoading, usersData, estate } = useContext(AppContext);

  function capitalizeEachWord(word) {
    const arr = word.split(" ");
    const mapped = arr.map((e) => {
      return e[0].toUpperCase() + e.slice(1).toLowerCase();
    });

    return mapped.join(" ");
  }
  async function approvePass() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(
          `${backendServer}/visitors-pass/arrive/${screen?.data?._id}/${usersData?._id}`,
          {
            method: "PATCH",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned?.message);

      setShowSuccess(true);

      setScreen({
        screen: "home",
        data: null,
      });

      setIsLoading(false);
    } catch (err) {
      popup("Invalid or Expired Visitors Pass");
      setIsLoading(false);
    }
  }

  async function endPass() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/visitors-pass/end/${screen?.data?._id}/${usersData?._id}`, {
          method: "PATCH",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned?.message);

      popup("Pass Ended");

      setScreen({
        screen: "home",
        data: null,
      });

      setIsLoading(false);
    } catch (err) {
      popup("Invalid or Expired Visitors Pass");
      setIsLoading(false);
    }
  }

  async function forceDeclineVisitorsPass() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(
          `${backendServer}/visitors-pass/force/decline/${screen?.data?._id}/${usersData?._id}`,
          {
            method: "PATCH",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned?.message);

      popup("Visitors Pass Declined!");

      setScreen({
        screen: "home",
        data: null,
      });

      setIsLoading(false);
    } catch (err) {
      popup("Invalid Visitors Pass");
      setIsLoading(false);
    }
  }

  if (!display) return;

  return (
    <div>
      <div className="flex-row space-between">
        <p className="big removemargin">
          <IoMdArrowBack
            onClick={() =>
              setScreen({
                screen: "home",
                data: null,
              })
            }
            size={20}
            className="cursor"
            title="Go Back"
          />
        </p>
      </div>

      <div className="media-verify-screen-force">
        <div className="verify-one">
          <p className="big blackText boldText removemargin">Request Information</p>
          <p style={{ color: "#3D4C5E" }} className="big boldText removemargin">
            Visitor
          </p>
          <p className="small removemargin">
            {screen?.data?.pass_type === "Visitor"
              ? estate?.estate_name
              : screen?.data?.pass_type === "Rider"
              ? "Estility"
              : ""}{" "}
            {screen?.data?.pass_type ? screen?.data?.pass_type : "Vistor"}
          </p>
          <p className="small removemargin">
            {capitalizeEachWord(screen?.data?.visitor_details?.first_name)}{" "}
            {capitalizeEachWord(screen?.data?.visitor_details?.last_name)}
          </p>
          <div className="flex-row align-row-left" style={{ gap: 20 }}>
            <p className="small removemargin">
              <img
                style={{ width: 15, marginRight: 10 }}
                src="/ver-icons/visitor.png"
                alt="mobile"
              />
              {screen?.data?.number_of_persons}
            </p>

            <p className="small removemargin">
              <img style={{ width: 15, marginRight: 5 }} src="/ver-icons/time.png" alt="mobile" />
              <img style={{ width: 15, marginRight: 10 }} src="/ver-icons/in.png" alt="mobile" />
              {screen?.data?.arrival_date
                ? new Intl.DateTimeFormat("en-gb", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(new Date(screen?.data?.arrival_date))
                : "-"}
            </p>
            <p className="small removemargin">
              <img style={{ width: 15, marginRight: 5 }} src="/ver-icons/time.png" alt="mobile" />
              <img style={{ width: 15, marginRight: 10 }} src="/ver-icons/out.png" alt="mobile" />
              {screen?.data?.departure_date
                ? new Intl.DateTimeFormat("en-gb", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(new Date(screen?.data?.departure_date))
                : "-"}
            </p>
          </div>
          <p className="small removemargin">
            <img style={{ width: 15, marginRight: 10 }} src="/ver-icons/address.png" alt="mobile" />
            {screen?.data?.residentID?.address?.addressString}
          </p>
          <p style={{ color: "#3D4C5E" }} className="big boldText removemargin">
            Resident
          </p>
          <p className="small removemargin">
            {capitalizeEachWord(screen?.data?.residentID?.first_name)}{" "}
            {capitalizeEachWord(screen?.data?.residentID?.last_name)}
          </p>
          <p className="small removemargin">
            <img style={{ width: 15, marginRight: 10 }} src="/ver-icons/mobile.png" alt="mobile" />
            {screen?.data?.residentID?.mobile}
          </p>
        </div>

        <div className="verify-two">
          <p style={{ color: "#716EAA" }} className="big boldText removemargin">
            Is the Visitor Information Correct?
          </p>
          <img
            src="/v-2.png"
            alt="Estility Verification"
            title="Verify Visitor Code"
            className="media-verify-dont-show"
          />
          <div className="verify-btn-container">
            {!screen?.data?.arrival_date && (
              <button
                onClick={approvePass}
                className="themeBtn verify-screen-btn"
                style={{ width: "40%", background: "#549186" }}
              >
                <AiOutlineCheck size={20} /> &nbsp; Yes, Approve
              </button>
            )}
            <button
              onClick={() => {
                if (screen?.data?.arrival_date) endPass();
                else forceDeclineVisitorsPass();
              }}
              className="themeBtn verify-screen-btn"
              style={{ width: "40%", background: "#FF4C51" }}
            >
              {screen?.data?.arrival_date ? (
                "End Pass"
              ) : (
                <span>
                  {" "}
                  <AiOutlineClose size={20} /> &nbsp; No, Decline
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationVerify;
