import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { sortData, columns } from "./data";
import "./the-due-table.css";

let pseudoCheckBoxSelectedData = [];

function TheDueTable({
  data,
  page,
  setPage,
  setCheckBoxSelectedData,
  setcsvData,
  theBillInQuestion,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getStatusAndColor(status) {
    if (status !== "active" && status !== "success")
      return {
        text: "Unpaid",
        color: "high-severity-thedue",
      };
    else
      return {
        text: "Paid",
        color: "low-severity-thedue",
      };
  }

  function workCSV(data) {
    setcsvData([
      ["Name", "Bill Type", "Address", "Amount", "Status", "Date", "Date"],
      ...data?.map(({ customer, user, status, paymentRef, createdAt }) => {
        return [
          theBillInQuestion?.bill_frequency !== "One Time"
            ? customer?.first_name + ` ${customer?.last_name}`
            : user?.first_name + ` ${user?.last_name}`,
          theBillInQuestion?.bill_type,
          theBillInQuestion?.bill_frequency !== "One Time"
            ? customer?.address?.addressString
            : user?.address?.addressString,
          ,
          theBillInQuestion?.amount,
          theBillInQuestion?.bill_frequency !== "One Time"
            ? getStatusAndColor(status).text
            : getStatusAndColor(paymentRef?.status).text,
          createdAt || paymentRef?.initiated_on
            ? new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              }).format(
                new Date(
                  (theBillInQuestion?.bill_frequency !== "One Time"
                    ? createdAt
                    : paymentRef?.initiated_on) || Date.now()
                )
              )
            : "-",
        ];
      }),
    ]);
  }

  function makeChecboxComeAlive(event) {
    if (
      pseudoCheckBoxSelectedData.find(
        (e) =>
          e?.id ==
          event?.target
            ?.closest(".MuiTableRow-root")
            ?.children[0]?.children[0].getAttribute("data-e")
      )
    ) {
      pseudoCheckBoxSelectedData = pseudoCheckBoxSelectedData.filter(
        (e) =>
          e?.id !==
          event?.target
            ?.closest(".MuiTableRow-root")
            ?.children[0]?.children[0].getAttribute("data-e")
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      workCSV(pseudoCheckBoxSelectedData);
    } else {
      pseudoCheckBoxSelectedData.push(
        data?.find(
          (e) =>
            e?.id ==
            event?.target
              ?.closest(".MuiTableRow-root")
              ?.children[0]?.children[0].getAttribute("data-e")
        )
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      workCSV(pseudoCheckBoxSelectedData);
    }
  }

  useEffect(() => {
    document.querySelectorAll("#thedue-checkbox-children").forEach((element) => {
      if (
        pseudoCheckBoxSelectedData.find(
          (e) =>
            e?.id ==
            element?.closest(".MuiTableRow-root")?.children[0]?.children[0].getAttribute("data-e")
        )
      ) {
        element.checked = true;
      } else element.checked = false;
    });
  });

  function allActionTheDueTable(add) {
    if (add) {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#thedue-checkbox-children").forEach((element) => {
        pseudoCheckBoxSelectedData.push(
          data?.find(
            (e) =>
              e?.id ==
              element?.closest(".MuiTableRow-root")?.children[0]?.children[0].getAttribute("data-e")
          )
        );
        element.checked = true;
      });

      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      workCSV(pseudoCheckBoxSelectedData);
    } else {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#thedue-checkbox-children").forEach((element) => {
        element.checked = false;
      });
      workCSV(data);
    }
  }

  if (!data) return;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "fit-content" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  onClick={(e) => {
                    if (e.target.className.includes("thedue-table-checkboxes-head")) {
                      if (!e.target.checked) allActionTheDueTable(false);
                      else allActionTheDueTable(true);
                    }
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortData(data, theBillInQuestion)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          onClick={(event) => {
                            if (event.target.className.includes("thedue-table-checkboxes")) {
                              event.stopPropagation();
                              return makeChecboxComeAlive(event);
                            }
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={sortData(data).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export { TheDueTable, pseudoCheckBoxSelectedData };
