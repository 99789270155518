import React from "react";
import "../../../components/popup/filters/filters.css";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const PropertyStatusFilter = ({
  isOpen,
  setIsOpen,
  setTableFIlterStatus,
  tableFilterStatus,
  runTableFilter,
  getData,
}) => {
  if (!isOpen) return;
  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="filter-container">
          <div className="filter-headings">
            <div className="filter-text">Filter</div>
            <p
              className="clear-all cursor"
              onClick={() => {
                setTableFIlterStatus({
                  occupancyStatus: "",
                  propertyType: "",
                });
                getData();
              }}
            >
              clear all
            </p>
          </div>
          <div className="form-filters">
            <div className="filter-date">
              <p className="small removemargin">Occupancy Status</p>
              <MDBDropdown
                id="mdb-dropdown-custom-dropdown"
                style={{ width: "350px", borderRadius: "5px" }}
              >
                <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                  {tableFilterStatus?.occupancyStatus || "-"}
                </MDBDropdownToggle>

                <MDBDropdownMenu style={{ width: "350px" }}>
                  <MDBDropdownItem
                    link
                    href="#Properties"
                    onClick={() => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        occupancyStatus: "yes",
                      }));
                    }}
                  >
                    Yes
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Properties"
                    onClick={() => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        occupancyStatus: "no",
                      }));
                    }}
                  >
                    No
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>

            <div className="filter-date">
              <p className="small removemargin">Property Type</p>
              <MDBDropdown
                id="mdb-dropdown-custom-dropdown"
                style={{ width: "350px", borderRadius: "5px" }}
              >
                <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                  {tableFilterStatus?.propertyType || "-"}
                </MDBDropdownToggle>

                <MDBDropdownMenu style={{ width: "350px" }}>
                  <MDBDropdownItem
                    link
                    href="#Properties"
                    onClick={(e) => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        propertyType: e.target.textContent.trim(),
                      }));
                    }}
                  >
                    Bungalow
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Properties"
                    onClick={(e) => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        propertyType: e.target.textContent.trim(),
                      }));
                    }}
                  >
                    Studio Apartment
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Properties"
                    onClick={(e) => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        propertyType: e.target.textContent.trim(),
                      }));
                    }}
                  >
                    Service Apartment
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Properties"
                    onClick={(e) => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        propertyType: e.target.textContent.trim(),
                      }));
                    }}
                  >
                    Block of Flats
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Properties"
                    onClick={(e) => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        propertyType: e.target.textContent.trim(),
                      }));
                    }}
                  >
                    Mansion
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Properties"
                    onClick={(e) => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        propertyType: e.target.textContent.trim(),
                      }));
                    }}
                  >
                    Other
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>

            <div className="filter-input-button" style={{ paddingTop: 20 }}>
              <button
                disabled={
                  tableFilterStatus?.propertyType || tableFilterStatus?.occupancyStatus
                    ? false
                    : true
                }
                className="themeBtn"
                onClick={runTableFilter}
              >
                Apply Filter
              </button>
            </div>
          </div>
          <div className="cancel-div">
            <button
              className="cancel"
              onClick={() => {
                setIsOpen(false);
                setTableFIlterStatus({
                  propertyType: "",
                  occupancyStatus: "",
                });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyStatusFilter;
