import React from "react";
import "./InputField.css";

type Props = {
  label: string;
  placeholder?: string;
  name: string;
  value?: string;
  labelType?: "inline" | "nested";
  onChange?: (e: ITarget) => void;
  type?: "input" | "textarea";
  containerStyle?: React.CSSProperties;
  maxLength?: number | undefined;
  inputStyle?: React.CSSProperties;
};

type ITarget = {
  target: {
    name: string;
    value: string;
  };
};

const InputField = ({
  name,
  onChange,
  value,
  label,
  placeholder,
  labelType = "inline",
  type = "input",
  containerStyle,
  maxLength,
  inputStyle,
}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange({ target: { name, value: e.target.value } });
  };

  return (
    <div className="input-field" style={{ ...containerStyle }}>
      {labelType === "nested" && (label || placeholder) && <div>{label && placeholder}</div>}
      {type === "input" && (
        <input
          style={inputStyle}
          maxLength={maxLength}
          placeholder={placeholder || label}
          onChange={(e) => handleChange(e)}
          value={value}
        />
      )}
      {type === "textarea" && (
        <textarea
          style={inputStyle}
          maxLength={maxLength}
          placeholder={placeholder || label}
          onChange={(e) => {
            onChange && onChange({ target: { name, value: e.target.value } });
          }}
          value={value}
        />
      )}
    </div>
  );
};

export default InputField;
