import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import AppContext from '../app-context'
import { IUserData } from "./main-app-context.type";


type IMainContext = {
    userData: IUserData;
    getSignedAwsUrl: (fileName: string, bucketName: string) => any;
    setIsLoading: (val: boolean) => void;
    emsToken: string;
    backendServerUrl: string
    updateUsersData: () => void;
}

const MainAppContext = createContext<IMainContext>({
    userData: {} as IUserData, 
    getSignedAwsUrl: () => {},
    setIsLoading: () => {},
    emsToken: '',
    backendServerUrl: '',
    updateUsersData: () => {}
});

type IMainAppContextProvider = {
    children: ReactNode
}


const MainAppContextProvider = ({children}: IMainAppContextProvider ) => {
    const { usersData, getSignedAwsUrl, setIsLoading, backendServer, updateUsersData} = useContext(AppContext);

    const [userMainData, setUserMainData] = useState<IUserData>(usersData as IUserData)

    useEffect(() => {
        setUserMainData(usersData)
    }, [usersData])


    const getEmsToke = () => {
        const token = localStorage.getItem("emstoken");
        if(token) return token;
        return '';
    }
    
    return (
        <MainAppContext.Provider value={{
            userData: userMainData,
            getSignedAwsUrl, 
            setIsLoading,
            emsToken: getEmsToke(),
            backendServerUrl: backendServer,
            updateUsersData
            }}>
            {children}
        </MainAppContext.Provider>
    )
}

export const useMainAppContext = () => useContext(MainAppContext);

export default MainAppContextProvider;