const columns = [
  {
    id: "thedue_checkbox",
    label: (
      <input
        id="thedue-checkbox-parent"
        type="checkbox"
        className="table-checkboxes thedue-table-checkboxes-head"
      />
    ),
    minWidth: 20,
  },
  { id: "name", label: "Name", minWidth: 170 },
  { id: "bill_type", label: "Bill Type", minWidth: 100 },
  {
    id: "address",
    label: "Address",
    minWidth: 170,
    align: "left",
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 100,
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "center",
  },
  {
    id: "date",
    label: "Date",
    minWidth: 100,
    align: "center",
  },
];

function getStatusAndColor(status) {
  if (status !== "active" && status !== "success")
    return {
      text: "Unpaid",
      color: "high-severity-thedue",
    };
  else
    return {
      text: "Paid",
      color: "low-severity-thedue",
    };
}

function sortData(data, theBillInQuestion) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      thedue_checkbox: (
        <input
          id="thedue-checkbox-children"
          type="checkbox"
          className="table-checkboxes thedue-table-checkboxes"
          data-e={e?.id}
        />
      ),
      name:
        theBillInQuestion?.bill_frequency !== "One Time"
          ? e?.customer?.first_name + ` ${e?.customer?.last_name}`
          : e?.user?.first_name + ` ${e?.user?.last_name}`,
      bill_type: theBillInQuestion?.bill_type,
      address:
        theBillInQuestion?.bill_frequency !== "One Time"
          ? e?.customer?.address?.addressString.slice(0, 40) + "..."
          : e?.user?.address?.addressString.slice(0, 40) + "...",
      amount: theBillInQuestion?.amount,
      status: (
        <span
          className={
            theBillInQuestion?.bill_frequency !== "One Time"
              ? getStatusAndColor(e?.status).color
              : getStatusAndColor(e?.paymentRef?.status).color
          }
        >
          {theBillInQuestion?.bill_frequency !== "One Time"
            ? getStatusAndColor(e?.status).text
            : getStatusAndColor(e?.paymentRef?.status).text}
        </span>
      ),
      date:
        e?.createdAt || e?.paymentRef?.initiated_on
          ? new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(
              new Date(
                (theBillInQuestion?.bill_frequency !== "One Time"
                  ? e?.createdAt
                  : e?.paymentRef?.initiated_on) || Date.now()
              )
            )
          : "-",
    };
  });

  return finalArray;
}

export { sortData, columns };
