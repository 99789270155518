import { useContext, useEffect, useState } from "react";
import { HiPencil } from "react-icons/hi";
import AppContext from "../../../context/app-context";
import { MDBSpinner } from "mdb-react-ui-kit";

type IEstateInfoView = {
  onNavigate?: (screen: number) => void;
};

const EstateInfoView = ({ onNavigate }: IEstateInfoView) => {
  const { estate, getSignedAwsUrl } = useContext(AppContext);
  const [estateLogo, setEstateLogo] = useState<string>("");
  useEffect(() => {
    (async function () {
      setEstateLogo(estate?.logo ? await getSignedAwsUrl(estate?.logo, "others") : "/logo.png");
    })();
  }, [estate]);
  return (
    <div className="estate-info-container">
      <div className="estate-info-first-child">
        <div className="estate-info-first-child-logo">
          {estateLogo ? (
            <img alt="Estate Logo" src={estateLogo} />
          ) : (
            <MDBSpinner size="sm" className="mx-2">
              <span className="visually-hidden"></span>
            </MDBSpinner>
          )}
        </div>
        <span className="estate-info-title">{estate?.estate_name}</span>
      </div>
      <div className="estate-info-child">
        <div>
          <strong>Name</strong>
          <span>{estate?.estate_name}</span>
        </div>
        <div>
          <strong>Email address</strong>
          <span>{estate?.email}</span>
        </div>
        <div>
          <strong>Phone Number</strong>
          <span>{estate?.mobile}</span>
        </div>
      </div>

      <div className="estate-info-child">
        <div>
          <strong>Address</strong>
          <span>{estate?.address?.addressString}</span>
        </div>
        <div>
          <strong>Estate Type</strong>
          <span>{estate?.estate_type}</span>
        </div>
      </div>

      <div className="estate-info-child">
        <div>
          <strong>State</strong>
          <span>{estate?.address?.state}</span>
        </div>
        <div>
          <strong>Country</strong>
          <span>{estate?.address?.country}</span>
        </div>
      </div>

      <div className="estate-info-action-wrapper">
        <div onClick={() => onNavigate && onNavigate(1)}>
          <HiPencil />
          <span>Edit Details</span>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default EstateInfoView;
