import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "../edit-resident-details-modal/edit-resident-details-modal.css";
import "./edit-an-estate-user.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const EditAnEstateStaffModal = function ({ display, setEditAnEstateStaffModal, data, getData }) {
  const { backendServer, setIsLoading, updateUsersData } = useContext(AppContext);
  const [disableBtn, setdisableBtn] = useState(true);
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setmobile] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    if (!mobile && !firstName && !lastName && !role) setdisableBtn(true);
    else setdisableBtn(false);
  });

  async function updateUser() {
    const obj = {};

    if (firstName) obj.first_name = firstName;
    if (lastName) obj.last_name = lastName;
    if (mobile) obj.mobile = mobile;
    if (role) obj.estate_child_roles = [role];

    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/user/update/profile/${data?._id}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify(obj),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setIsLoading(false);
      popup("Staff Updated");
      setEditAnEstateStaffModal(false);
      updateUsersData();
      getData();
    } catch (err) {
      setIsLoading(false);
      popup("Error updating staff, try again later");
    }
  }

  if (!display) return <></>;

  return (
    <div
      className="modal-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="modal-body e-edit-a-staff-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setEditAnEstateStaffModal(false);
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="e-a-user-modal-cont flex-column align-column-left">
          <div className="flex-row align-row-left">
            <p className="big">Update Staff Details Below</p>
          </div>

          <div className="flex-row">
            <div className="e-a-a-u-inputs">
              <p className="small">First Name</p>
              <input
                className="in"
                placeholder="First Name"
                type="text"
                defaultValue={data?.first_name}
                onChange={(e) => setfirstName(e.target.value)}
              />
            </div>
            <div className="e-a-a-u-inputs">
              <p className="small">Last Name</p>
              <input
                className="in"
                placeholder="Last Name"
                type="text"
                defaultValue={data?.last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className="flex-row">
            <div className="e-a-a-u-inputs">
              <p className="small">Staff Mobile</p>
              <input
                className="in"
                placeholder="Staff Mobile"
                type="text"
                maxLength="100"
                defaultValue={data?.mobile}
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>

            <div className="e-a-a-u-inputs">
              <p className="small">Staff Role</p>
              <MDBDropdown id="mdb-dropdown-custom-dropdown" style={{ width: "100%" }}>
                <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                  {role || data?.estate_child_roles[0] || "Staff Role"}
                </MDBDropdownToggle>

                <MDBDropdownMenu id="estate-edit-staff-modal-mdb">
                  <MDBDropdownItem
                    link
                    href="#Settings"
                    onClick={(e) => {
                      setRole("secretary");
                    }}
                  >
                    Estate Secretary
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    href="#Settings"
                    onClick={(e) => {
                      setRole("security");
                    }}
                  >
                    Estate Security
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    href="#Settings"
                    onClick={(e) => {
                      setRole("accountant");
                    }}
                  >
                    Estate Accountant
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          </div>

          <button onClick={updateUser} className="themeBtn e-a-a-u-btn" disabled={disableBtn}>
            Update Staff
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAnEstateStaffModal;
