const columns = [
  { id: "_id", label: "", minWidth: 0 },
  {
    id: "visitors_checkbox",
    label: (
      <input
        id="visitors-checkbox-parent"
        type="checkbox"
        className="table-checkboxes visitors-table-checkboxes-head"
      />
    ),
    minWidth: 20,
  },
  { id: "vistor_name", label: "Visitor Name", minWidth: 170 },
  { id: "resident_name", label: "Resident Name", minWidth: 170 },
  { id: "property_code", label: "Property Code", minWidth: 100 },
  {
    id: "pass_type",
    label: "Type",
    minWidth: 100,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "left",
  },
  {
    id: "date",
    label: "Date",
    minWidth: 100,
    align: "left",
  },
  {
    id: "arrival_date",
    label: "Time In",
    minWidth: 100,
    align: "left",
  },
  {
    id: "departure_date",
    label: "Time Out",
    minWidth: 100,
    align: "left",
  },
];

function sortData(data) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      _id: <span data-id={e?._id}></span>,
      visitors_checkbox: (
        <input
          data={e?._id}
          id="visitors-checkbox-children"
          type="checkbox"
          className="table-checkboxes visitors-table-checkboxes"
        />
      ),
      vistor_name: e?.visitor_details?.first_name + ` ${e?.visitor_details?.last_name}`,
      property_code: e?.property_code || "-",
      pass_type: e?.pass_type || "Visitor",
      resident_name: e?.residentID?.first_name + ` ${e?.residentID?.last_name}`,
      status: (
        <span
          className={
            e?.departure_date
              ? "departed-severity"
              : e?.arrival_date
              ? "arrived-severity"
              : e?.total_declined_status
              ? "declined-severity"
              : e?.active
              ? "pending-severity"
              : "high-severity"
          }
        >
          {e?.departure_date
            ? "Departed"
            : e?.arrival_date
            ? "Approved"
            : e?.total_declined_status
            ? "Declined"
            : e?.active
            ? "Pending"
            : "Expired"}
        </span>
      ),
      date: new Intl.DateTimeFormat("en-us", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(e?.createdAt)),
      arrival_date: e?.arrival_date
        ? new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(e?.arrival_date))
        : "-",
      departure_date: e?.departure_date
        ? new Intl.DateTimeFormat("en-us", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(e?.departure_date))
        : "-",
    };
  });

  return finalArray;
}

export { sortData, columns };
