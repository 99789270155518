import { useState } from "react";
import "./Faq.css";
import { CiSearch } from "react-icons/ci";
import Accordion from "./accordion/Accordion";
import { faqsList } from "./faq.data";
import Button from "../../../components/button/Button";
import { FiArrowLeft } from "react-icons/fi";
import Contact from "../../../components/popup/contact/contact";
import {
  broadCastImg,
  compliantImg,
  financesImg,
  houseImg,
  residentImg,
  visitorsImg,
} from "./faq.svg";
import { popup } from "../../../vanilla-functions/model";

type Props = {};

const Faq = (props: Props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [active, setActive] = useState(-1);

  const [openContactUsModal, setOpenContactUsModal] = useState(false);

  const [faqs, setFaqs] = useState(faqsList);
  const [searchByCategoryOpen, setSearchByCategoryOpen] = useState(false);

  const handleSearch = (searchItem: string) => {
    setSearchQuery(searchItem);
    const _filteredData = faqsList.filter((item) => {
      const objectValues = Object.values(item).map((value) =>
        typeof value === "string" ? value.toLowerCase() : value
      );

      const searchValue = searchItem.toLowerCase();
      return objectValues.some((value: any) =>
        value.toString().toLowerCase().includes(searchValue)
      );
    });
    setFaqs(_filteredData);
  };

  const [activeCategory, setActiveCategory] = useState<number>(0);

  const categories = [
    {
      img: houseImg,
      title: "Properties",
      desc: "All questions related to properties",
    },
    {
      img: residentImg,
      title: "Residents",
      desc: "All questions related to residents",
    },
    {
      img: financesImg,
      title: "Finance",
      desc: "All questions related to finance",
    },
    {
      img: visitorsImg,
      title: "Visitors",
      desc: "All questions related to visitors",
    },
    {
      img: broadCastImg,
      title: "Broadcast",
      desc: "All questions related to broadcast",
    },
    {
      img: compliantImg,
      title: "Complaints",
      desc: "All questions related to complaints",
    },
  ];

  return (
    <div className="faq-container">
      <div className="faq-title" style={{ position: "relative" }}>
        <FiArrowLeft
          style={{
            fontSize: "1.5rem",
            cursor: "pointer",
            fontWeight: "600",
            position: "absolute",
            left: "2rem",
            display: searchByCategoryOpen ? "block" : "none",
          }}
          onClick={() => setSearchByCategoryOpen(false)}
        />
        Frequently Asked Questions
      </div>
      <div className="faq-search-box-wrapper">
        <CiSearch />
        <input
          placeholder="Search frequently asked questions"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      {searchByCategoryOpen && (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.5rem" }}>
          <div className="faq-active-category-svg-wrapper">{categories[activeCategory].img}</div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
              {categories[activeCategory].title}
            </div>
            <div style={{ fontWeight: "500", fontSize: "0.8rem" }}>
              {categories[activeCategory].desc}
            </div>
          </div>
        </div>
      )}
      {!searchByCategoryOpen && (
        <div className="faq-categories-box-wrapper">
          {categories.map((x, i) => (
            <div
              key={i}
              className="faq-search-category-box"
              onClick={() => {
                // makeActive
                setActiveCategory(i);
                setSearchByCategoryOpen(true);
                handleSearch(x.title);
              }}
            >
              {x.img}
              <div>{x.title}</div>
              <div>{x.desc}</div>
            </div>
          ))}
        </div>
      )}
      <div className="faq-accordion-wrapper">
        {faqs.map((x, i) => (
          <Accordion
            title={x.title}
            desc={x.desc}
            key={i}
            showText={active === i}
            onClick={() => {
              if (active === i) {
                setActive(-1);
              } else {
                setActive(i);
              }
            }}
          />
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            border: "1px solid var(--primary-primary-50, #7975B6)",
            borderRadius: "0.5rem",
            padding: "1rem",
            flexDirection: "column",
            width: "60%",
            gap: "0.2rem",
            justifyContent: "space-around",
            alignItems: "center",
            minHeight: "6rem",
          }}
        >
          <div style={{ textTransform: "uppercase", fontWeight: "500", fontSize: "1rem" }}>
            Can’t Find what you’re looking for?
          </div>
          <span>Reach out to our team and get immediate response</span>
          <Button
            onClick={() =>
              window.open("https://estility.atlassian.net/servicedesk/customer/portal/2", "_blank")
            }
            label="Contact us"
          />
        </div>
      </div>
      {/* contact us modal */}
      {openContactUsModal && (
        <Contact
          isOpen={openContactUsModal}
          onClose={() => setOpenContactUsModal(false)}
          onSendMsg={(arg) => {
            setOpenContactUsModal(false);
            popup("Sent!");
          }}
        />
      )}
      {/* contact us modal */}
    </div>
  );
};

export default Faq;
