import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { sortData, columns } from "./data";
import "./transactions-table.css";
let pseudoCheckBoxSelectedData = [];

function TransactionsTable({ data, page, setPage, setCheckBoxSelectedData, setcsvData }) {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function makeChecboxComeAlive(event) {
    if (
      pseudoCheckBoxSelectedData.find(
        (e) => e._id === event?.target?.closest(".MuiTableRow-root").children[2].textContent.trim()
      )
    ) {
      pseudoCheckBoxSelectedData = pseudoCheckBoxSelectedData.filter(
        (e) => e._id !== event?.target?.closest(".MuiTableRow-root").children[2].textContent.trim()
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      setcsvData([
        ["Bill Type", "Transaction ID", "Status", "Transaction Date", "Amount (NGN)"],
        ...pseudoCheckBoxSelectedData?.map(({ bill_type, _id, status, initiated_on, amount }) => {
          return [
            bill_type,
            _id,
            status,
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(initiated_on)),
            new Intl.NumberFormat("en-us", {
              currency: "NGN",
            }).format(amount),
          ];
        }),
      ]);
    } else {
      pseudoCheckBoxSelectedData.push(
        data?.find(
          (e) =>
            e?._id === event?.target?.closest(".MuiTableRow-root").children[2].textContent.trim()
        )
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      setcsvData([
        ["Bill Type", "Transaction ID", "Status", "Transaction Date", "Amount (NGN)"],
        ...pseudoCheckBoxSelectedData?.map(({ bill_type, _id, status, initiated_on, amount }) => {
          return [
            bill_type,
            _id,
            status,
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(initiated_on)),
            new Intl.NumberFormat("en-us", {
              currency: "NGN",
            }).format(amount),
          ];
        }),
      ]);
    }
  }

  useEffect(() => {
    document.querySelectorAll("#f-checkbox-children").forEach((element) => {
      if (
        pseudoCheckBoxSelectedData.find(
          (e) => e._id === element?.closest(".MuiTableRow-root").children[2].textContent.trim()
        )
      ) {
        element.checked = true;
      } else element.checked = false;
    });
  });

  function allActionFTable(add) {
    if (add) {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#f-checkbox-children").forEach((element) => {
        pseudoCheckBoxSelectedData.push(
          data?.find(
            (e) => e?._id === element?.closest(".MuiTableRow-root").children[2].textContent.trim()
          )
        );
        element.checked = true;
      });

      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);

      setcsvData([
        ["Bill Type", "Transaction ID", "Status", "Transaction Date", "Amount (NGN)"],
        ...pseudoCheckBoxSelectedData?.map(({ bill_type, _id, status, initiated_on, amount }) => {
          return [
            bill_type,
            _id,
            status,
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(initiated_on)),
            new Intl.NumberFormat("en-us", {
              currency: "NGN",
            }).format(amount),
          ];
        }),
      ]);
    } else {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#f-checkbox-children").forEach((element) => {
        element.checked = false;
      });
      setcsvData([
        ["Bill Type", "Transaction ID", "Status", "Transaction Date", "Amount (NGN)"],
        ...data?.map(({ bill_type, _id, status, initiated_on, amount }) => {
          return [
            bill_type,
            _id,
            status,
            new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(initiated_on)),
            new Intl.NumberFormat("en-us", {
              currency: "NGN",
            }).format(amount),
          ];
        }),
      ]);
    }
  }

  if (!data) return;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "fit-content" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  onClick={(e) => {
                    if (e.target.className.includes("f-table-checkboxes-head")) {
                      if (!e.target.checked) allActionFTable(false);
                      else allActionFTable(true);
                    }
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortData(data)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          onClick={(event) => {
                            if (event.target.className.includes("f-table-checkboxes")) {
                              event.stopPropagation();
                              return makeChecboxComeAlive(event);
                            }
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={sortData(data).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export { TransactionsTable, pseudoCheckBoxSelectedData };
