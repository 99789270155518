import React from "react";
import "../../../components/popup/filters/filters.css";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const VisitorsStatusFilter = ({
  isOpen,
  setIsOpen,
  setTableFIlterStatus,
  tableFilterStatus,
  runTableFilter,
  getData,
}) => {
  if (!isOpen) return;
  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="filter-container">
          <div className="filter-headings">
            <div className="filter-text">Filter</div>
            <p
              className="clear-all cursor"
              onClick={() => {
                setTableFIlterStatus({
                  from: "",
                  to: "",
                  requestStatus: "",
                });
                getData(true);
              }}
            >
              clear all
            </p>
          </div>
          <div className="form-filters">
            <div className="filter-date">
              <p className="small removemargin">Request Status</p>
              <MDBDropdown
                id="mdb-dropdown-custom-dropdown"
                style={{ width: "350px", borderRadius: "5px" }}
              >
                <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                  {tableFilterStatus?.requestStatus || "-"}
                </MDBDropdownToggle>

                <MDBDropdownMenu style={{ width: "350px" }}>
                  <MDBDropdownItem
                    link
                    href="#Visitors"
                    onClick={() => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        requestStatus: "Pending",
                      }));
                    }}
                  >
                    Pending
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Visitors"
                    onClick={() => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        requestStatus: "Approved",
                      }));
                    }}
                  >
                    Approved
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Visitors"
                    onClick={() => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        requestStatus: "Departed",
                      }));
                    }}
                  >
                    Departed
                  </MDBDropdownItem>

                  <MDBDropdownItem
                    link
                    href="#Visitors"
                    onClick={() => {
                      setTableFIlterStatus((p) => ({
                        ...p,
                        requestStatus: "Declined",
                      }));
                    }}
                  >
                    Declined
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>

            <div className="filter-date">
              <p className="small removemargin">From</p>
              <input
                style={{ borderRadius: 5, padding: 10, border: "1px solid rgb(201, 199, 199)" }}
                className="o-absolute-filter-date-in"
                type="date"
                value={tableFilterStatus.from}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setTableFIlterStatus((p) => ({ ...p, from: e.target.value }));
                }}
              />
            </div>

            <div className="filter-date">
              <p className="small removemargin">To</p>
              <input
                style={{ borderRadius: 5, padding: 10, border: "1px solid rgb(201, 199, 199)" }}
                className="o-absolute-filter-date-in"
                type="date"
                value={tableFilterStatus.to}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setTableFIlterStatus((p) => ({ ...p, to: e.target.value }));
                }}
              />
            </div>

            <div className="filter-input-button" style={{ paddingTop: 20 }}>
              <button
                disabled={
                  tableFilterStatus?.requestStatus ||
                  (tableFilterStatus?.from && tableFilterStatus?.to)
                    ? false
                    : true
                }
                className="themeBtn"
                onClick={runTableFilter}
              >
                Apply Filter
              </button>
            </div>
          </div>
          <div className="cancel-div">
            <button
              className="cancel"
              onClick={() => {
                setIsOpen(false);
                setTableFIlterStatus({
                  from: "",
                  to: "",
                  requestStatus: "",
                });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorsStatusFilter;
