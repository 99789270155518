import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { sortData, columns } from "./data";
import "./residents-table.css";
import ResdientDetails from "./resident-detail";
let pseudoCheckBoxSelectedData = [];

function ResidentsTableTable({
  data,
  setHideResidentTable,
  screen: mainScreen,
  page,
  setPage,
  setCheckBoxSelectedData,
  setcsvData,
  hardRefresh,
}) {
  const [screen, setScreen] = useState({
    screen: "table",
    residentsDetailsData: null,
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function workCSV(data) {
    setcsvData([
      ["Name", "Address", "Unit Code", "Phone Number", "Email", "Resident Level"],
      ...data?.map(
        ({ first_name, last_name, address, mobile, resident_type, property_code, email }) => {
          return [
            first_name + ` ${last_name}`,
            address?.addressString,
            property_code,
            mobile,
            email,
            resident_type ? resident_type : "none",
          ];
        }
      ),
    ]);
  }

  function makeChecboxComeAlive(event) {
    if (
      pseudoCheckBoxSelectedData.find(
        (e) => e.email === event?.target?.closest(".MuiTableRow-root").children[6].textContent
      )
    ) {
      pseudoCheckBoxSelectedData = pseudoCheckBoxSelectedData.filter(
        (e) => e.email !== event?.target?.closest(".MuiTableRow-root").children[6].textContent
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      workCSV(pseudoCheckBoxSelectedData);
    } else {
      pseudoCheckBoxSelectedData.push(
        data?.find(
          (e) => e?.email === event?.target?.closest(".MuiTableRow-root").children[6].textContent
        )
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      workCSV(pseudoCheckBoxSelectedData);
    }
  }

  useEffect(() => {
    document.querySelectorAll("#resident-checkbox-children").forEach((element) => {
      if (
        pseudoCheckBoxSelectedData.find(
          (e) => e.email === element?.closest(".MuiTableRow-root").children[6].textContent
        )
      ) {
        element.checked = true;
      } else element.checked = false;
    });
  });

  function allActionResidentsTable(add) {
    if (add) {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#resident-checkbox-children").forEach((element) => {
        pseudoCheckBoxSelectedData.push(
          data?.find(
            (e) => e?.email === element?.closest(".MuiTableRow-root").children[6].textContent
          )
        );
        element.checked = true;
      });

      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);

      workCSV(pseudoCheckBoxSelectedData);
    } else {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#resident-checkbox-children").forEach((element) => {
        element.checked = false;
      });
      workCSV(data);
    }
  }

  return (
    <>
      {screen?.screen === "table" && (
        <Paper sx={{ width: "100%", overflow: "hidden", height: "fit-content" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      onClick={(e) => {
                        if (e.target.className.includes("resident-table-checkboxes-head")) {
                          if (!e.target.checked) allActionResidentsTable(false);
                          else allActionResidentsTable(true);
                        }
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortData(data, hardRefresh)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={(event) => {
                                if (event.target.className.includes("resident-table-checkboxes")) {
                                  event.stopPropagation();
                                  return makeChecboxComeAlive(event);
                                }
                                setHideResidentTable(true);
                                setScreen({
                                  screen: "details",
                                  residentsDetailsData: row,
                                  propertyData: mainScreen?.propertyData,
                                });
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={sortData(data).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {screen?.screen === "details" && (
        <ResdientDetails
          setHideResidentTable={setHideResidentTable}
          screen={screen}
          setScreen={setScreen}
          hardRefresh={hardRefresh}
        />
      )}
    </>
  );
}

export { ResidentsTableTable, pseudoCheckBoxSelectedData };
