import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";

const PropertyDetails = function ({ display, screen, setScreen }) {
  const [propertyData, setPropertyData] = useState(null);
  const { backendServer, setIsLoading } = useContext(AppContext);

  useEffect(() => {
    if (!screen?.detailsID) return;
    (async function () {
      try {
        setIsLoading(true);

        const returned = await (
          await fetch(`${backendServer}/property/get?property_code=${screen?.detailsID}`, {
            method: "GET",
            credentials: "include",
            headers: {
              token: localStorage.getItem("emstoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
        setPropertyData(returned?.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        popup("Error fetching Property Data");
      }
    })();

    return () => setPropertyData(null);
  }, [screen]);

  if (!display) return;

  if (!propertyData) return;

  return (
    <div>
      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        <p className="small removemargin">
          <IoMdArrowBack
            onClick={() =>
              setScreen({
                screen: "home",
                detailsID: null,
                residentsTableData: null,
              })
            }
            style={{ marginTop: -3 }}
            size={17}
            className="cursor"
          />{" "}
          &nbsp; Property Details
        </p>
      </div>

      <div className="flex-row align-row-left more-gap">
        <div className="flex-column align-column-left width-fit-content property-d-max-width">
          <p className="small boldText removemargin">House Number</p>
          <p className="small  removemargin">{propertyData?.full_address?.house_number}</p>
        </div>
        <div className="flex-column align-column-left width-fit-content property-d-max-width">
          <p className="small boldText removemargin">Street Name</p>
          <p className="small  removemargin">{propertyData?.full_address?.street_name}</p>
        </div>
        <div className="flex-column align-column-left width-fit-content property-d-max-width">
          <p className="small boldText removemargin">Property Code</p>
          <p className="small  removemargin">{propertyData?.property_code}</p>
        </div>
        <div className="flex-column align-column-left width-fit-content property-d-max-width">
          <p className="small boldText removemargin">Property Type</p>
          <p className="small  removemargin">{propertyData?.property_type}</p>
        </div>
      </div>

      <div className="flex-row align-row-left more-gap">
        <div className="flex-column align-column-left width-fit-content property-d-max-width">
          <p className="small boldText removemargin">Number of Units</p>
          <p className="small  removemargin">{propertyData?.number_of_units}</p>
        </div>
        <div className="flex-column align-column-left width-fit-content property-d-max-width">
          <p className="small boldText removemargin">Status</p>
          <p className="small  removemargin">
            {propertyData?.occupied ? "Occupied" : "Unoccupied"}
          </p>
        </div>
        <div className="flex-column align-column-left width-fit-content property-d-max-width">
          <p className="small boldText removemargin">Number of Residents</p>
          <p className="small  removemargin">{propertyData?.residents.length}</p>
        </div>
      </div>

      {propertyData?.contacts.find((e) => e?.contact_type === "Primary") && (
        <>
          <p className="small boldText themeText" style={{ margin: "30px 0px 0px 0px" }}>
            Primary Contact
          </p>

          <div className="flex-row align-row-left more-gap">
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small boldText removemargin">Full Name</p>
              <p className="small  removemargin">
                {propertyData?.contacts.find((e) => e?.contact_type === "Primary")?.full_name}
              </p>
            </div>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small boldText removemargin">Phone Number</p>
              <p className="small  removemargin">
                {propertyData?.contacts.find((e) => e?.contact_type === "Primary")?.phone}
              </p>
            </div>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small boldText removemargin">Email Address</p>
              <p className="small  removemargin">
                {propertyData?.contacts.find((e) => e?.contact_type === "Primary")?.email}
              </p>
            </div>
          </div>
        </>
      )}

      {propertyData?.contacts.find((e) => e?.contact_type === "Secondary") && (
        <>
          <p className="small boldText themeText" style={{ margin: "15px 0px 0px 0px" }}>
            Secondary Contact
          </p>

          <div className="flex-row align-row-left more-gap">
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small boldText removemargin">Full Name</p>
              <p className="small  removemargin">
                {propertyData?.contacts.find((e) => e?.contact_type === "Secondary")?.full_name}
              </p>
            </div>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small boldText removemargin">Phone Number</p>
              <p className="small  removemargin">
                {propertyData?.contacts.find((e) => e?.contact_type === "Secondary")?.phone}
              </p>
            </div>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small boldText removemargin">Email Address</p>
              <p className="small  removemargin">
                {propertyData?.contacts.find((e) => e?.contact_type === "Secondary")?.email}
              </p>
            </div>
          </div>
        </>
      )}

      <div className="flex-row" style={{ justifyContent: "flex-end", paddingRight: 40 }}>
        <p
          className="small cursor"
          onClick={() =>
            setScreen({
              screen: "residents-table",
              detailsID: screen?.detailsID,
              residentsTableData: propertyData?.residents.filter((e) => e.is_active),
              propertyData: propertyData,
            })
          }
        >
          View property residents &nbsp; <IoMdArrowForward style={{ marginTop: -5 }} size={20} />
        </p>
      </div>
    </div>
  );
};

export default PropertyDetails;
