import { useEffect, useState, useContext } from "react";
import "../../../styles/index.css";
import "./BroadCastHome.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { AiOutlinePlus } from "react-icons/ai";
import { BroadcastTable } from "../../../components/broadcast-table/broadcast-table";
import SearchInput from "../../../components/searchInput/SearchInput";

type Props = {
  display: boolean;
  onNavigate: (screen: number) => void;
};

const BroadCastHome = ({ display, onNavigate }: Props) => {
  const [allBroadcasts, setAllBroadcasts] = useState<[] | any>();
  const [originalData, setOriginalData] = useState<[] | any>();
  const [page, setPage] = useState<number>(0);
  const { backendServer, setIsLoading, patternMatching } = useContext(AppContext);

  const getData = async () => {
    setPage(0);
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/broadcasts/get`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken") || "",
          },
        })
      ).json();

      const theReversed = returned?.data?.reverse();

      setAllBroadcasts(theReversed);
      setOriginalData(theReversed);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching finances...");
    }
  };

  function searchPlease(e: any) {
    setPage(0);
    const query = e.target.value;
    if (!query) return getData();

    const foundBroadcasts: object[] = [...originalData]?.filter((e) => {
      return patternMatching(query, e.title) || patternMatching(query, e.text);
    });

    setAllBroadcasts(foundBroadcasts);
  }

  useEffect(() => {
    if (!display) return;
    getData();
  }, [display]);

  if (!display) return;

  return (
    <div>
      <div className="p-table-container">
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <div style={{ width: "80%" }}>
            <SearchInput placeholder="search for a broadcast" onChange={(e) => searchPlease(e)} />
          </div>
          <div className="flex-row" style={{ width: "fit-content", gap: 10 }}>
            <button
              onClick={() => {
                onNavigate && onNavigate(1);
              }}
              className="themeBtn due-search-btn"
            >
              <AiOutlinePlus /> New Broadcasts
            </button>
          </div>
        </div>
        <BroadcastTable page={page} setPage={setPage} data={allBroadcasts} />
      </div>
    </div>
  );
};

export default BroadCastHome;
