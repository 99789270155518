import React, { useContext, useState, useEffect } from "react";
import "./notification.css";
import markUnread from "../../assets/read.svg";
import { BsArrowLeftShort } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import AppContext from "../../context/app-context";

const Notification = ({ isOpen, setIsOpen }) => {
  const { backendServer, updateUsersData, estate } = useContext(AppContext);
  const [notification, setNotifications] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  useEffect(() => {
    if (!estate?.notifications) return;
    const theReversed = [...estate?.notifications].reverse();
    setNotifications(theReversed);
  }, [estate]);

  const displayContent = (item) => {
    setOpen(true);
    setSelectedItem(item);
    read(false, item?._id);
  };

  const SelectedItemDiv = () => (
    <div className="selectedItem-container">
      <div className="selectedItem-heading">
        <div className="selectedItem-title">{selectedItem?.title}</div>
        <div className="small">
          {new Intl.DateTimeFormat("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(selectedItem?.createdAt))}
        </div>
      </div>
      <div className="small" style={{ maxWidth: "75%" }}>
        {selectedItem?.message}
      </div>
    </div>
  );

  async function read(all, id) {
    if (!all && id) {
      try {
        await fetch(`${backendServer}/estate/notifications/read/${id}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        });
        updateUsersData(true);
      } catch {}
    } else {
      try {
        await fetch(`${backendServer}/estate/notifications/read`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        });
        updateUsersData(true);
      } catch {}
    }
  }

  if (!isOpen) return;
  return (
    <div className="modal-overlay animate-modal">
      <div className="modal-content-body edit-wallet-modal-body notification-body">
        <div className="toggle-buttons">
          {open && (
            <div className="toggle-page">
              <div className="back-button">
                <BsArrowLeftShort className="cursor" size={30} onClick={() => setOpen(false)} />
              </div>
              <div className="toggle-title">Notifications</div>
              <div className="close-button">
                <AiOutlineCloseCircle
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className="cursor"
                  size={20}
                  color="#34204C"
                />
              </div>
            </div>
          )}
        </div>
        <div className="notification-container">
          {!open && (
            <>
              <div className="notification-title-div">
                <div className="title">Notifications</div>
                <div className="close-button">
                  <AiOutlineCloseCircle
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    className="cursor"
                    size={20}
                    color="#34204C"
                  />
                </div>
              </div>
              <div className="notification-button-items">
                {notification?.map((item, i) => (
                  <button onClick={() => displayContent(item)} key={i} className="container-button">
                    <div className="notification-boxes">
                      <div className="notification-items">
                        <div className="notification-content-title">
                          <div className="title">{item?.title}</div>
                          <div className="small" style={{ maxWidth: "90%" }}>
                            {item?.message?.slice(0, 50)}...
                          </div>
                        </div>
                        <div className="notification-content-date">
                          <div className="notification-date">
                            {new Intl.DateTimeFormat("en-us", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }).format(new Date(item?.createdAt))}
                          </div>
                          <div
                            style={{ color: !item?.is_read ? "#FF4C51" : "#70C1B3" }}
                            className="notification-status"
                          >
                            {item?.is_read ? "read" : "unread"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
              <button onClick={() => read(true, null)} className="mark-all">
                <img src={markUnread} />
                &nbsp;&nbsp; Mark all as read
              </button>
            </>
          )}
          {open && <SelectedItemDiv />}
        </div>
      </div>
    </div>
  );
};

export default Notification;
