import React, { useContext } from "react";
import "../../styles/index.css";
import "./compliants-modal.css";
import AppContext from "../../context/app-context";
import { MdContentCopy } from "react-icons/md";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";

const CompliantsModal = function ({ display, compliant, setCompliantsModal, getData }) {
  const { backendServer, setIsLoading } = useContext(AppContext);

  async function resolveCompliant() {
    if (!compliant?._id) return popup("Ooops, something went wrong");
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/compliants/resolve/${compliant?._id}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("emstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup("Compliant marked as resolved");
      setCompliantsModal({
        display: false,
        data: {},
      });
      getData();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching Estate Compliants");
    }
  }

  if (!display) return;

  return (
    <div className="modal-container">
      <div className="modal-body edit-complains-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setCompliantsModal({
                display: false,
                data: {},
              });
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>
        <div className="complaint-modal-cont flex-column align-column-left">
          <p className="big boldText">Complaint Details</p>

          <p className="small">
            <span className="boldText">Reported By:</span>
            &nbsp; &nbsp;
            <span>
              {compliant?.first_name} {compliant?.last_name}
            </span>
          </p>

          <p className="small">
            <span className="boldText">Date and Time:</span>
            &nbsp; &nbsp;
            <span>
              {new Intl.DateTimeFormat("en-gb", {
                year: "2-digit",
                month: "numeric",
                day: "2-digit",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(new Date(compliant?.createdAt))}
            </span>
          </p>

          <p className="small">
            <span className="boldText">Type:</span>
            &nbsp; &nbsp;
            <span>{compliant?.compliant_type}</span>
          </p>

          <p className="small">
            <span className="boldText">Description:</span>
            <br></br>
            <span>{compliant?.description}</span>
          </p>

          <p className="small">
            <span className="boldText">Severity</span>
            <br></br>
            <span>
              <img
                src={`${
                  compliant?.severity === "low"
                    ? "/blue.png"
                    : compliant?.severity === "medium"
                    ? "/yellow.png"
                    : "/red.png"
                }`}
                alt="compliant-severity"
              />
            </span>
            <br></br>
            <span>{String(compliant?.severity).toUpperCase()}</span>
          </p>

          {!compliant?.status && (
            <div className="flex-row">
              <button onClick={resolveCompliant} className="themeBtn compliants-modal-btn">
                Mark as Resolved
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompliantsModal;
