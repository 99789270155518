const processBarChartData = (dues, orders, orderIncentive, unit) => {
  if (dues.length === 0 && orders.length === 0) return [];

  const finalArray = [];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function drillData(thedues, theOrders) {
    months.forEach((e) => {
      const obj = {
        month: e,
      };

      const allduesForTheMonth = thedues.filter(
        (due) =>
          new Intl.DateTimeFormat("en-us", {
            month: "long",
          }).format(new Date(due.initiated_on)) === e &&
          new Date(due.initiated_on).getFullYear() === new Date(Date.now()).getFullYear()
      );

      let totaldueForTheMonth = 0;

      allduesForTheMonth.forEach((e) => {
        totaldueForTheMonth = totaldueForTheMonth + e.amount;
      });

      obj["Dues"] = totaldueForTheMonth;

      //getting the orders and setting for the month

      const allOrdersForTheMonth = theOrders.filter(
        (order) =>
          new Intl.DateTimeFormat("en-us", {
            month: "long",
          }).format(new Date(order.createdAt)) === e &&
          new Intl.DateTimeFormat("en-us", {
            year: "numeric",
          }).format(new Date(order.createdAt)) == new Date(Date.now()).getFullYear()
      );

      let totalOrderForTheMonth = 0;

      allOrdersForTheMonth.forEach((e) => {
        totalOrderForTheMonth = totalOrderForTheMonth + e.totalAmount;
      });

      if (orderIncentive) {
        if (unit === "value") {
          obj["Order Incentives"] = orderIncentive * allOrdersForTheMonth.length;
        } else {
          obj["Order Incentives"] = Math.trunc((orderIncentive / 100) * totalOrderForTheMonth);
        }
      } else obj["Order Incentives"] = 0;

      finalArray.push(obj);
    });
  }

  drillData(dues, orders);

  return finalArray;
};

export default processBarChartData;
