import { useEffect, useState } from "react";
import SelectInput from "../../../components/selectInput/SelectInput";
import Button from "../../../components/button/Button";
import { IoMdArrowBack } from "react-icons/io";
import InputField from "../../../components/inputField/InputField";
import Editor from "../../../components/editor/Editor";
import BroadcastView from "../broadcastView/BroadcastView";
import { useMainAppContext } from "../../../context/appContext/MainAppContext";
import { CreateBroadCastUrl } from "../../../config/constants/endpoints";
import { popup } from "../../../vanilla-functions/model";
import { validateField } from "../../../utils";

type Props = {
  onNavigate?: (s: number, arg?: any) => void;
};

const BroadcastForm = ({ onNavigate }: Props) => {
  const { setIsLoading, backendServerUrl, emsToken } = useMainAppContext();
  const [state, setState] = useState<any>({});
  // const [editorState, setEditorState] = useState<string>("");

  const [preview, _setPreview] = useState(false);

  const handleSendBroadcast = async (msgBody: any) => {
    try {
      const payload = {
        title: state.title,
        recipients: state.recipients,
        text: state.text,
        html: state.html,
      };
      const validate = validateField({
        fields: [payload],
        onError: (field) => popup(`${field} is required`),
      });
      if (!validate) return;

      setIsLoading(true);
      const url = `${backendServerUrl}/${CreateBroadCastUrl()}`;
      const result = await (
        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: emsToken,
          },
          body: JSON.stringify(msgBody),
        })
      ).json();
      const { status, message } = result;
      if (status === "Internal server error") {
        popup(`${message}`);
        setIsLoading(false);
        return;
      }
      popup("Broadcast sent");
      setIsLoading(false);
      setState({});
      onNavigate && onNavigate(0);
    } catch (error) {
      setIsLoading(false);
      popup("Error sending broadcast!");
    }
  };

  return (
    <div
      style={{
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!preview && (
        <div
          style={{
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            padding: "0.5rem",
          }}
        >
          <IoMdArrowBack
            onClick={() => onNavigate && onNavigate(0)}
            style={{}}
            size={20}
            cursor={"pointer"}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="big" style={{ maxWidth: "40%" }}>
              To send a broadcast message, fill in the required details below
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.2rem",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: "1rem" }}>Send To: &nbsp;</div>
              <div style={{ minWidth: "10rem" }}>
                <SelectInput
                  onChange={(e) =>
                    setState((p: any) => ({
                      ...p,
                      [e.target.name]: e.target.value === "All Residents" ? "All" : e.target.value,
                    }))
                  }
                  options={["Primary", "Secondary", "Property Owner", "All Residents"]}
                  name={"recipients"}
                  label="User Type"
                  value={state["recipients"]}
                />
              </div>
            </div>
          </div>
          <InputField
            onChange={(e) => setState((p: any) => ({ ...p, [e.target.name]: e.target.value }))}
            label={"Title"}
            name={"title"}
            value={state["title"]}
            placeholder="Your title here"
          />
          <div>
            {/* <Editor
              value={editorState}
              onChange={(arg) => {
                console.log(arg);
                arg.rawHtmString && setEditorState(arg.rawHtmString);
                setState((p: any) => ({ ...p, text: arg.text, html: arg.htmlString }));
              }}
            /> */}
            <InputField
              inputStyle={{ whiteSpace: "pre-wrap" }}
              type={"textarea"}
              label={"Start typing your message..."}
              placeholder={"Start typing your message..."}
              name={"text"}
              onChange={(e) => {
                setState((p: any) => ({
                  ...p,
                  text: e.target.value.replace(/\n/g, "<br/>"),
                  html: e.target.value.replace(/\n/g, "<br/>"),
                }));
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            <Button
              label="Cancel"
              btnType="outline"
              onClick={() => {
                onNavigate && onNavigate(0);
              }}
            />
            <Button
              label="Send Message"
              onClick={() => {
                handleSendBroadcast(state);
              }}
            />
          </div>
        </div>
      )}
      {/* {preview && (
        <BroadcastView
          messageHtmlString={state["html"] || ""}
          onContinueEditing={() => setPreview(false)}
          onSendMessage={() => handleSendBroadcast(state)}
        />
      )} */}
    </div>
  );
};

export default BroadcastForm;
