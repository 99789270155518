const columns = [
  {
    id: "property_checkbox",
    label: (
      <input
        id="property-checkbox-parent"
        type="checkbox"
        className="table-checkboxes property-table-checkboxes-head"
      />
    ),
    minWidth: 20,
  },
  { id: "property_code", label: "Property Code", minWidth: 170 },
  { id: "property_type", label: "Property Type", minWidth: 170 },
  {
    id: "full_address",
    label: "Address",
    minWidth: 170,
    align: "left",
  },
  {
    id: "number_of_units",
    label: "No. of Units",
    minWidth: 100,
    align: "center",
  },
  {
    id: "residents",
    label: "No. of Residents",
    minWidth: 100,
    align: "center",
  },
  {
    id: "occupied",
    label: "Ocuppied",
    minWidth: 100,
    align: "center",
  },
];

function sortData(data) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      property_checkbox: (
        <input
          data={e?._id}
          id="property-checkbox-children"
          type="checkbox"
          className="table-checkboxes property-table-checkboxes"
        />
      ),
      property_code: e?.property_code,
      property_type: e?.property_type,
      full_address:
        (
          e?.full_address?.house_number +
          " " +
          e?.full_address?.street_name +
          " " +
          e?.full_address?.city +
          " " +
          e?.full_address?.state +
          " "
        ).slice(0, 40) + "...",
      number_of_units: e?.number_of_units,
      residents: e?.residents?.length,
      occupied: (
        <span className={e?.occupied ? "greenText" : "redText"}>{e?.occupied ? "Yes" : "No"}</span>
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
