const estateAccesses = {
  security: ["Visitors", "Verification"],
  super: [
    "Dashboard",
    "Properties",
    "Residents",
    "Finances",
    "Visitors",
    "Verification",
    "Broadcasts",
    "Settings",
    "Compliants",
  ],
  secretary: [
    "Dashboard",
    "Properties",
    "Residents",
    "Visitors",
    "Verification",
    "Broadcasts",
    "Compliants",
  ],
  accountant: ["Dashboard", "Finances"],
};

export default estateAccesses;
